import React from "react";

import "./index.scss";
import { Stack, Typography } from "@mui/material";

export const Slide3: React.FC = () => {
  return (
    <div
      className="page-chest-onboarding-slide2-wrapper"
      style={{ gap: "40px" }}
    >
      <div className="page-chest-onboarding-slide2-header">
        <p className="page-chest-onboarding-slide2-header-hight">Important</p>
        <p className="page-chest-onboarding-slide2-header-low ab">info</p>
      </div>
      <div className="page-chest-onboarding-slide2-body">
        <div
          className="page-chest-onboarding-slide2-body-container"
          style={{
            justifyContent: "flex-start",
            height: "100%",
            color: "#fff",
            fontSize: "16px",
          }}
        >
          <div
            className="page-chest-onboarding-slide2-body-container-item"
            style={{
              justifyContent: "flex-start",
            }}
          >
            <p>1.</p>
            <p>
              When you purchase a key using TON, an{" "}
              <span className="ton-color">order</span> is created in the{" "}
              <span className="ton-color">History page.</span>
            </p>
          </div>
          <div
            className="page-chest-onboarding-slide2-body-container-item"
            style={{
              justifyContent: "flex-start",
            }}
          >
            <p>2.</p>
            <p>
              After payment, our system verifies the{" "}
              <span className="ton-color">transaction hash</span> through TON
              Blockchain to complete the order and deliver your keys
            </p>
          </div>
          <Typography
            className="purple-color-chests"
            sx={{
              fontSize: "30px",
              lineHeight: "33px",
            }}
            textAlign="center"
          >
            If something goes wrong:
          </Typography>
          <div
            className="page-chest-onboarding-slide2-body-container-item"
            style={{
              justifyContent: "flex-start",
            }}
          >
            <p>
              <b>Case 1:</b> You exit the app before completing payment. Simply{" "}
              <span className="ton-color">cancel the order</span> upon your
              return and <span className="ton-color">try again.</span>
            </p>
          </div>
          <div
            className="page-chest-onboarding-slide2-body-container-item"
            style={{
              justifyContent: "flex-start",
            }}
          >
            <p>
              <b>Case 2:</b> If your payment was made but isn’t detected,{" "}
              <span className="ton-color">input the transaction hash</span>{" "}
              manually in the provided field{" "}
              <span className="ton-color">to receive your key.</span>
            </p>
          </div>
          <div
            className="page-chest-onboarding-slide2-body-container-item"
            style={{
              justifyContent: "flex-start",
            }}
          >
            <p>
              In any other cases simply{" "}
              <span className="purple-color-chests">
                cancel the order, reconnect the wallet
              </span>{" "}
              and try again
            </p>
          </div>
        </div>
      </div>
      <Stack
        justifyContent="center"
        gap={2}
        flexDirection="row"
        style={{
          color: "#fff",
        }}
      >
        <Typography
          className="ab"
          style={{ fontSize: "60px", fontWeight: "600" }}
          display="block"
        >
          #
        </Typography>
        <Typography
          className="ab"
          style={{ fontSize: "60px", fontWeight: "600" }}
          display="block"
        >
          #
        </Typography>
        <Typography
          className="ab"
          style={{ fontSize: "60px", fontWeight: "600" }}
          display="block"
        >
          #
        </Typography>
      </Stack>
    </div>
  );
};
