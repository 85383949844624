// urls
export const TELEGRAM_LINK = "https://t.me/w_community";
export const NEW_URL_MASK = "app.w-coin.io";
export const SUPPORT_LINK = 'https://t.me/w_support3';

// AdsGram
export const AD_GRAM_NEW_CODE = "4814";
export const AD_GRAM_OLD_CODE = "4195";

// coin sizes
export const BALANCE_COIN_SIZE_XX_LARGE = 167;
export const BALANCE_COIN_SIZE_X_LARGE = 65;
export const BALANCE_COIN_SIZE_LARGE = 49;
export const BALANCE_COIN_SIZE_MEDIUM = 24;
export const BALANCE_COIN_SIZE_SMALL = 20;

export const MIN_FORMAT_COIN_VALUE = 100000000;

export const RUNE_REWARD = 22222;

// multiplier
export const DEFAULT_MULTIPLIER = 1;
export const PAY_PASS_MULTIPLIER = 3;
export const PAY_PASS_MULTIPLIER_LIGHT = 2;

// colors
export const GOLD_COLOR = '#F7CE45';

//  wai animation key from  local storage
export const BG_FROM_LS_STATE = "isBgDynamic";
