import React from "react";

import { Box, Button, Stack } from "@mui/material";
import { Address } from "@ton/core";

import { truncateString } from "../../../wallet/utils";

import "./index.scss";

// images
const editIcon = require("./img/edit-icon.png") as string;

interface IProps {
  address?: string;
  handleDisconnect: () => Promise<void>;
  handleConnectWallet: () => void;
}

export const Actions: React.FC<IProps> = ({
  address,
  handleDisconnect,
  handleConnectWallet,
}) => {
  const getBtn = () => {
    return (
      <Button className="nft-action-btn ab" style={{ width: "100%" }} disabled>
        In progress...
      </Button>
    );

    // if (!address) {
    //   return (
    //     <Button onClick={handleConnectWallet} className="nft-action-btn ab">
    //       Connect wallet
    //     </Button>
    //   );
    // } else if (address) {
    //   return (
    //     <Button
    //       onClick={() => console.log("checkEligibility")}
    //       className="nft-action-btn ab"
    //     >
    //       Check eligibility
    //     </Button>
    //   );
    // }
  };

  return (
    <Stack width="100%" justifyContent="center" alignItems="center" gap="20px">
      {getBtn()}
      {/* {address ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <p className="white-color paPassAddress">
            Connected address:{" "}
            {truncateString(
              Address.parse(address).toString({
                bounceable: false,
              })
            )}
          </p>
          <img src={editIcon} alt="" onClick={handleDisconnect} />
        </div>
      ) : (
        <Box height="22px"></Box>
      )} */}
    </Stack>
  );
};
