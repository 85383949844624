import  React from 'react';

export const useTimerApi = (timestamp?: number) => {
    const [timeLeft, setTimeLeft] = React.useState<number>(24 * 60 * 60 * 1000);
  
    React.useEffect(() => {
      if (timestamp === undefined) {
        setTimeLeft(0);
        return;
      }
  
      const updateTimer = () => {
        const now = Date.now();
        const diff = timestamp + 24 * 60 * 60 * 1000 - now;
        setTimeLeft(diff > 0 ? diff : 0);
      };
  
      const intervalId = setInterval(updateTimer, 1000);
  
      updateTimer();
  
      return () => clearInterval(intervalId);
    }, [timestamp]);
  
    const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((timeLeft / (1000 * 60)) % 60);
    const seconds = Math.floor((timeLeft / 1000) % 60);
    
    const formattedTime =
      timestamp === undefined
        ? undefined
        : `${hours.toString().padStart(2, "0")}:${minutes
            .toString()
            .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  
    return {
      formattedTime,
    };
  };
  