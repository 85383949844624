import { EKeyType, TKey } from "./models";

export const CHEST_ONBOARDING_KEY = 'CHEST_ONBOARDING_KEY_UPDATED';


export enum EChestNames {
    STARTER = 'STARTER',
    GUARDIAN = 'GUARDIAN',
    TREASURE = 'TREASURE',
    WCOSMIC = 'W-COSMIC',
}

export const KEYS: Record<EKeyType, TKey> = {
    [EKeyType.Bronze]: {
        type: EKeyType.Bronze,
        amount: 1,
        isBestDeal: false,
        ton: 0.02,
        stars: 5,
    },
    [EKeyType.Silver]: {
        type: EKeyType.Silver,
        amount: 10,
        isBestDeal: false,
        ton: 0.14,
        stars: 33,
    },
    [EKeyType.Gold]: {
        type: EKeyType.Gold,
        amount: 100,
        isBestDeal: false,
        ton: 1.1,
        stars: 300,
    },
    [EKeyType.Platinum]: {
        type: EKeyType.Platinum,
        amount: 1000,
        isBestDeal: true,
        ton: 9.1,
        stars: 2000,
    },
    [EKeyType.Diamond]: {
        type: EKeyType.Diamond,
        amount: 5000,
        isBestDeal: false,
        ton: 25,
        stars: 8000,
    },
    [EKeyType.Brilliant]: {
        type: EKeyType.Brilliant,
        amount: 10000,
        isBestDeal: false,
        ton: 35, 
        stars: 13000,
    },
};