import React from "react";

import { CSSTransition } from "react-transition-group";

const countdownModalImg = require("./img/tapping-icon.png") as string;

interface IProps {
  show: boolean;
  onClose: () => void;
}

export const CountdownModal: React.FC<IProps> = ({ show, onClose }) => {
  return (
    <CSSTransition in={show} timeout={500} classNames="fade" unmountOnExit>
      <div className="overlay app-modal-root">
        <div
          className={`app-modal-content-wrapper task-modal-wrapper`}
          style={{ height: "60%" }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="close-modal ab" onClick={onClose}>
            x
          </div>
          <div className="app-modal-content task-modal-content">
            <div className="task-modal-desc">
              <div className="" style={{ background: "transparent" }}>
                <img src={countdownModalImg} style={{ width: "75px" }} alt="" />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  textAlign: "center",
                }}
              >
                <p style={{ color: "#fff" }}>
                  <p className="ab">Tapping is gone...</p>{" "}
                </p>
                <div
                  style={{
                    color: "#fff",
                    gap: "12px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p>
                    <p style={{ color: "#CDCDCD" }}>
                      The energy bar no longer refills, but you can use daily
                      boosters or W-Treasures to restore it.
                    </p>
                  </p>
                  <p style={{ color: "#6D6D6D" }}>
                    P.S. Christmas in the air, do you smell it?
                  </p>
                </div>
              </div>
            </div>
            <div className="task-modal-button-wrapper">
              <button
                style={{ fontWeight: "600" }}
                onClick={onClose}
                className="app-modal-content-button"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
