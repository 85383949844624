import React from "react";

import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

import { ICampaign } from "../models";
import { Box, IconButton, Stack } from "@mui/material";
import { Price } from "../../../../app-modal/task-modal/price";
import {
  useHapticFeedback,
  useWebApp,
} from "@vkruglikov/react-telegram-web-app";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from "@reduxjs/toolkit/query";
import { RetryOptions } from "@reduxjs/toolkit/dist/query/retry";
import { EAPITagType } from "../../../../../enums";

const keyIcon = require("../img/key.png") as string;

interface IProps {
  onClose: () => void;
  activeTask: ICampaign | null;
  payPassMultiplier?: number;
  onClaimTask: (task: ICampaign | null) => void;
  checkTrumpsImpireTaskAsync: MutationTrigger<
    MutationDefinition<
      void,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {} & RetryOptions,
        FetchBaseQueryMeta
      >,
      EAPITagType.EXTERNAL_API,
      void,
      "externalApi"
    >
  >;
}

export const ModalContent: React.FC<IProps> = ({
  activeTask,
  onClose,
  payPassMultiplier,
  onClaimTask,
  checkTrumpsImpireTaskAsync,
}) => {
  const webApp = useWebApp();

  const [isVerified, setIsVerified] = React.useState<boolean>(false);
  const [isTryVerified, setIsTryVerified] = React.useState<
    boolean | undefined
  >();
  const [impactOccurred] = useHapticFeedback();

  const handleReset = () => {
    setIsVerified(false);
    setIsTryVerified(undefined);
    onClose();
  };

  const handleClose = (event: React.SyntheticEvent, reason: string) => {
    if (reason === "backdropClick") {
      return;
    }

    handleReset();
  };

  const handleTryVerifiedContent = () => {
    if (typeof isTryVerified === "undefined") {
      return null;
    } else if (isTryVerified) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "red",
            width: "100%",
          }}
        >
          <p>Task not completed.</p>
          <p>Try again</p>
        </div>
      );
    } else {
      return null;
    }
  };

  const handleOpen = () => {
    if (activeTask) {
      webApp.openLink(activeTask.link);
    }
  };

  const handleVerify = () => {
    checkTrumpsImpireTaskAsync().then((res) => {
      // @ts-ignore
      if (res?.data?.status == "success") {
        setIsVerified(true);
        setIsTryVerified(undefined);
        onClaimTask(activeTask);
      } else {
        setIsVerified(false);
        setIsTryVerified(true);
      }
    });
  };

  const actionButtons = () => {
    const subscribeBtn = (
      <div
        className="task-modal-button-wrapper"
        style={{
          width: "100%",
          maxWidth: "unset",
        }}
      >
        <button
          onClick={handleOpen}
          className={`app-modal-content-button`}
          style={{
            width: "100%",
          }}
        >
          {activeTask?.btnLabel}
        </button>
      </div>
    );

    return (
      <Stack gap={2} width="100%">
        <Box
          width="100%"
          height="37px"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {handleTryVerifiedContent()}
        </Box>
        <Stack width="100%" gap={1}>
          {activeTask?.is_done ? null : (
            <button
              onClick={
                isVerified ? () => onClaimTask(activeTask) : handleVerify
              }
              className={`app-modal-content-button`}
            >
              {isVerified ? "Claim" : "Verify"}
            </button>
          )}
          {subscribeBtn}
        </Stack>
      </Stack>
    );
  };

  const getModalContent = () => {
    return (
      <Stack display="flex" flexDirection="column" gap={1}>
        <Price
          modalLabel={activeTask?.title}
          reward={activeTask?.reward}
          payPassMultiplier={payPassMultiplier}
          isDone={!!activeTask?.is_done}
          keyComponent={
            <div
              className="task-modal-reward ab"
              style={{ alignItems: "center" }}
            >
              <img width="20px" src={keyIcon} alt="" />
              <p className="task-modal-received">+50</p>
            </div>
          }
        />
      </Stack>
    );
  };

  return (
    <Modal
      open={!!activeTask}
      onClose={handleClose}
      style={{
        display: "flex",
      }}
    >
      <Box
        className="app-modal-content-wrapper"
        style={{
          display: "flex",
          width: "100%",
          height: "85%",
          justifyContent: "space-between",
          overflow: "scroll",
        }}
      >
        <IconButton
          onClick={handleReset}
          sx={{
            position: "absolute",
            top: 12,
            right: 12,
            color: "#fff",
          }}
        >
          <CloseIcon style={{ color: "#fff" }} />
        </IconButton>
        <div
          className="app-modal-content task-modal-content"
          style={{
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div className="task-modal-desc">
            <Stack
              style={{
                gap: "24px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                className="task-modal-icon"
                style={{
                  maxWidth: "max-content",
                  margin: "0 auto",
                }}
              >
                <img src={activeTask?.icon} alt="" />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  textAlign: "center",
                }}
              >
                {getModalContent()}
              </div>
            </Stack>
          </div>
          {actionButtons()}
        </div>
      </Box>
    </Modal>
  );
};
