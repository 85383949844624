import React from "react";

import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

import { ECampaign, ICampaign } from "../models";
import { Box, IconButton, Stack } from "@mui/material";
import { Price } from "../../../../app-modal/task-modal/price";
import {
  useHapticFeedback,
  useWebApp,
} from "@vkruglikov/react-telegram-web-app";

interface IProps {
  onClose: () => void;
  activeTask: ICampaign | null;
  payPassMultiplier?: number;
  onTelegramGroupVerify: (resource: string) => any;
  onClaimTask: (task: ICampaign | null) => void;
  onTeafiVerify: () => any;
}

export const ModalContent: React.FC<IProps> = ({
  activeTask,
  onClose,
  payPassMultiplier,
  onTelegramGroupVerify,
  onClaimTask,
  onTeafiVerify,
}) => {
  const webApp = useWebApp();

  const [isVerified, setIsVerified] = React.useState<boolean>(false);
  const [isTryVerified, setIsTryVerified] = React.useState<
    boolean | undefined
  >();
  const [impactOccurred] = useHapticFeedback();

  const handleReset = () => {
    setIsVerified(false);
    setIsTryVerified(undefined);
    onClose();
  };

  const handleClose = (event: React.SyntheticEvent, reason: string) => {
    if (reason === "backdropClick") {
      return;
    }

    handleReset();
  };

  const handleTryVerifiedContent = () => {
    if (typeof isTryVerified === "undefined") {
      return null;
    } else if (isTryVerified) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "red",
            width: "100%",
          }}
        >
          <p>Task not completed.</p>
          <p>Try again</p>
        </div>
      );
    } else {
      return null;
    }
  };

  const handleOpen = () => {
    if (activeTask) {
      if (activeTask?.externalLink) {
        if (
          (activeTask.type === ECampaign.FOLLOW_X && !activeTask.is_done) ||
          (activeTask.type === ECampaign.JOIN_TG_COMMUNITY &&
            !activeTask.is_done)
        ) {
          onClaimTask(activeTask);
        }
        webApp.openLink(activeTask.link);
      } else {
        webApp.openTelegramLink(activeTask.link);
      }
    }
  };

  const handleVerify = () => {
    if (activeTask?.tgName) {
      onTelegramGroupVerify(activeTask?.tgName).then((res: any) => {
        if (res.data?.is_member) {
          setIsVerified(true);
          setIsTryVerified(undefined);
        } else {
          setIsVerified(false);
          setIsTryVerified(true);
        }
        impactOccurred("heavy");
      });
    } else {
      return onTeafiVerify().then((res: any) => {
        // @ts-ignore
        if (res?.data?.isMember && res?.data?.hasSwaps) {
          setIsVerified(true);
          setIsTryVerified(undefined);
          onClaimTask(activeTask);
        } else {
          setIsVerified(false);
          setIsTryVerified(true);
        }
      });
    }
  };

  const actionButtons = () => {
    const subscribeBtn = (
      <div className="task-modal-button-wrapper">
        <button onClick={handleOpen} className={`app-modal-content-button`}>
          {activeTask?.btnLabel}
        </button>
      </div>
    );

    if (
      (activeTask?.type === ECampaign.FOLLOW_X && !activeTask?.is_done) ||
      (activeTask?.type === ECampaign.JOIN_TG_COMMUNITY && !activeTask?.is_done)
    ) {
      return subscribeBtn;
    }

    return (
      <Stack gap={2} width="100%">
        <Box
          width="100%"
          height="37px"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {handleTryVerifiedContent()}
        </Box>
        <Stack width="100%" gap={1}>
          {activeTask?.is_done ? null : (
            <button
              onClick={
                isVerified ? () => onClaimTask(activeTask) : handleVerify
              }
              className={`app-modal-content-button`}
            >
              {isVerified ? "Claim" : "Verify"}
            </button>
          )}
          {activeTask?.is_done ? null : subscribeBtn}
        </Stack>
      </Stack>
    );
  };

  const getModalContent = () => {
    return (
      <>
        <Stack display="flex" flexDirection="column" gap={1}>
          <Price
            modalLabel={activeTask?.title}
            modalSubtitle={activeTask?.subtitle}
            reward={activeTask?.reward}
            payPassMultiplier={payPassMultiplier}
            isDone={!!activeTask?.is_done}
          />
        </Stack>
      </>
    );
  };

  return (
    <Modal
      open={!!activeTask}
      onClose={handleClose}
      style={{
        display: "flex",
      }}
    >
      <Box
        className="app-modal-content-wrapper"
        style={{
          display: "flex",
          width: "100%",
          height: "85%",
          justifyContent: "space-between",
        }}
      >
        <IconButton
          onClick={handleReset}
          sx={{
            position: "absolute",
            top: 12,
            right: 12,
            color: "#fff",
          }}
        >
          <CloseIcon style={{ color: "#fff" }} />
        </IconButton>
        <div
          className="app-modal-content task-modal-content"
          style={{
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div className="task-modal-desc">
            <Stack
              style={{
                gap: "24px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                className="task-modal-icon"
                style={{
                  maxWidth: "max-content",
                  margin: "0 auto",
                }}
              >
                <img src={activeTask?.icon} alt="" />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  textAlign: "center",
                }}
              >
                {getModalContent()}
              </div>
            </Stack>
          </div>
          {actionButtons()}
        </div>
      </Box>
    </Modal>
  );
};
