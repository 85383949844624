import React from "react";

import { TemplatePages } from "../template-pages";

import { usePayPassApi } from "./hooks";
import { AccordionComponent } from "./accordion-component";
import { WalletActions } from "./wallet-actions";
import { ErrorNotificationModal } from "./error-notification-modal";
import { SuccessPayPassModal } from "./success-pay-pass-modal";
import { ErrorNetModal } from "./error-net-modal";
import { Tabs } from "./tabs";
import { EPayPassTab } from "./models";

import "./styles.css";
import { Spinner } from "../spinner";
import { BackButton } from "@vkruglikov/react-telegram-web-app";
import { ROUTES } from "../routers";

// images
const bg = require("./img/bg.png") as string;
const bgLight = require("./img/bg-light.png") as string;
const galaxy = require("./img/galaxy.png") as string;
const galaxyLight = require("./img/galaxy-light.png") as string;

export const PagePayPass: React.FC = () => {
  const {
    onSentTransaction,
    showError,
    setShowError,
    isFetching,
    isLoading,
    showSuccessPayPassModal,
    onCloseSuccessPayPassModal,
    onGetPayPassGenerateInvoice,
    onDisconnect,
    address,
    isPayPassPurchased,
    payPassPurchasedType,
    disabled,
    showNetError,
    setShowNetError,
    payPassTab,
    onPayPassTabChange,
    isPayPassUpdating,
  } = usePayPassApi();

  const isActiveTabPayPassComplete = payPassTab === EPayPassTab.COMPLETE;

  return (
    <TemplatePages
      bg={isActiveTabPayPassComplete ? bg : bgLight}
      isLoading={isLoading}
      isFetching={isFetching}
    >
      <Spinner isLoading={isPayPassUpdating} />
      {!isFetching && (
        <BackButton onClick={() => (window.location.hash = ROUTES.MAIN.PATH)} />
      )}
      <ErrorNotificationModal
        open={showError}
        onClose={() => setShowError(false)}
        onSentTransaction={onSentTransaction}
      />
      <ErrorNetModal
        open={showNetError}
        onClose={() => setShowNetError(false)}
      />
      <SuccessPayPassModal
        open={showSuccessPayPassModal}
        onClose={onCloseSuccessPayPassModal}
        isActiveTabPayPassComplete={isActiveTabPayPassComplete}
      />
      <div className="pay-pass-root">
        <img
          src={isActiveTabPayPassComplete ? galaxy : galaxyLight}
          className="pay-pass-logo"
          alt=""
        />
        {!isPayPassPurchased && (
          <Tabs
            payPassTab={payPassTab}
            onPayPassTabChange={onPayPassTabChange}
            payPassPurchasedType={payPassPurchasedType}
          />
        )}
        <AccordionComponent
          isPayPassPurchased={isPayPassPurchased}
          isActiveTabPayPassComplete={isActiveTabPayPassComplete}
        />
        {!isPayPassPurchased && (
          <WalletActions
            address={address}
            onSentTransaction={onSentTransaction}
            onGetPayPassGenerateInvoice={onGetPayPassGenerateInvoice}
            onDisconnect={onDisconnect}
            disabled={disabled}
            isActiveTabPayPassComplete={isActiveTabPayPassComplete}
          />
        )}
      </div>
    </TemplatePages>
  );
};
