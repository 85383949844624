import React from "react";

import {
  Stack,
  IconButton,
  Typography,
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Button,
} from "@mui/material";
import { Address } from "@ton/core";
import CloseIcon from "@mui/icons-material/Close";
import { TonConnectUI, TonConnectUiOptions } from "@tonconnect/ui-react";
import { CSSTransition } from "react-transition-group";

import { EPrice, starsPrice, tabPrice, tonPrice } from "../../models";
import { truncateString } from "../../../wallet/utils";
import { Spinner } from "../../../spinner";

const editIcon = require("./img/edit-icon.png") as string;
const starsIcon = require("./img/stars-icon.png") as string;
const icon = require("./img/icon.png") as string;

interface IProps {
  showModal: boolean;
  onCloseWithBackdropClick: (
    event: React.SyntheticEvent,
    reason: string
  ) => void;
  onClose: () => void;
  onUpdateDonate: (price: number) => Promise<void>;
  currentPrice: EPrice;
  setCurrentPrice: (price: EPrice) => void;

  isWalletConnected: boolean;
  address?: string;
  tonConnectUI: TonConnectUI;
  setOptions: (options: TonConnectUiOptions) => void;
  onDisconnect: () => Promise<void>;
  failedTransaction: boolean;
  handleSentTransaction: () => Promise<void>;
  isLoading: boolean;
}

export const ModalContent: React.FC<IProps> = ({
  showModal,
  onCloseWithBackdropClick,
  onClose,
  onUpdateDonate,
  setCurrentPrice,
  currentPrice,
  isWalletConnected,
  address,
  tonConnectUI,
  setOptions,
  onDisconnect,
  failedTransaction,
  handleSentTransaction,
  isLoading,
}) => {
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newPrice: EPrice | null
  ) => {
    if (newPrice) {
      setCurrentPrice(newPrice);
    }
  };

  const isActiveTab = (tab: EPrice) => {
    return currentPrice === tab;
  };

  return (
    <>
      <CSSTransition
        in={showModal}
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
        <div
          className={`app-modal-content-wrapper task-modal-wrapper`}
          onClick={(e) => e.stopPropagation()}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 13,
            borderRadius: "27px",
            display: "flex",
            height: "100vh",
            alignItems: "end",
            padding: "0px",
          }}
        >
          <Stack
            width="100%"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              position: "relative",
              borderRadius: "27px",
              padding: "20px 30px 20px",
            }}
          >
            <Spinner isLoading={isLoading} />
            <IconButton
              onClick={() => {
                onClose();
                setCurrentPrice(EPrice.MEDIUM);
              }}
              sx={{
                position: "absolute",
                top: 0,
                right: "20px",
                color: "#fff",
                padding: "0px",
              }}
            >
              <CloseIcon style={{ color: "#fff" }} />
            </IconButton>
            <Typography
              style={{
                fontSize: "36px",
                lineHeight: "1",
                marginBottom: "20px",
              }}
              textAlign="center"
              className="tapping-text ab"
            >
              Power the Team
            </Typography>
            <Typography
              textAlign="center"
              style={{
                fontSize: "19px",
                color: "#fff",
                maxWidth: "273px",
                margin: "0 auto",
                marginBottom: "20px",
              }}
            >
              Keep W-Coin growing with your support.
            </Typography>
            <Typography
              textAlign="center"
              style={{
                fontSize: "14px",
                color: "#A0A0A0",
                maxWidth: "273px",
                margin: "0 auto",
                marginBottom: "42px",
              }}
            >
              Donating can be done more than once. Every bit of help counts!
            </Typography>
            <Box
              style={{
                width: "100%",
                margin: "0 auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img width="53%" src={icon} alt="" />
            </Box>
            <ToggleButtonGroup
              value={currentPrice}
              exclusive
              onChange={handleChange}
              aria-label="price selection"
              style={{
                borderRadius: "18px",
                margin: "0 auto",
                display: "flex",
                gap: "2px",
                marginTop: "35px",
              }}
            >
              <ToggleButton
                style={{
                  backgroundColor: isActiveTab(EPrice.LOW) ? "#fff" : "#9A9595",
                  borderRadius: "20px 0 0 20px",
                  width: "80px",
                  fontSize: "24px",
                  color: isActiveTab(EPrice.LOW) ? "#8254BD" : "#fff",
                  height: "57px",
                  gap: "4px",
                }}
                className="ab"
                value={EPrice.LOW}
                aria-label="low price"
              >
                ${tabPrice[EPrice.LOW]}
              </ToggleButton>
              <ToggleButton
                className="ab"
                style={{
                  backgroundColor: isActiveTab(EPrice.MEDIUM)
                    ? "#fff"
                    : "#9A9595",
                  width: "80px",
                  fontSize: "24px",
                  color: isActiveTab(EPrice.MEDIUM) ? "#8254BD" : "#fff",
                  height: "57px",
                  gap: "4px",
                }}
                value={EPrice.MEDIUM}
                aria-label="medium price"
              >
                ${tabPrice[EPrice.MEDIUM]}
              </ToggleButton>
              <ToggleButton
                className="ab"
                style={{
                  backgroundColor: isActiveTab(EPrice.PRE_HIGH)
                    ? "#fff"
                    : "#9A9595",
                  width: "80px",
                  fontSize: "24px",
                  color: isActiveTab(EPrice.PRE_HIGH) ? "#8254BD" : "#fff",
                  height: "57px",
                  gap: "4px",
                }}
                value={EPrice.PRE_HIGH}
                aria-label="medium price"
              >
                ${tabPrice[EPrice.PRE_HIGH]}
              </ToggleButton>

              <ToggleButton
                className="ab"
                style={{
                  backgroundColor: isActiveTab(EPrice.HIGH)
                    ? "#fff"
                    : "#9A9595",
                  borderRadius: "0 20px 20px 0",
                  width: "80px",
                  fontSize: "24px",
                  height: "57px",
                  color: isActiveTab(EPrice.HIGH) ? "#8254BD" : "#fff",
                  display: "flex",
                  flexDirection: "row",
                  gap: "2px",
                  alignItems: "center",
                }}
                value={EPrice.HIGH}
                aria-label="high price"
              >
                <div>🤑</div>
                {tabPrice[EPrice.HIGH]}
              </ToggleButton>
            </ToggleButtonGroup>
            <Stack
              style={{
                marginTop: "35px",
              }}
            >
              {address ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "4px",
                    height: "22px",
                    justifyContent: "center",
                  }}
                >
                  <p className="white-color paPassAddress">
                    Connected address:{" "}
                    {truncateString(
                      Address.parse(address).toString({
                        bounceable: false,
                      })
                    )}
                  </p>
                  <img src={editIcon} alt="" onClick={onDisconnect} />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "4px",
                    height: "22px",
                  }}
                ></div>
              )}
              {failedTransaction ? (
                <Typography
                  style={{
                    color: "red",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Not enough TON funds
                </Typography>
              ) : null}
              <Box
                style={{
                  height: "49px",
                  width: "100%",
                  marginTop: "16px",
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                }}
              >
                <Button
                  onClick={
                    !tonConnectUI.connected
                      ? () => tonConnectUI.openModal()
                      : () => handleSentTransaction()
                  }
                  className="ab"
                  style={{
                    fontSize: "20px",
                    border: "1px solid #fff",
                    borderRadius: "20px",
                    color: "#fff",
                    textTransform: "capitalize",
                    width: "100%",
                  }}
                >
                  {tonPrice[currentPrice]} TON
                </Button>
                <Button
                  onClick={() => onUpdateDonate(starsPrice[currentPrice])}
                  className="ab"
                  style={{
                    fontSize: "20px",
                    border: "1px solid #fff",
                    borderRadius: "20px",
                    color: "#fff",
                    textTransform: "capitalize",
                    width: "100%",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <img width="21px" src={starsIcon} alt="" />
                  {starsPrice[currentPrice]}
                </Button>
              </Box>
            </Stack>
          </Stack>
        </div>
      </CSSTransition>
    </>
  );
};
