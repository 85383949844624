import React from "react";

import { IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CSSTransition } from "react-transition-group";

import { ActionButton } from "./action-button";
import { Address } from "@ton/core";
import WebApp from "@twa-dev/sdk";

import "./index.scss";
import { useConnectWalletApi } from "./hooks";
import { TClaimState } from "../../../../contexts/types";
import { LottieAnimationContext } from "../../../../contexts/lottie-animation-context";
import { useUpdateUserExternalApi } from "../../../../../hooks";
import { useGetWaiBonusInvoiceMutation } from "../../../../feature/users";
import { Spinner } from "../../../../spinner";
import { truncateString } from "../../../../wallet/utils";

const editIcon = require("./img/edit-icon.png") as string;
const icon = require("./img/hand-icon.png") as string;
const starsIcon = require("./img/stars-icon.png") as string;

interface IProps {
  showModal: boolean;
  onShow: React.Dispatch<React.SetStateAction<boolean>>;
  claimState?: TClaimState;
  setClaimState:
    | React.Dispatch<React.SetStateAction<TClaimState | undefined>>
    | undefined;
}

export const RecoveryTapping: React.FC<IProps> = ({
  showModal,
  onShow,
  claimState,
  setClaimState,
}) => {
  const {
    address,
    onDisconnect,
    tonConnectUI,
    tonPrice,
    isLoading,
    handleSentTransaction,
    failedTransaction,
    isProcessing,
  } = useConnectWalletApi(onShow, setClaimState, claimState);
  const { setIsVisible } = React.useContext(LottieAnimationContext);

  const [updateUserExternalApi, updateUserState] = useUpdateUserExternalApi();

  const [
    getRecoveryTappingStarsPriceInvoice,
    getRecoveryTappingStarsPriceInvoiceState,
  ] = useGetWaiBonusInvoiceMutation();

  const starsPrice = process.env.REACT_APP_RECOVERY_TAPPING_STARS_AMOUNT;

  const disableButton =
    isLoading ||
    getRecoveryTappingStarsPriceInvoiceState.isLoading ||
    updateUserState.isLoading ||
    isProcessing;

  const handleBuyStars = async () => {
    try {
      const result = await getRecoveryTappingStarsPriceInvoice().unwrap();

      if (result?.result) {
        WebApp.openInvoice(result?.result, (status) => {
          if (status === "paid") {
            const newData = {
              boosts: { ...(claimState?.boosts || {}), wai_boost: true },
            } as unknown as TClaimState;

            updateUserExternalApi({ ...newData }).then(() => {
              setClaimState?.((prevState) => ({ ...prevState, ...newData }));
              onShow(false);
              setIsVisible?.(true);

              const timeoutId = setTimeout(() => {
                setIsVisible?.(false);
              }, 4000);
            });
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Spinner isLoading={disableButton} top="0" />
      {
        <CSSTransition
          in={showModal}
          timeout={500}
          classNames="fade"
          unmountOnExit
        >
          <div
            className={`app-modal-content-wrapper task-modal-wrapper`}
            onClick={(e) => e.stopPropagation()}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 13,
              height: "unset",
              borderRadius: "27px",
              padding: "33px",
            }}
          >
            <IconButton
              onClick={() => onShow(false)}
              sx={{
                position: "absolute",
                top: 12,
                right: 12,
                color: "#fff",
              }}
            >
              <CloseIcon style={{ color: "#fff" }} />
            </IconButton>
            <div className="app-modal-content task-modal-content">
              <div className="task-modal-desc">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    textAlign: "center",
                    maxWidth: "354px",
                  }}
                >
                  <p style={{ color: "#fff", fontSize: "32px" }}>
                    <p className="ab tapping-text">Turbo Trading</p>{" "}
                  </p>
                  <div
                    style={{
                      color: "#fff",
                      gap: "12px",
                      display: "flex",
                      flexDirection: "column",
                      fontSize: "20px",
                    }}
                  >
                    <>
                      <p style={{ color: "#fff", fontSize: "16px" }}>
                        Boost your trading speed to 5 times.
                      </p>
                      <p style={{ color: "#CDCDCD", fontSize: "16px" }}>
                        One-time purchase
                      </p>
                    </>
                    <img
                      src={icon}
                      width="167.50px"
                      height="72.09px"
                      alt=""
                      style={{ margin: "0 auto", marginTop: "17px" }}
                    />
                  </div>
                </div>
              </div>
              <Stack
                flexDirection="column"
                gap={2}
                width="100%"
                justifyContent="center"
                alignItems="center"
              >
                <div
                  className="connected-address"
                  style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {address ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <p className="white-color paPassAddress">
                        Connected address:{" "}
                        {truncateString(
                          Address.parse(address).toString({
                            bounceable: false,
                          })
                        )}
                      </p>
                      <img src={editIcon} alt="" onClick={onDisconnect} />
                    </div>
                  ) : null}
                  {failedTransaction ? (
                    <Typography style={{ color: "red" }}>
                      Not enough TON funds
                    </Typography>
                  ) : null}
                </div>
                <Stack width="100%" flexDirection="row" gap={2}>
                  {tonConnectUI.connected ? (
                    <ActionButton
                      onClick={handleSentTransaction}
                      label={`${tonPrice} TON`}
                      disableButton={disableButton}
                    />
                  ) : (
                    <ActionButton
                      onClick={() => {
                        tonConnectUI.openModal();
                      }}
                      label={`${tonPrice} TON`}
                      disableButton={disableButton}
                    />
                  )}
                  <ActionButton
                    onClick={handleBuyStars}
                    label={
                      <Stack flexDirection="row" alignItems="center" gap={0.5}>
                        {" "}
                        <img
                          width="22.14px"
                          height="22.71px"
                          src={starsIcon}
                          alt=""
                        />{" "}
                        {starsPrice}
                      </Stack>
                    }
                    disableButton={disableButton}
                  />
                </Stack>
              </Stack>
            </div>
          </div>
        </CSSTransition>
      }
    </>
  );
};
