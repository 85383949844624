import React from "react";
import { SmallCoin } from "../../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_MEDIUM } from "../../../../consts";
import { formatValues } from "../../../../utils";
import { PayPassMultiplierComponent } from "../../../page-tasks/pay-pass-multiplier";

interface IProps {
  reward?: number;
  payPassMultiplier?: number;
  modalLabel?: string;
  isDone: boolean;
  modalSubtitle?: string;
  keyComponent?: React.ReactElement;
}

export const Price: React.FC<IProps> = ({
  reward,
  payPassMultiplier,
  modalLabel,
  isDone,
  modalSubtitle,
  keyComponent,
}) => {
  return (
    <>
      {modalLabel && !isDone ? (
        <p className="task-modal-received">Complete task to get</p>
      ) : null}

      <div className="task-modal-reward ab">
        <SmallCoin
          width={BALANCE_COIN_SIZE_MEDIUM}
          height={BALANCE_COIN_SIZE_MEDIUM}
        />
        <p>+{formatValues(reward)}</p>{" "}
        <PayPassMultiplierComponent payPassMultiplier={payPassMultiplier} />
      </div>
      {keyComponent}
      <div>
        {isDone ? <p className="task-modal-received">Received</p> : null}
        {modalLabel && !isDone ? (
          <p className="task-modal-received">{modalLabel}</p>
        ) : null}
        {modalSubtitle && !isDone ? (
          <p className="task-modal-received">{modalSubtitle}</p>
        ) : null}
      </div>
    </>
  );
};
