import React from "react";

import { Stack } from "@mui/material";

import { ROUTES } from "../../routers";

const galaxyIcon = require("../img/galaxy-icon.png") as string;
const treasuresIcon = require("../img/treasures.png") as string;
const snowLeftIcon = require("./img/snow-left.svg").default as string;
const snowRightIcon = require("./img/snow-right.svg").default as string;

export const PagesLinks: React.FC = () => {
  return (
    <div className="page-claim-actions" style={{ paddingTop: "10px" }}>
      <>
        <Stack position="relative">
          <img
            style={{
              position: "absolute",
              top: "-20px",
              left: "15px",
              zIndex: "11",
              width: "72px",
            }}
            src={snowLeftIcon}
            alt=""
          />
          <a
            href={`#${ROUTES.PAY_PASS.PATH}`}
            className="page-claim-leaderboard-link ab"
          >
            <img width="152px" height="40.76px" src={galaxyIcon} />
          </a>
        </Stack>
        <Stack position="relative">
          <img
            style={{
              position: "absolute",
              top: "-7px",
              right: "-9px",
              zIndex: "11",
            }}
            src={snowRightIcon}
            alt=""
          />
          <a
            href={`#${ROUTES.CHESTS.PATH}`}
            className="page-claim-leaderboard-link ab"
          >
            <img width="173px" height="40.76px" src={treasuresIcon} />
          </a>
        </Stack>
      </>
    </div>
  );
};
