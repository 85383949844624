import React from "react";

import { Stack, Typography } from "@mui/material";
import { ActionButton } from "./action-button";
import { EModalType } from "../models";
import { formatStatisticsValue, formatValue } from "../../../utils";
import { IPendingOrder } from "../../feature/users/models";

const keyIcon = require("./img/key-icon.png") as string;
const basketIcon = require("./img/basket-icon.png") as string;
const historyIcon = require("./img/history-icon.png") as string;

interface IProps {
  onModalTypeClick: (
    modalType: EModalType | null,
    item?: IPendingOrder & {
      type: "ton" | "stars";
    }
  ) => void;
  balance?: number;
}

export const BalanceAndShop: React.FC<IProps> = ({
  onModalTypeClick,
  balance,
}) => {
  return (
    <Stack
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      maxHeight="170px"
      flex="1"
      alignItems="center"
    >
      <Stack gap={2}>
        <Stack>
          <Typography style={{ color: "#AFB4A8", fontSize: "19px" }}>
            Your Balance:
          </Typography>
          <Stack display="flex" flexDirection="row" gap={1} alignItems="center">
            <img width="40.45px" height="47.2" src={keyIcon} alt="" />
            <Typography
              className="ab"
              style={{ color: "#DFF2D8", fontSize: "37px", lineHeight: 1 }}
            >
              {formatStatisticsValue(balance, 2)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack display="flex" flexDirection="column" gap={2}>
        <ActionButton
          label="Key Shop"
          icon={basketIcon}
          width="17.54"
          height="18.02"
          onClick={() => onModalTypeClick(EModalType.KEY_SHOP)}
        />
        <ActionButton
          label="History"
          icon={historyIcon}
          width="17.54"
          height="17.54"
          onClick={() => onModalTypeClick(EModalType.HISTORY)}
        />
      </Stack>
    </Stack>
  );
};
