import React from "react";

import { Stack, Typography } from "@mui/material";

const icon = require("./voucher.png") as string;

export const IconContent: React.FC = () => {
  return (
    <Stack width="100%" justifyContent="center" alignItems="center">
      <Typography
        className="ab galaxy-color"
        style={{
          fontSize: "31px",
        }}
        textAlign="center"
      >
        Coming soon
      </Typography>
      <img width="80%" src={icon} alt="" />
    </Stack>
  );
};
