import React from "react";

import { AbsoluteSpinner } from "../../../absolute-spinner";
import { TAppUser, TClaimState } from "../../../contexts/types";
import { SmallCoin } from "../../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_SMALL } from "../../../../consts";
import { PayPassMultiplierComponent } from "../../pay-pass-multiplier";
import { formatValues } from "../../../../utils";

import { useMinerTyconApi } from "./hooks";
import { ModalContent } from "./modal";
import { ICampaign } from "./models";
import { Box, Stack } from "@mui/material";

const checkedImg = require("./img/checked.svg").default as string;
const completedTasksImg = require("./img/completed.png") as string;
const keyIcon = require("./img/key.png") as string;

interface IProps {
  user: TAppUser;
  setClaimState?: React.Dispatch<React.SetStateAction<TClaimState | undefined>>;
}
export const MinerTycoon: React.FC<IProps> = ({ user, setClaimState }) => {
  const {
    completedTasks,
    allTasks,
    tasksToDisplay,
    payPassMultiplier,
    onTaskClick,
    activeTask,
    onModalClose,
    isLoading,
    onClaimTask,
    isAllTasksDone,
    checkTrumpsImpireTaskAsync,
  } = useMinerTyconApi(user, setClaimState);

  const renderedTasks = React.useMemo(
    () =>
      tasksToDisplay.map((task) => (
        <div
          className="collab-list-item"
          onClick={() => onTaskClick(task)}
          key={task?.type}
          style={{
            position: "relative",
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              className="collab-item-icon"
              src={task?.is_done ? checkedImg : task?.icon}
              alt=""
              width="70px"
              height="70px"
            />
          </Box>
          <div className="collab-item-content">
            <div className="task-item-reward">
              <div className="task-item-info-stack">
                <div className="white-color">{task?.title}</div>
                {!task?.is_done && (
                  <div className="collab-item-desc">
                    <SmallCoin
                      width={BALANCE_COIN_SIZE_SMALL}
                      height={BALANCE_COIN_SIZE_SMALL}
                    />
                    <p className="white-color">+{formatValues(task?.reward)}</p>
                    <PayPassMultiplierComponent
                      payPassMultiplier={payPassMultiplier}
                    />
                  </div>
                )}
                <Stack
                  justifyContent="flex-start"
                  flexDirection="row"
                  alignItems="center"
                  gap={0.5}
                >
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img width="14px" src={keyIcon} alt="" />
                  </div>
                  <p className="common-color" style={{ fontSize: "15px" }}>
                    +{50}
                  </p>
                </Stack>
              </div>
            </div>
          </div>
        </div>
      )),
    [tasksToDisplay, onTaskClick]
  );

  const currentActiveTask = () =>
    tasksToDisplay.find(
      (item) => item?.type === activeTask?.type
    ) as ICampaign | null;

  return (
    <div
      className="task-wrapper"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <AbsoluteSpinner show={isLoading} />
      <ModalContent
        activeTask={currentActiveTask()}
        onClose={onModalClose}
        payPassMultiplier={payPassMultiplier}
        onClaimTask={onClaimTask}
        checkTrumpsImpireTaskAsync={checkTrumpsImpireTaskAsync}
      />
      <div
        className="task-list-main-wrapper bebebe"
        style={{ height: "auto", backdropFilter: "blur(10px)" }}
      >
        <div className="task-list-wrapper">
          {isAllTasksDone ? (
            <div className="collab-done">
              <img width="115px" src={completedTasksImg} alt="" />
              <div className="collab-done-desc">
                <p>Congratulations!</p>
                <p>You have successfully</p>
                <p>completed all tasks!</p>
              </div>
            </div>
          ) : (
            renderedTasks
          )}
        </div>
        <div className="collab-footer">
          <div>
            Tasks completed:{" "}
            <b className="collab-footer-counter ab">
              {completedTasks}/{allTasks}
            </b>
          </div>
        </div>
      </div>
    </div>
  );
};
