import React from "react";

import { Stack, Typography } from "@mui/material";
import { TStylesItems } from "../../models";
import { useConnectWalletApi } from "../../hooks";
import { TClaimState } from "../../../../../../../contexts/types";
import { truncateString } from "../../../../../../../wallet/utils";
import { Address } from "@ton/core";
import { ActionButton } from "../../../../../../../page-claim/recovery-tapping/action-button";
import { Spinner } from "../../../../../../../spinner";

const starsIcon = require("./img/stars-icon.png") as string;

const editIcon = require("./img/edit-icon.png") as string;

interface IProps {
  icon: string;
  title: string;
  onClick: (paymentType: "STARS" | "TON" | "DEFAULT") => void;
  onClose: (event: React.SyntheticEvent) => void;
  isPurchased?: boolean;
  subTitle?: string;
  stylesToBuy: TStylesItems;
  claimState?: TClaimState;
  isLoadingData?: boolean;
}

export const WStylesModalContent: React.FC<IProps> = ({
  icon,
  title,
  onClick,
  isLoadingData,
  onClose,
  isPurchased,
  subTitle,
  stylesToBuy,
  claimState,
}) => {
  const {
    address,
    onDisconnect,
    tonConnectUI,
    tonPrice,
    isLoading,
    handleSentTransaction,
    failedTransaction,
    isProcessing,
  } = useConnectWalletApi(onClick, claimState, stylesToBuy);

  return (
    <div className="app-modal-content-wrapper">
      <Spinner isLoading={!!isProcessing || isLoadingData || isLoading} />
      <div className="close-modal ab" onClick={onClose}>
        x
      </div>
      <>
        <div className="app-modal-content">
          <div className="app-modal-content-icon">
            {<img width={200} src={icon} alt="" />}
          </div>
          <Stack gap={1} alignItems="center">
            <div className="app-modal-content-label ab">{title}</div>
            {subTitle ? (
              <div
                className="app-modal-content-label"
                style={{ fontSize: "16px" }}
              >
                {subTitle}
              </div>
            ) : null}
          </Stack>
        </div>
        {isPurchased ? (
          <button className={`app-modal-content-button "btn-disabled"`}>
            <b>Purchased</b>
          </button>
        ) : (
          <Stack
            flexDirection="column"
            gap={2}
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <div
              className="connected-address"
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {address ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <p className="white-color paPassAddress">
                    Connected address:{" "}
                    {truncateString(
                      Address.parse(address).toString({
                        bounceable: false,
                      })
                    )}
                  </p>
                  <img src={editIcon} alt="" onClick={onDisconnect} />
                </div>
              ) : null}
              {failedTransaction ? (
                <Typography style={{ color: "red" }}>
                  Not enough TON funds
                </Typography>
              ) : null}
            </div>
            <Stack width="100%" flexDirection="row" gap={2}>
              {tonConnectUI.connected ? (
                <ActionButton
                  onClick={() => {
                    handleSentTransaction();
                  }}
                  label={`${tonPrice}`}
                  disableButton={isLoading}
                  tonImg={true}
                />
              ) : (
                <ActionButton
                  onClick={() => {
                    tonConnectUI.openModal();
                  }}
                  label={`${tonPrice} TON`}
                  disableButton={isLoading}
                />
              )}
              <ActionButton
                onClick={() => {
                  onClick("STARS");
                }}
                label={
                  <Stack flexDirection="row" alignItems="center" gap={0.5}>
                    {" "}
                    <img
                      width="22.14px"
                      height="22.71px"
                      src={starsIcon}
                      alt=""
                    />{" "}
                    {stylesToBuy.starsPrice}
                  </Stack>
                }
                disableButton={isLoading}
              />
            </Stack>
          </Stack>
        )}
      </>
    </div>
  );
};
