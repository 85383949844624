import React from "react";

import { TAppUser, TClaimState } from "../../../contexts/types";
import { ECampaign, ETab, ICampaign } from "./models";
import { useUpdateUserExternalApi } from "../../../../hooks";

// images
const youtubeIcon = require("./img/youtubeIcon.png") as string;
const tonIcon = require("./img/tonIconYT.svg").default as string;

export const useYoutubeFiApi = (
  user: TAppUser,
  setClaimState?: React.Dispatch<React.SetStateAction<TClaimState | undefined>>
) => {
  const [activeTab, setActiveTab] = React.useState(ETab.SPACE_SHOW);

  const payPassMultiplier = user?.claim_state?.payPassMultiplier;

  const [updateUserExternalApi, updateUserState] = useUpdateUserExternalApi();
  const [activeTask, setActiveTask] = React.useState<ICampaign | null>(null);

  const handleClaimTask = (task: ICampaign | null) => {
    if (!task) {
      return;
    }

    const payPassMultiplier = user?.claim_state?.payPassMultiplier;

    if (task && payPassMultiplier) {
      let currentField =
        activeTab === ETab.SAGA ? "youtubeSaga" : "youtubeSpaceShow";

      if (activeTab === ETab.TON_VIDEO) {
        currentField = "youtubeTon";
      }

      const newData = {
        balance:
          (user.claim_state?.balance || 0) + task.reward * payPassMultiplier,
        balance_from_clicks:
          (user.claim_state?.balance_from_clicks || 0) + task.reward,
        // @ts-ignore
        tasks: user?.claim_state.tasks
          ? {
              ...user.claim_state.tasks,
              [currentField]: {
                // @ts-ignore
                ...(user.claim_state.tasks[currentField] || {}),
                [task.type]: true,
              },
            }
          : {
              // @ts-ignore
              [currentField]: { [task.type]: true },
            },
      } as unknown as TClaimState;
      updateUserExternalApi({ ...newData })
        .then(() => {
          setClaimState?.((prevState) => ({
            ...prevState,
            ...newData,
          }));
        })
        .catch((e: any) => {
          console.warn(e);
        });
    }
  };

  const onTaskClick = React.useCallback((task: ICampaign) => {
    if (task.is_done) {
      return;
    }

    setActiveTask(task);
  }, []);

  const tasksList: ICampaign[] = React.useMemo(
    () => [
      {
        icon: youtubeIcon,
        link: `https://youtu.be/WlfGw14RJhw?si=QOyntJDbRXXeDzr2`,
        type: ECampaign.SAGA_1,
        title: "W-Saga #1",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch W-Saga #1",
      },
      {
        icon: youtubeIcon,
        link: `https://youtu.be/Dh6jdDpG1nA?si=SXl5maUdLGfY2goM`,
        type: ECampaign.SAGA_2,
        title: "W-Saga #2",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch W-Saga #2",
      },
      {
        icon: youtubeIcon,
        link: ` https://youtu.be/RuFHe0bXgc0?si=xMvklyyQYu1R_QEi`,
        type: ECampaign.SAGA_3,
        title: "W-Saga #3",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch W-Saga #3",
      },
      {
        icon: youtubeIcon,
        link: `https://youtu.be/6BACvWQgCEk?si=eeO5pW7IV0-gDEVx`,
        type: ECampaign.SAGA_4,
        title: "W-Saga #4",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch W-Saga #4",
      },
    ],
    [user?.claim_state?.tasks?.youtubeSaga]
  );

  const tasksSpaceShowList: ICampaign[] = React.useMemo(
    () => [
      {
        icon: youtubeIcon,
        link: `https://youtu.be/Y6_7G2DMkCI?si=AceMv_rrH1HjGA5q`,
        type: ECampaign.SPACE_SHOW_0,
        title: "The W-Space #0",
        reward: 100_000,
        is_done: false,
        btnLabel: "Find code in The W-Space #0",
        code: "13652447",
      },
      {
        icon: youtubeIcon,
        link: `https://youtu.be/siQspAnbYh4?si=xp7WlpjR4rXAmvdV`,
        type: ECampaign.SPACE_SHOW_1,
        title: "The W-Space #1",
        reward: 100_000,
        is_done: false,
        btnLabel: "Find code in The W-Space #1",
        code: "27480279",
      },
      {
        icon: youtubeIcon,
        link: `https://youtu.be/SKzM-eqmmuA?si=UduNoaGScv0LeaEY`,
        type: ECampaign.SPACE_SHOW_2,
        title: "The W-Space #2",
        reward: 100_000,
        is_done: false,
        btnLabel: "Find code in The W-Space #2",
        code: "85040376",
      },
      {
        icon: youtubeIcon,
        link: `https://youtu.be/N4EB27IPA_w?si=D0aJFm_Mo6kCd-8v`,
        type: ECampaign.SPACE_SHOW_3,
        title: "The W-Space #3",
        reward: 100_000,
        is_done: false,
        btnLabel: "Find code in The W-Space #3",
        code: "48276395",
      },
      {
        icon: youtubeIcon,
        link: `https://youtu.be/FmVBpoZEA7I?si=GXfdck16TUJLr2ut`,
        type: ECampaign.SPACE_SHOW_4,
        title: "The W-Space #4",
        reward: 100_000,
        is_done: false,
        btnLabel: "Find code in The W-Space #4",
        code: "54783715",
      },
      {
        icon: youtubeIcon,
        link: `https://youtu.be/VoKBIn2anX8?si=UxrVb2db6EkbC8gk`,
        type: ECampaign.SPACE_SHOW_5,
        title: "The W-Space #5",
        reward: 100_000,
        is_done: false,
        btnLabel: "Find code in The W-Space #5",
        code: "39647218",
      },
      {
        icon: youtubeIcon,
        link: `https://youtu.be/FEr9suFdmhs`,
        type: ECampaign.SPACE_SHOW_6,
        title: "The W-Space #6",
        reward: 100_000,
        is_done: false,
        btnLabel: "Find code in The W-Space #6",
        code: "54769821",
      },
      {
        icon: youtubeIcon,
        link: `https://youtu.be/xjRp_kxx6kE`,
        type: ECampaign.SPACE_SHOW_7,
        title: "The W-Space #7",
        reward: 100_000,
        is_done: false,
        btnLabel: "Find code in The W-Space #7",
        code: "64289317",
      },
      {
        icon: youtubeIcon,
        link: `https://youtu.be/AnMQ6Yenb_w`,
        type: ECampaign.SPACE_SHOW_8,
        title: "The W-Space #8",
        reward: 100_000,
        is_done: false,
        btnLabel: "Find code in The W-Space #8",
        code: "46512783",
      },
    ],
    [user?.claim_state?.tasks?.youtubeSpaceShow]
  );

  const tasksListTon: ICampaign[] = React.useMemo(
    () => [
      {
        icon: tonIcon,
        link: `https://bit.ly/4fJmwXM`,
        type: ECampaign.TON_1,
        title: "TON Video #1",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #1",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/3C0yzlO`,
        type: ECampaign.TON_2,
        title: "TON Video #2",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #2",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/4gAOGpm`,
        type: ECampaign.TON_3,
        title: "TON Video #3",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #3",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/3VMErFZ`,
        type: ECampaign.TON_4,
        title: "TON Video #4",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #4",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/4gJDHcy`,
        type: ECampaign.TON_5,
        title: "TON Video #5",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #5",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/3ZVjStv`,
        type: ECampaign.TON_6,
        title: "TON Video #6",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #6",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/3DcA0hj`,
        type: ECampaign.TON_7,
        title: "TON Video #7",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #7",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/4iimEjD`,
        type: ECampaign.TON_8,
        title: "TON Video #8",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #8",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/49p7xAX`,
        type: ECampaign.TON_9,
        title: "TON Video #9",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #9",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/41eqHYe`,
        type: ECampaign.TON_10,
        title: "TON Video #10",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #10",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/4geEMsR`,
        type: ECampaign.TON_11,
        title: "TON Video #11",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #11",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/3ZmIdYV`,
        type: ECampaign.TON_12,
        title: "TON Video #12",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #12",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/3APBsFh`,
        type: ECampaign.TON_13,
        title: "TON Video #13",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #13",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/3UHK3kc`,
        type: ECampaign.TON_14,
        title: "TON Video #14",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #14",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/4fB4jMA`,
        type: ECampaign.TON_15,
        title: "TON Video #15",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #15",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/3YTCcT6`,
        type: ECampaign.TON_16,
        title: "TON Video #16",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #16",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/4hsFJ28`,
        type: ECampaign.TON_17,
        title: "TON Video #17",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #17",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/4f2BkRI`,
        type: ECampaign.TON_18,
        title: "TON Video #18",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #18",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/3NIyO7h`,
        type: ECampaign.TON_19,
        title: "TON Video #19",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #19",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/3BOT3gQ`,
        type: ECampaign.TON_20,
        title: "TON Video #20",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #20",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/409MvUi`,
        type: ECampaign.TON_21,
        title: "TON Video #21",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #21",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/4dOWTUz`,
        type: ECampaign.TON_22,
        title: "TON Video #22",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #22",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/4f9RLLB`,
        type: ECampaign.TON_23,
        title: "TON Video #23",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #23",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/403VpTd`,
        type: ECampaign.TON_24,
        title: "TON Video #24",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #24",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/48iQL5N`,
        type: ECampaign.TON_25,
        title: "TON Video #25",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #25",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/406mpkU`,
        type: ECampaign.TON_26,
        title: "TON Video #26",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #26",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/3Nre3wR`,
        type: ECampaign.TON_27,
        title: "TON Video #27",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #27",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/4f50Pl6`,
        type: ECampaign.TON_28,
        title: "TON Video #28",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #28",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/3UbZoJA`,
        type: ECampaign.TON_29,
        title: "TON Video #29",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #29",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/3Y4a2Dk`,
        type: ECampaign.TON_30,
        title: "TON Video #30",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #30",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/4fbftHH`,
        type: ECampaign.TON_31,
        title: "TON Video #31",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #31",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/40rbGA3`,
        type: ECampaign.TON_32,
        title: "TON Video #32",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #32",
      },
      {
        icon: tonIcon,
        link: `https://bit.ly/3ClFndO`,
        type: ECampaign.TON_33,
        title: "TON Video #33",
        reward: 30_000,
        is_done: false,
        btnLabel: "Watch TON Video #33",
      },
    ],
    [user?.claim_state?.tasks?.youtubeTon]
  );

  const tasksToDisplay = React.useMemo(() => {
    return tasksList?.map((item: ICampaign) => ({
      ...item,
      // @ts-ignore
      is_done:
        (user?.claim_state?.tasks &&
          // @ts-ignore
          user.claim_state.tasks?.youtubeSaga?.[item.type]) ||
        false,
    }));
  }, [tasksList, user?.claim_state?.tasks?.youtubeSaga]);

  const tasksTonToDisplay = React.useMemo(() => {
    return tasksListTon?.map((item: ICampaign) => ({
      ...item,
      // @ts-ignore
      is_done:
        (user?.claim_state?.tasks &&
          // @ts-ignore
          user.claim_state.tasks?.youtubeTon?.[item.type]) ||
        false,
    }));
  }, [tasksList, user?.claim_state?.tasks?.youtubeTon]);

  const tasksSpaceShowToDisplay = React.useMemo(() => {
    return tasksSpaceShowList?.map((item: ICampaign) => ({
      ...item,
      // @ts-ignore
      is_done:
        (user?.claim_state?.tasks &&
          // @ts-ignore
          user.claim_state.tasks?.youtubeSpaceShow?.[item.type]) ||
        false,
    }));
  }, [tasksList, user?.claim_state?.tasks?.youtubeSpaceShow]);

  const handleModalClose = () => {
    setActiveTask(null);
  };

  return {
    tasksToDisplay,
    tasksSpaceShowToDisplay,
    tasksTonToDisplay,
    payPassMultiplier,
    onTaskClick,
    activeTask,
    onModalClose: handleModalClose,
    isLoading: updateUserState.isLoading,
    onClaimTask: handleClaimTask,
    activeTab,
    setActiveTab,
  };
};
