import React from "react";

import { TAppUser, TClaimState } from "../../../contexts/types";
import { useUpdateUserExternalApi } from "../../../../hooks";

import { ECampaign, ICampaign } from "./models";
import { useUpdateGetCheckTrumpEmpireTaskExternalApiMutation } from "../../../feature/users";

// images
const taskIcon = require("./img/taskIcon.png") as string;

export const useMinerTyconApi = (
  user: TAppUser,
  setClaimState?: React.Dispatch<React.SetStateAction<TClaimState | undefined>>
) => {
  const payPassMultiplier = user?.claim_state?.payPassMultiplier;
  const [checkTrumpsImpireTaskAsync, checkTrumpsImpireTaskAsyncState] =
    useUpdateGetCheckTrumpEmpireTaskExternalApiMutation();

  const [updateUserExternalApi, updateUserState] = useUpdateUserExternalApi();
  const [activeTask, setActiveTask] = React.useState<ICampaign | null>(null);

  const handleClaimTask = (task: ICampaign | null) => {
    if (!task) {
      return;
    }

    if (task && payPassMultiplier) {
      const newData = {
        balance:
          (user.claim_state?.balance || 0) + task.reward * payPassMultiplier,
        balance_from_clicks:
          (user.claim_state?.balance_from_clicks || 0) + task.reward,
        // @ts-ignore
        tasks: user?.claim_state.tasks
          ? {
              ...user.claim_state.tasks,
              miner_tycon: {
                // @ts-ignore
                ...(user.claim_state.tasks.miner_tycon || {}),
                [task.type]: true,
              },
            }
          : {
              // @ts-ignore
              miner_tycon: { [task.type]: true },
            },
      } as unknown as TClaimState;
      updateUserExternalApi({ ...newData })
        .then(() => {
          setClaimState?.((prevState) => ({
            ...prevState,
            ...newData,
          }));
        })
        .catch((e: any) => {
          console.warn(e);
        });
    }
  };

  const onTaskClick = (task?: ICampaign | null) => {
    if (task?.is_done) {
      return;
    } else if (task) {
      setActiveTask(task);
    }
  };

  // @ts-ignore
  const id = window?.Telegram?.WebApp?.initDataUnsafe?.user?.id || "";

  const tasksList: ICampaign[] = React.useMemo(
    () => [
      {
        icon: taskIcon,
        link: `https://app.adjust.com/1kf8zsvh?campaign=CR_WW_PPE_akatzuki&tg_id=${id}`,
        type: ECampaign.PLAY_GAME,
        title: "Play the game and buy an Akatzuki business in the NFT world",
        reward: 400_000,
        is_done: false,
        btnLabel: "Complete task",
      },
    ],
    [user?.claim_state?.tasks?.miner_tycon]
  );

  const tasksToDisplay = React.useMemo(() => {
    return tasksList?.map((item: ICampaign) => {
      if (item.type === ECampaign.PLAY_GAME) {
        return {
          ...item,
          // @ts-ignore
          is_done:
            (user?.claim_state?.tasks &&
              // @ts-ignore
              user.claim_state.tasks?.miner_tycon?.[item.type]) ||
            false,
        };
      }
    });
  }, [tasksList]);

  const completedTasks =
    tasksToDisplay.filter((task) => task?.is_done)?.length || 0;
  const allTasks = tasksToDisplay?.length || 0;
  const isAllTasksDone = completedTasks === allTasks;

  const handleModalClose = () => {
    setActiveTask(null);
  };

  return {
    completedTasks,
    allTasks,
    tasksToDisplay,
    isAllTasksDone,
    payPassMultiplier,
    onTaskClick,
    activeTask,
    onModalClose: handleModalClose,
    isLoading:
      updateUserState.isLoading || checkTrumpsImpireTaskAsyncState.isLoading,
    onClaimTask: handleClaimTask,
    checkTrumpsImpireTaskAsync,
  };
};
