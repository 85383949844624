import React from "react";

// external paths
import { Support } from "./support";
import { BottomAnimation, ZoomInAnimation } from "../page-animation";
import { Switcher } from "../switcher";
import { TemplatePages } from "../template-pages";

// internal paths
import { usePageSelectedBlockchainApi } from "./hooks";
import "./index.scss";
import { BackButton } from "@vkruglikov/react-telegram-web-app";
import { ROUTES } from "../routers";

// images
const bg = require("./img/bg.png") as string;
const logo = require("./img/w-logo.png") as string;
const logoText = require("./img/w-coin-text.png") as string;
const ton = require("./img/ton_symbol.svg").default as string;

export const PageSelectedBlockchain: React.FC = () => {
  const {
    isLoading,
    isFetching,
    userName,
    onOpenSupport,
    isAnimationSwitcherOn,
    onAnimationToggler,
    getCustomBg,
  } = usePageSelectedBlockchainApi();

  return (
    <TemplatePages
      isLoading={isLoading}
      isFetching={isFetching}
      bg={getCustomBg ? getCustomBg(bg) : bg}
    >
      <BackButton onClick={() => (window.location.hash = ROUTES.MAIN.PATH)} />
      <div className="page page-1 page-tasks main-wrapper blockchain-wrapper">
        <div className="blockchain-content-wrapper" style={{ flex: 1 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <p className="blockchain-content-name ab">Hello,</p>
            <p
              className="blockchain-content-name ab"
              style={{ textAlign: "center" }}
            >
              {" "}
              {userName}
            </p>
          </div>
          <ZoomInAnimation>
            <div className="blockchain-content-select-wrapper-main">
              <img width="218px" src={logo} alt="" />
              <div className="blockchain-ton-text-wrapper">
                <img width="136px" src={logoText} alt="" />
                <p className="blockchain-ton-text blockchain-ton-text-first">
                  Powered by $TON <img width="23px" src={ton} alt="" />
                </p>
                <p className="blockchain-ton-text">Boosted by Mates 🧑🏼‍🚀</p>
              </div>
              <div className="toggler-wrapper">
                <p className="ab toggler-text">Animation</p>{" "}
                <Switcher
                  isOn={isAnimationSwitcherOn}
                  onClick={onAnimationToggler}
                />
              </div>
            </div>
          </ZoomInAnimation>
        </div>
        <div style={{ width: "100%", borderRadius: "27px" }}>
          <BottomAnimation>
            <div className="blockchain-content-wrapper blockchain-content-footer">
              <button
                className={`app-modal-content-button page-blockchain-btn button-wrapper `}
              >
                <div className="support-wrapper" onClick={onOpenSupport}>
                  <Support />
                  <b>Support</b>
                </div>
              </button>
              <p className="blockchain-rights">
                © 2025 W-Coin. All rights reserved.
              </p>
            </div>
          </BottomAnimation>
        </div>
      </div>
    </TemplatePages>
  );
};
