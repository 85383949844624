export enum EStyles {
    NATURE = 'Nature',
    OCEAN = 'Ocean',
    GOLD = 'Gold',
    SPACE = 'Space',
    DEFAULT = 'Default',
}

export type TStylesItems = {
    icon: string;
    title: string;
    price: number;
    selected: boolean;
    purchased: boolean;
    type: EStyles;
    subTitle?: string;
    starsPrice: number,
    tonPrice:number,
};