import React, { useState } from "react";

import "react-multi-carousel/lib/styles.css";
import { motion } from "framer-motion";
import Carousel from "react-multi-carousel";

import { LEAGUE_PARAMS } from "../../consts";
import { TLeagueData } from "../../../models";
import { TLeague } from "../../../../feature/users/wai.models";
import { DEFAULT_LEAGUE_DATA } from "../../../consts";
import { ILeague } from "../../../../feature/users/models";

import "./index.scss";
import { Spinner } from "../../../../spinner";

// images
const top100 = require("./img/top100.png") as string;
const place1 = require("./img/place-1.png") as string;
const place2 = require("./img/place-2.png") as string;
const place3 = require("./img/place-3.png") as string;
const ton = require("./img/ton-icon-1.png") as string;

interface IProps {
  league?: ILeague;
  leagueData: TLeagueData;
  onGetWaiLeaderboardByLeague: (league: TLeague) => void;
  isLoading: boolean;
}

const EToggle = {
  WEEKLY: "weekly",
  TOTAL: "total",
};

export const LeagueListContent = React.memo(
  ({ league, onGetWaiLeaderboardByLeague, isLoading, leagueData }: IProps) => {
    const [currentSlideName, setCurrentSlideName] = useState<string | null>(
      null
    );

    const [initalize, setInitialize] = React.useState<Boolean>(false);

    const [carouselRef, setCarouselRef] = React.useState(false);

    React.useEffect(() => {
      const findCurSlide = Object.values(LEAGUE_PARAMS).find(
        (i) => i.name === league?.name
      );

      if (findCurSlide?.slide !== undefined && carouselRef) {
        // @ts-ignore
        carouselRef.goToSlide(findCurSlide.slide);
        setCurrentSlideName(findCurSlide.name);
      }
    }, [carouselRef, league?.name]);

    const [toggle, setToggle] = React.useState(EToggle.WEEKLY);

    const getPosition = (position: number) => {
      if (position === 1) {
        return (
          <img
            style={{ color: "#FFD700!important" }}
            src={place1}
            width="29.38px"
            height="30px"
            alt=""
          />
        );
      } else if (position === 2) {
        return (
          <img
            style={{ color: "#C0C0C0!important" }}
            src={place2}
            width="29.38px"
            height="30px"
            alt=""
          />
        );
      } else if (position === 3) {
        return (
          <img
            style={{ color: "#CD7F32!important" }}
            src={place3}
            width="29.38px"
            height="30px"
            alt=""
          />
        );
      }

      return (
        <p
          style={{ color: "#DFF2D8!important" }}
          className="table-container-item-position"
        >
          {position}
        </p>
      );
    };

    const getColor = (position: number) => {
      if (position === 1) {
        return "#FFD700";
      } else if (position === 2) {
        return "#C0C0C0";
      } else if (position === 3) {
        return "#CD7F32";
      }

      return "#DFF2D8";
    };

    const getUsers = (league?: string | null) =>
      toggle === EToggle.TOTAL && league
        ? // @ts-ignore
          leagueData[league]?.data?.total?.users
        : // @ts-ignore
          leagueData[league]?.data?.weekly?.users;

    const getUserPosition = (league: string) =>
      toggle === EToggle.TOTAL && league
        ? // @ts-ignore
          leagueData[league]?.data?.user?.total?.position
        : // @ts-ignore
          leagueData[league]?.data?.user?.weekly?.position;

    const handleBeforeChange = (nextSlide: number, state: any) => {
      if (!initalize) {
        // @ts-ignore
        setInitialize(true);
      } else if (currentSlideName !== null) {
        const findCurSlide = Object.values(DEFAULT_LEAGUE_DATA).find(
          (i) => i.name === currentSlideName
        );
        const isNextSlide =
          // @ts-ignore
          nextSlide > findCurSlide?.slide
            ? findCurSlide?.nextLeague
            : findCurSlide?.prevLeague;

        if (isNextSlide) {
          setCurrentSlideName(isNextSlide);
          // @ts-ignore
          onGetWaiLeaderboardByLeague(isNextSlide);
        }
      }
    };

    return (
      <div className={`league-list-content-root`}>
        <div className="league-list-content-header">
          <p className="league-list-content-title white-color ab">Leagues</p>
          <div className="league-list-content-divider"></div>
          <p className="white-color" style={{ height: "36px" }}>
            Reach new leagues ahead of others to earn rewards.
          </p>
        </div>
        <div
          style={{ width: "150px", height: "150px" }}
          className="league-list-content-league"
        >
          {currentSlideName && (
            <img
              src={LEAGUE_PARAMS[currentSlideName]?.icon}
              alt=""
              width="60px"
            />
          )}
          <p className="league-list-content-league-name">{currentSlideName}</p>
        </div>
        <p style={{ color: "#9A9595", display: "flex", gap: "4px" }}>
          Current max level {" - "}
          <span className="white-color">
            {league?.level || 0}/
            {currentSlideName ? LEAGUE_PARAMS[currentSlideName].level : ""}
          </span>
        </p>
        <div className="table-root">
          <Spinner isLoading={!!isLoading} />
          <div className="table-header">
            <img src={top100} alt="" width={80} height={20} />
            <div className="boosters-toggle-wrapper">
              <h2
                className={`green-color boosters-header-item wai-toggle ${
                  toggle === EToggle.WEEKLY ? "selected" : "wai-toggle-disabled"
                }`}
                onClick={() => setToggle(EToggle.WEEKLY)}
              >
                <b>This Week</b>
                {toggle === "WEEKLY" && (
                  <motion.div className="underline" layoutId="underline" />
                )}
              </h2>
              <h2
                className={`green-color boosters-header-item wai-toggle ${
                  toggle === EToggle.TOTAL ? "selected" : "wai-toggle-disabled"
                }`}
                onClick={() => setToggle(EToggle.TOTAL)}
              >
                <b>ATH</b>
                {toggle === "ALL" && (
                  <motion.div className="underline" layoutId="underline" />
                )}
              </h2>
            </div>
          </div>
          <div className="wrapper-league">
            {!isLoading && !getUsers(currentSlideName)?.length && (
              <div className="app-spinner force-left-0 empty-league">
                The league is empty.
              </div>
            )}
            <Carousel
              // @ts-ignore
              ref={(el) => setCarouselRef(el)}
              responsive={{
                desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1 },
                tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
                mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
              }}
              arrows={!isLoading}
              autoPlaySpeed={3000}
              centerMode={false}
              keyBoardControl
              minimumTouchDrag={80}
              slidesToSlide={1}
              beforeChange={handleBeforeChange}
              // afterChange={handleBeforeChange}
              customTransition="transform 300ms ease-in-out"
            >
              {Object.values(leagueData).map((leagueItem, leagueIndex) => (
                <div
                  className={`table-container ${leagueItem.name}`}
                  key={leagueIndex}
                >
                  {/* @ts-ignore */}
                  {getUsers(leagueItem.name)?.map((user, key) => (
                    <div
                      key={key}
                      className={`table-container-item ${
                        getUserPosition(leagueItem.name) > String(4) &&
                        getUserPosition(leagueItem.name) === String(key + 1) &&
                        "table-container-item-selected"
                      }`}
                    >
                      <div className="table-container-item-start">
                        <div className="table-container-item-place">
                          {getPosition(user.position)}
                        </div>
                        <p
                          className="table-container-item-name"
                          style={{ color: getColor(user.position) }}
                        >
                          {user.user_telegram_username}
                        </p>
                      </div>
                      <div className="table-container-item-reward">
                        {user.reward === 0 || leagueItem?.name === "Newbie" ? (
                          leagueItem?.name === "Newbie" || key > 9 ? (
                            ""
                          ) : (
                            "-"
                          )
                        ) : (
                          <>
                            <p>{user.reward}</p>
                            <img
                              src={ton}
                              alt="Reward Icon"
                              width="20px"
                              height="20px"
                            />
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </Carousel>
          </div>
          {currentSlideName && getUserPosition(currentSlideName) ? (
            <div className="user-place">
              Your place: {getUserPosition(currentSlideName)}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
);
