import React from "react";

import { UserContext } from "../contexts/user-context";
import { TClaimState } from "../contexts/types";
import { AnimationContext } from "../contexts/animation-context";
import { getCurrentTimestamp } from "../page-tasks/utils";
import { useUpdateUserExternalApi } from "../../hooks";
import { useUpdatePayDonateInvoiceMutation } from "../feature/users";
import WebApp from "@twa-dev/sdk";
import { LottieAnimationContext } from "../contexts/lottie-animation-context";
import {
  MAX_WORKER_MINUTES,
  MAX_WORKER_REWARD,
  MIN_WORKER_MINUTES,
} from "./consts";
import { showToast, validateOrder } from "../../utils";
import { EPrice, tonPrice } from "./models";

import { CHAIN, SendTransactionRequest, useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { beginCell, Cell, toNano } from "@ton/core";

// images
const worker = require("./img/worker.png") as string;
const dice = require("./img/dice.png") as string;
const multiplier = require("./img/multiplier.png") as string;

export const usePageClaimApi = () => {
  const [showCountdownModal, setShowCountdownModal] = React.useState(false);

  const {
    user,
    isLoading,
    isFetching,
    isSuccess,
    setClaimState,
    onOpenNotification,
    setDebouncedValue,
    workerModalStatus,
    setWorkerModalStatus,
    setHasLoadedOnce,
    hasLoadedOnce,
    canShowContent,
    task: { activeTaskCount },
    isCheckActivityIsSuccess,
    isCheckActivityIsLoading,
    isCheckActivityIsError,
    checkActivityData,
    canGetUser,
    setCanGetAuthAfterCheckActivity,
    canGetAuthAfterCheckActivity,
    getCustomBg
  } = React.useContext(UserContext);
  const isRecoveryTappingActive = (!!user?.claim_state?.boosts?.recoveryTapping || !!user?.claim_state?.tasks?.recoveryTapping); 

  const payPassMultiplier = user?.claim_state?.payPassMultiplier;
  const showAnimation =
    typeof user?.claim_state?.boosts?.showAnimation === "undefined"
      ? true
      : !!user?.claim_state?.boosts?.showAnimation;

  const [earnByMinute, setEarnByMinute] = React.useState<null | number>(null);

  React.useEffect(() => {
    if (
      typeof earnByMinute !== "number" &&
      user?.claim_state?.boosts?.worker?.last_claimed_at
    ) {
      const last_claimed_at =
        user?.claim_state?.boosts?.worker?.last_claimed_at;
      const currentTimestamp = getCurrentTimestamp();

      if (last_claimed_at) {
        const secondsPerMinute = 60;
        let minutesPassed = Math.floor(
          (currentTimestamp - last_claimed_at) / secondsPerMinute
        );

        if (minutesPassed > MAX_WORKER_MINUTES) {
          minutesPassed = MAX_WORKER_MINUTES;
        }

        if (
          workerModalStatus?.readyToShowWorkerModal &&
          !workerModalStatus?.alreadyOpenedModal &&
          !workerModalStatus?.showModal &&
          minutesPassed >= MIN_WORKER_MINUTES
        ) {
          const earn = (MAX_WORKER_REWARD / MAX_WORKER_MINUTES) * minutesPassed;
          setEarnByMinute(Math.round(earn));
        }
      }
    }
  }, [
    earnByMinute,
    user?.claim_state?.boosts?.worker?.last_claimed_at,
    workerModalStatus?.alreadyOpenedModal,
    workerModalStatus?.readyToShowWorkerModal,
    workerModalStatus?.showModal,
  ]);

  const { handleActiveAnimation } = React.useContext(AnimationContext);
  const [updateUserExternalApi, updateUserState] = useUpdateUserExternalApi();

  const claimState = user?.claim_state;

  const [diceBoost, setDiceBoost] = React.useState(1);
  const isDiceBoostActive = diceBoost > 1;

  const [isMultiplierBoostActive, setIsMultiplierBoostActive] =
    React.useState(false);

  const [addToBalance, setAddToBalance] = React.useState<number>(0);

  React.useEffect(() => {
    if (
      claimState?.boosts?.lucky_dice &&
      !claimState?.boosts?.lucky_dice?.is_used &&
      diceBoost === 1 &&
      isSuccess
    ) {
      setDiceBoost(claimState?.boosts?.lucky_dice.value);
      handleActiveAnimation?.({
        duration: 10,
        blockCount: 35,
        blockImage: dice,
      });

      setTimeout(() => {
        setDiceBoost(1);
      }, 10000);

      const boosts = {
        ...claimState.boosts,
        lucky_dice: {
          ...claimState!.boosts!.lucky_dice,
          value: 1,
          is_used: true,
        },
      };

      const newData = {
        boosts,
      } as TClaimState;

      updateUserExternalApi({ ...newData })
        .then(() => {
          setClaimState?.((prevState) => ({ ...prevState, ...newData }));
        })
        .catch((e: any) => {
          console.warn(e);
        });
    }
  }, [claimState?.boosts?.lucky_dice, isSuccess]);

  // Increase balance with duration
  const [counter, setCounter] = React.useState<{ start: number; end: number }>({
    start: 0,
    end: 0,
  });

  React.useEffect(() => {
    if (claimState?.balance && claimState?.balance !== counter.end) {
      setCounter({
        start: claimState.balance,
        end: claimState.balance,
      });
    }
  }, [claimState?.balance, counter]);

  // useEffect needs to update all additional parameters
  React.useEffect(() => {
    // update refill
    if (isRecoveryTappingActive) {
      handleRefillUpdate();
    }
  }, [claimState]);

  //  ----- update all additional parameters ------
  function handleRefillUpdate() {
    if (claimState && claimState.energy < claimState?.max_energy) {
      const now = new Date();
      const utcTimestampInSeconds = Math.floor(now.getTime() / 1000);
      const secondsPassed = utcTimestampInSeconds - claimState.last_click_at;
      const energyRefill = Math.floor(
        claimState.energy_refill_multiplier * secondsPassed
      );

      const newEnergy =
        claimState.energy + energyRefill > claimState.max_energy
          ? claimState.max_energy
          : claimState.energy + energyRefill;

      if (energyRefill > 0) {
        const newLocalClaimState = {
          energy: newEnergy,
          last_click_at: utcTimestampInSeconds,
        };

        setClaimState?.({ ...claimState, ...newLocalClaimState });
      }
    }
  }

  // Energy refill
  const energyRefill = React.useRef<any>();

  React.useEffect(() => {
    if (isRecoveryTappingActive) {

      if (
        claimState &&
        !energyRefill.current &&
        claimState!.energy < claimState?.max_energy
      ) {
        // The amount of energy + refill_multiplier should not exceed max_energy
        const nextEnergy =
          claimState!.energy + claimState.energy_refill_multiplier >
          claimState?.max_energy
            ? claimState?.max_energy
            : claimState!.energy + claimState.energy_refill_multiplier;
        energyRefill.current = setInterval(() => {
          // @ts-ignore
          setClaimState((prevState) => ({ ...prevState, energy: nextEnergy }));
        }, 1000);
      }

      return () => {
        clearInterval(energyRefill.current);
        energyRefill.current = null;
      };
    }
  }, [claimState]);

  const disableBtn = React.useMemo(() => {
    if (isDiceBoostActive) {
      return false
    }

    if (claimState) {
      return (
        claimState.energy < claimState.click_multiplier ||
        claimState.energy === 0
      );
    }
    return true;
  }, [claimState]);

  // Handle click
  const handleClickChange = () => {
    const now = new Date();
    const utcTimestampInSeconds = Math.floor(now.getTime() / 1000);

    const getNewEnergy = () => {
      return isDiceBoostActive
        ? claimState!.energy
        : Math.max(0, claimState!.energy - claimState!.click_multiplier);
    };
    const setFineshedTappingState = () => {
      
      if (claimState?.boosts?.isTappingFinished && !isRecoveryTappingActive) {
        const closeTapping = getNewEnergy() < claimState?.click_multiplier ||
        getNewEnergy() === 0;
        if (closeTapping && !isDiceBoostActive) {
          showToast('You out of a power, Mate');

        }

          return true
      } else {
        if (claimState) {
          const closeTapping = getNewEnergy() < claimState?.click_multiplier ||
          getNewEnergy() === 0;
  
          if (closeTapping) {
            setShowCountdownModal(true)
            return true;
          } 
        }
       
        return false;
      }
    }

    const newClicksValue = claimState!.clicks + 1;

    let multiplierBoost = 1;
    setIsMultiplierBoostActive(false);
    if (claimState?.boosts?.multiplier?.clicks_when_bought) {
      if (
        (claimState!.clicks -
          claimState?.boosts?.multiplier?.clicks_when_bought) %
          100 ===
        0
      ) {
        setIsMultiplierBoostActive(true);
        multiplierBoost = 100;
        handleActiveAnimation?.({
          duration: 2,
          blockCount: 15,
          blockImage: multiplier,
        });
      }
    }

    const newAddToBalance =
      claimState!.click_multiplier *
      diceBoost *
      multiplierBoost *
      payPassMultiplier!;

    setAddToBalance(newAddToBalance);
    const newLocalClaimState = {
      clicks: newClicksValue,
      energy: getNewEnergy(),
      balance: claimState!.balance + newAddToBalance,
      boosts: ({...(claimState?.boosts ?? {}), isTappingFinished: setFineshedTappingState()}),
      balance_from_clicks: claimState?.balance_from_clicks
        ? claimState.balance_from_clicks + newAddToBalance
        : newAddToBalance,
      last_click_at: utcTimestampInSeconds,
    } as TClaimState;

    setClaimState?.((prevState) => ({ ...prevState, ...newLocalClaimState }));
    setCounter({ start: claimState!.balance, end: newLocalClaimState.balance });
    setDebouncedValue?.(newLocalClaimState);
  };

  const handleCloseModal = () => {
    setWorkerModalStatus?.((prevState) => ({
      ...prevState,
      alreadyOpenedModal: true,
    }));
  };

  const handleWorkerClaimBalance = async () => {
    if (
      earnByMinute &&
      typeof claimState?.balance === "number" &&
      typeof claimState.balance_from_clicks === "number"
    ) {
      const workerBoost = {
        worker: { last_claimed_at: getCurrentTimestamp() },
      };

      const newBoosts = claimState.boosts
        ? {
            ...claimState.boosts,
            ...workerBoost,
          }
        : workerBoost;

      const newData = {
        balance: claimState.balance + earnByMinute,
        boosts: newBoosts,
        balance_from_clicks: claimState.balance_from_clicks + earnByMinute,
      } as TClaimState;

      // TODO: complete
      updateUserExternalApi({ ...newData })
        .then(() => {
          setCounter({ start: claimState!.balance, end: newData.balance });

          setClaimState?.((prevState) => ({ ...prevState, ...newData }));
          handleCloseModal();
          setEarnByMinute(null);
          handleActiveAnimation?.({
            duration: 2,
            blockCount: 15,
            blockImage: worker,
          });
        })
        .catch((e: any) => {
          console.warn(e);
        });
    }
  };

  return {
    isLoading: isLoading,
    isSuccess,
    isFetching: updateUserState.isLoading || isFetching,
    updateUserState,
    balance: user?.claim_state?.balance,
    energy: Math.max(user?.claim_state?.energy || 0, 0),
    maxEnergy: Math.max(user?.claim_state?.max_energy || 0, 0),
    user,
    onClickChange: handleClickChange,
    claimState,
    counter,
    onOpenNotification,
    disableBtn,
    setClaimState,
    isDiceBoostActive,
    isMultiplierBoostActive,
    addToBalance,
    workerModalStatus,
    setWorkerModalStatus,
    earnByMinute,
    setEarnByMinute,
    onCloseModal: handleCloseModal,
    onWorkerClaimBalance: handleWorkerClaimBalance,
    activeTaskCount,
    setHasLoadedOnce,
    hasLoadedOnce,
    canGetUser,
    canShowContent,
    payPassMultiplier,
    showAnimation,
    isCheckActivityIsSuccess,
    isCheckActivityIsLoading,
    isCheckActivityIsError,
    checkActivityData,
    setCanGetAuthAfterCheckActivity,
    canGetAuthAfterCheckActivity,
    showCountdownModal,
    setShowCountdownModal,
    isRecoveryTappingActive,
    getCustomBg,
  };
};

export const useConnectWalletApi = (
  currentPrice: EPrice,
    setClaimState:
    | React.Dispatch<React.SetStateAction<TClaimState | undefined>>
    | undefined, claimState?: TClaimState, ) => {

    const { setIsVisible } = React.useContext(LottieAnimationContext);

    // Wallets connections
    const [tonConnectUI, setOptions] = useTonConnectUI();
    const wallet = useTonWallet();
    const address = wallet?.account?.address;
    const [impactOccurred] = useHapticFeedback();
    const [updateUserExternalApi, updateUserState] = useUpdateUserExternalApi();
    const [isProcessing, setIsProcessing] = React.useState(false);
    const databaseUserId = claimState?.id

    // @ts-ignore
  const notAcceptedWallet = wallet?.name === "HOT";
  // @ts-ignore
  const isNetNotAccepted = React.useMemo(
    () => wallet?.account?.chain === CHAIN.TESTNET || notAcceptedWallet,
    [notAcceptedWallet, wallet?.account?.chain]
  );

  const [failedTransaction, setFailedTransaction] = React.useState(false);

  const handleDisconnect = async () => {
    try {
      impactOccurred('heavy')
      await tonConnectUI.disconnect();
    } catch (e) {
      console.log("Error during disconnect:", e);
    }
  };

  React.useEffect(() => {
    if (isNetNotAccepted) {
      handleDisconnect();
    }
  }, [handleDisconnect, isNetNotAccepted]);


  const updateBoosts = () => {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, '0'); 
    const month = String(now.getMonth() + 1).padStart(2, '0'); 
    const year = now.getFullYear(); 
    
    const hours = String(now.getHours()).padStart(2, '0'); 
    const minutes = String(now.getMinutes()).padStart(2, '0'); 
    const seconds = String(now.getSeconds()).padStart(2, '0');
    
    const date = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  
    const teamDonates = () => {
      let newTeamDonates: any[] = [];
  
      if (claimState?.boosts?.teamDonates) {
        newTeamDonates = [
          ...claimState.boosts.teamDonates,
          {
            amount: tonPrice[currentPrice],
            date,
            type: 'TON'
          },
        ];
      } else {
        newTeamDonates = [
          {
            amount: tonPrice[currentPrice],
            date,
            type: 'TON'
          },
        ];
      }
  
      return newTeamDonates;
    };
  
    const newData = {
      boosts: {
        ...(claimState?.boosts || {}),
        teamDonates: teamDonates(),
      },
    } as unknown as TClaimState;
  
    updateUserExternalApi({ ...newData }).then(() => {
      setClaimState?.((prevState) => ({ ...prevState, ...newData }));
      setIsVisible?.(true);

      const timeoutId = setTimeout(() => {
        setIsVisible?.(false);
      }, 4000);
    });
  }

    const handleSentTransaction = async () => {
      setFailedTransaction(false);

      if (isNetNotAccepted) {
        handleDisconnect();
      } else {
      if (!wallet || !wallet.account?.address) {
        console.error("Wallet not connected");
        return;
      }

      if (address) {
        const transaction: SendTransactionRequest = {
          validUntil: Math.floor(Date.now() / 1000) + 3600,
          messages: [
            {
              address: "UQAq4SXiWmgsve0zqMzZRmCErBC_CjZjU_P0Csptw5XlTU3O",
              amount: toNano(tonPrice[currentPrice]).toString(),
              payload: beginCell()
              .storeUint(0, 32)
              // @ts-ignore
              .storeStringTail(`type: team donation, wallet: ${wallet?.name || ''},  userId: ${databaseUserId}`)
              .endCell().toBoc().toString("base64"),
            },
          ],
        };
    
        try {
          const res = await tonConnectUI.sendTransaction(transaction);
        
          if (res?.boc) {
            const cell = Cell.fromBase64(res.boc);
            const buffer = cell.hash();
            const hashHex = buffer.toString("hex");
        
                
            if (hashHex && databaseUserId) {
              setIsProcessing(true);
              const checkTransaction = await validateOrder(
                hashHex,
                databaseUserId
              );

            if (checkTransaction) {
                setIsProcessing(false);
                updateBoosts();
                setIsVisible?.(true);
                setFailedTransaction(false);

                const timeoutId = setTimeout(() => {
                  setIsVisible?.(false);
                }, 4000);
              } else if (checkTransaction === false) {
                setIsProcessing(false);
                setFailedTransaction(true);
              }
            } 
          }
        } catch (e) {
          setIsProcessing(false);
          console.error(e, 'Transaction error');
        }
      }
      }
    };

    return {
      isWalletConnected: tonConnectUI.connected,
      address,
      tonConnectUI,
      setOptions,
      onDisconnect: handleDisconnect,
      handleSentTransaction,
      isLoading: updateUserState.isLoading as boolean,
      failedTransaction,
      isProcessing,
    }
}

export const useTeamDonateApi = (claimState?: TClaimState, setClaimState?: React.Dispatch<React.SetStateAction<TClaimState | undefined>> ) => {
  const [updatePayDonateInvoice, updatePayDonateInvoiceState] =
  useUpdatePayDonateInvoiceMutation();
const [currentPrice, setCurrentPrice] = React.useState<EPrice>(EPrice.MEDIUM);

const [updateUserExternalApi, updateUserState] = useUpdateUserExternalApi();
const { setIsVisible } = React.useContext(LottieAnimationContext);


const updateBoosts = (amount: number) => {
  const now = new Date();
  const day = String(now.getDate()).padStart(2, '0'); 
  const month = String(now.getMonth() + 1).padStart(2, '0'); 
  const year = now.getFullYear();
  
  const hours = String(now.getHours()).padStart(2, '0'); 
  const minutes = String(now.getMinutes()).padStart(2, '0'); 
  const seconds = String(now.getSeconds()).padStart(2, '0');
  
  const date = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

  const teamDonates = () => {
    let newTeamDonates: any[] = [];

    if (claimState?.boosts?.teamDonates) {
      newTeamDonates = [
        ...claimState.boosts.teamDonates,
        {
          amount,
          date,
          type: 'stars'
        },
      ];
    } else {
      newTeamDonates = [
        {
          amount,
          date,
          type: 'stars'
        },
      ];
    }

    return newTeamDonates;
  };

  const newData = {
    boosts: {
      ...(claimState?.boosts || {}),
      teamDonates: teamDonates(),
    },
  } as unknown as TClaimState;

  updateUserExternalApi({ ...newData }).then(() => {
    setClaimState?.((prevState) => ({ ...prevState, ...newData }));
    setIsVisible?.(true);
    const timeoutId = setTimeout(() => {
      setIsVisible?.(false);
    }, 4000);
  });
}

const handleUpdateDonate = async (amount: number) => {
  try {
    const result = await updatePayDonateInvoice({ amount }).unwrap();

    if (result?.result) {
      WebApp.openInvoice(result?.result, (status) => {
        if (status === "paid") {
          updateBoosts(amount);
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
};

const {
  isWalletConnected,
  address,
  tonConnectUI,
  setOptions,
  onDisconnect: handleDisconnect,
  handleSentTransaction,
  isLoading,
  failedTransaction,
  isProcessing,
} = useConnectWalletApi(currentPrice, setClaimState, claimState);

  return {
    updatePayDonateInvoiceState,
    updateUserState,
    handleUpdateDonate,
    currentPrice,
    setCurrentPrice: (price: EPrice) => setCurrentPrice(price),
    isWalletConnected,
    address,
    tonConnectUI,
    setOptions,
    onDisconnect: handleDisconnect,
    handleSentTransaction,
    isTonDonateLoading: isLoading,
    failedTransaction,
    isProcessing,
  }
} 
