import React from "react";

import "./index.scss";
import { RightAnimation } from "../../../page-animation";
import { TradeButton } from "./trade-button";
import { PAY_PASS_MULTIPLIER, TELEGRAM_LINK } from "../../../../consts";
import { useWebApp } from "@vkruglikov/react-telegram-web-app";
import { isPayPasPurchased } from "../../../../utils";
import { Stack } from "@mui/material";
import { RecoveryTapping } from "./recovery-tapping";
import { TClaimState } from "../../../contexts/types";

interface IProps {
  balance: number;
  maxBalance: number;
  currentTradeSessionStartAt: number | null;
  onClaim: () => void;
  remainingTime: string | null;
  canClaim: boolean;
  payPassMultiplier?: number;
  claimState?: TClaimState;
  setClaimState:
    | React.Dispatch<React.SetStateAction<TClaimState | undefined>>
    | undefined;
}

export const ActionButton: React.FC<IProps> = ({
  balance,
  maxBalance,
  currentTradeSessionStartAt,
  onClaim,
  remainingTime,
  canClaim,
  payPassMultiplier,
  claimState,
  setClaimState,
}) => {
  const webApp = useWebApp();

  const handleClaim = () => {
    webApp.openTelegramLink(TELEGRAM_LINK);
    onClaim();
  };

  const payPasPurchased = payPassMultiplier === PAY_PASS_MULTIPLIER;
  const isWaiMultiplierActive = !!claimState?.boosts?.wai_boost;

  const getButton = () => {
    if (canClaim) {
      return (
        <div
          onClick={handleClaim}
          className={`${
            isPayPasPurchased(payPassMultiplier)
              ? "wai-action-text-timer-claim-pay-pass"
              : "wai-action-text-timer-claim"
          } ab`}
        >
          {payPasPurchased
            ? `Claim x${isWaiMultiplierActive ? 10 : 2}`
            : `Claim ${isWaiMultiplierActive ? "x5" : ""}`}
        </div>
      );
    } else if (currentTradeSessionStartAt === null) {
      return <TradeButton onClaim={onClaim} />;
    } else {
      return remainingTime ? (
        <div className="wai-action-text-timer-claim ab">
          <div
            style={{
              width: "120px",
              height: "29.9px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {remainingTime}
          </div>
        </div>
      ) : null;
    }
  };

  const [showRecoveryTappingModal, setShowRecoveryTappingModal] =
    React.useState(false);

  return (
    <>
      <RecoveryTapping
        showModal={showRecoveryTappingModal}
        onShow={setShowRecoveryTappingModal}
        claimState={claimState}
        setClaimState={setClaimState}
      />
      <div className="wai-action-button-root">
        <RightAnimation>
          <div className="wai-action-wrapper">
            <p className="white-color wai-action-text">
              Per session: {balance.toFixed(2)}/{maxBalance.toFixed(2)} WAI
            </p>
            <Stack display="flex" flexDirection="row" gap={1}>
              <div
                style={{ width: "220px!important" }}
                className={`action-btn-wrapper ${
                  payPasPurchased ? "action-btn-wrapper-pay-pass-width" : ""
                }`}
              >
                {getButton()}
              </div>
              {isWaiMultiplierActive ? null : (
                <div
                  onClick={() => setShowRecoveryTappingModal(true)}
                  style={{
                    width: "81.82px !important",
                    height: "64.79px  !important",
                  }}
                >
                  <div className="wai-action-text-timer-claim wai-action-text-timer-claim-multiplier ab">
                    x5
                  </div>
                </div>
              )}
            </Stack>
          </div>
        </RightAnimation>
      </div>
    </>
  );
};
