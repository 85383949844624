import React from "react";

import {
  useHapticFeedback,
  useWebApp,
} from "@vkruglikov/react-telegram-web-app";

import { TTelegramGroupVerify } from "../../contexts/types";
import { useOpenTelegramLink } from "../../hooks";
import { ITask } from "../type";

interface IProps {
  currentTask: ITask;
  onClaim?: () => void;
  isJoinedToTelegram?: boolean;
  onTelegramGroupVerify?: TTelegramGroupVerify;
}

export const VerifyButton: React.FC<IProps> = ({
  currentTask,
  onClaim,
  isJoinedToTelegram,
  onTelegramGroupVerify,
}) => {
  const webApp = useWebApp();

  const { onOpenTelegramLink } = useOpenTelegramLink();

  const [isVerified, setIsVerified] = React.useState<boolean>(false);
  const [isTryVerified, setIsTryVerified] = React.useState<
    boolean | undefined
  >();
  const [impactOccurred] = useHapticFeedback();
  const handleVerify = () => {
    if (currentTask?.verifyLink && onTelegramGroupVerify) {
      onTelegramGroupVerify(currentTask?.verifyLink).then((res) => {
        if (res.data?.is_member) {
          setIsVerified(true);
          setIsTryVerified(undefined);
        } else {
          setIsVerified(false);
          setIsTryVerified(false);
        }
        impactOccurred("heavy");
      });
    }
  };

  const handleTryVerifiedContent = () => {
    if (isTryVerified) {
      return null;
    } else if (typeof isTryVerified === "undefined") {
      return null;
    } else if (isTryVerified === false) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "#fff",
            position: "absolute",
            top: "-36%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
          }}
        >
          <p>You have not joined the community.</p>
          <p>Try again</p>
        </div>
      );
    } else {
      return null;
    }
  };

  const handleOpen = () => {
    if (currentTask?.verifyLink) {
      if (currentTask?.is_done) {
        if (currentTask.link) {
          webApp.openTelegramLink(currentTask.link);
        } else {
          onOpenTelegramLink();
        }
      } else {
        if (currentTask.link) {
          webApp.openTelegramLink(currentTask.link);
        } else {
          onOpenTelegramLink?.(currentTask.verifyLink);
        }
      }
    }
  };

  return (
    <div
      className="task-modal-button-wrapper"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        position: "relative",
      }}
    >
      {handleTryVerifiedContent()}
      {!isJoinedToTelegram && (
        <button
          onClick={isVerified ? onClaim : handleVerify}
          className={`app-modal-content-button ${
            currentTask.is_done ? "btn-disabled" : ""
          }`}
        >
          {isVerified ? "Claim" : "Verify"}
        </button>
      )}

      {!currentTask.is_done && (
        <div className="task-modal-button-wrapper">
          <button onClick={handleOpen} className={`app-modal-content-button`}>
            {currentTask?.btnLabel}
          </button>
        </div>
      )}
    </div>
  );
};
