import React from "react";

import { Stack, Typography } from "@mui/material";

import { EModalType, TChestsHistory } from "../../models";
import { PendingOrder } from "../../../feature/users/models";

import { TreasuresContent } from "./treasures-content";
import { ToggleItems } from "./toggle-items";
import { PendingContent } from "./pending-content";

import "./index.scss";

interface IProps {
  history?: TChestsHistory[];
  pendingOrders?: PendingOrder;
  onModalTypeClick: (value: EModalType | null) => void;
}

export const HistoryContent: React.FC<IProps> = ({
  history,
  pendingOrders,
  onModalTypeClick,
}) => {
  const [activeTab, setActiveTab] = React.useState<"Pending" | "Treasures">(
    "Pending"
  );

  const handleActiveTabChange = (value: "Pending" | "Treasures") => {
    setActiveTab(value);
  };

  return (
    <Stack width="100%">
      <Typography
        className="ab"
        style={{ fontSize: "35px", color: "#D6D6E7" }}
        variant="h4"
        component="h2"
        justifyContent="center"
        textAlign="center"
      >
        History
      </Typography>
      <Stack display="flex" flex="1" width="100%" overflow="hidden">
        <ToggleItems
          activeTab={activeTab}
          handleActiveTabChange={handleActiveTabChange}
        />
        {activeTab === "Pending" && (
          <PendingContent
            onModalTypeClick={onModalTypeClick}
            pendingOrders={pendingOrders}
          />
        )}
        {activeTab === "Treasures" && <TreasuresContent history={history} />}
      </Stack>
    </Stack>
  );
};
