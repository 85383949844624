export interface ITask {
  stringIcon?: string;
  componentIcon?: React.ReactElement;
  icon?: string;
  title: string;
  subtitle?: string;
  modalLabel?: string;
  modalSubLabel?: string;
  modalSubSubLabel?: string;
  link: string | null;
  reward: number;
  is_done?: boolean;
  type: ETask | string;
  btnLabel: string;
  index: number;
  isReadyToClaim?: boolean;
  isTest?: boolean;
  additionalRewardInfo?: string;
  verifyLink?: string;
  verifyExternalLink?: boolean;
  verifyExternalTaskName?: string;
  hasAnalytics?: boolean;
  openPageBeforeTaskReadyType?: string;
  isJSONTask?: boolean;
  hasTelegramLink?: boolean;
  allowedLanguages?: string[];
  excludeLang?: string[];
  refLink?: string;
  claimTask?: boolean;
  category: "Social" | "Web3" | "In-game" | "Specials";
  showProbability: number;
  isAdverts?: boolean;
  doubleVerify?: boolean;
  checkCode?: string;
  isKeyPrice?: number;
  share?: {
    mediaUrl: string;
    text?: string;
    widget_link: {
      url?: string;
      text?: string;
    }
  }
  checkUser?: boolean;
}

export type TGroupTask = {
    [key: string]: ITask[];
}

export enum ETab {
    OKX = 'OKX',
    ACTIVE = "ACTIVE",
    COMPLETED = "COMPLETED",
    AZURE = 'AZURE',
    BITGET_WALLET = 'BITGET_WALLET',
    TEA_FI = 'TEA_FI',
    YOUTUBE = 'YOUTUBE',
    MINER_TYCOON = 'MINER_TYCOON',
}

export enum ETask {
    TELEGRAM = "task_join_community_claimed",
    X = "is_x_joined",
    BLOCKCHAIN = "selected_blockchain",
    CONNECT_WALLET = 'connect_wallet',
    INVITE_MATE_CLAIMED = "task_invite_mate_claimed",
    TASK_UPGRADE_CHARGER_TO_MAX_CLAIMED = "task_upgrade_charger_to_max_claimed",
    TASK_TRY_STAKING_CLAIMED = "task_try_staking_claimed",
    TASK_CLICK_LEVEL = "task_click_level",
    TASK_VISIT_EVERY_DAY = "task_visit_every_day",
    TASK_BALANCE_LEVEL = "task_balance_level",
    TASK_IS_PREMIUM = "task_is_premium_claimed",
    TASK_RETWEET = "task_retweet_contest",
    TASK_PLAY_CATIZENS = "task_play_catizens",
    TASK_JETTON = "task_jetton",
    YES_ORIGIN_COIN = "YES_ORIGIN_COIN",
    DAILY_SCAN = "DAILY_SCAN",
    INVITE_THREE_MATE = "inviteThreeMatesData",
    TASK_WCOIN_IN_NICKNAME_VISIT_EVERY_DAY = "task_wcoin_in_nickname_visit_every_day_new",
  }
  
  export enum EExternalVerifyTasks {
    LEGEND_COMMUNITY_PLAY_GAME = 'LEGEND_COMMUNITY_PLAY_GAME',
  }

  export type TAdsGramParams = {
    blockId: string;
    onReward: () => void;
    onError?: (result: any) => void;
  };