export enum ECampaign {
    CONNECT_WALLET = "connett_wallet",
    FOLLOW_X = "follow_x",
    JOIN_TG = "join_tg",
    JOIN_TG_COMMUNITY = "join_tg_community",

  }
  
  export interface ICampaign {
      icon: string;
      link: string;
      type: ECampaign;
      subtitle?: string;
      title: string;
      btnLabel: string;
      reward: number;
      connection?: boolean;
      externalLink?: boolean;
      is_done?: boolean;
      errorMessage?: string
      tgName?: string
  }
  