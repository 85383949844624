import React from "react";

import { CSSTransition } from "react-transition-group";
import { WStylesModalContent } from "./wstyles-modal-content";
import { TStylesItems } from "../models";
import { TClaimState } from "../../../../../../contexts/types";

interface IProps {
  open: boolean;
  onClose: () => void;
  onUpdate: (paymentType: "STARS" | "TON" | "DEFAULT") => void;
  stylesToBuy: TStylesItems | null;
  isPurchased?: boolean;
  isLoading?: boolean;
  claimState?: TClaimState;
}

export const WStylesModal: React.FC<IProps> = ({
  open,
  onClose,
  onUpdate,
  stylesToBuy,
  isPurchased,
  isLoading,
  claimState,
}) => {
  return (
    <CSSTransition in={!!open} timeout={400} classNames="fade" unmountOnExit>
      <div className="overlay app-modal-root blur">
        {stylesToBuy && (
          <WStylesModalContent
            isLoadingData={isLoading}
            stylesToBuy={stylesToBuy}
            onClick={onUpdate}
            icon={stylesToBuy?.icon}
            title={stylesToBuy.title}
            subTitle={stylesToBuy.subTitle}
            onClose={onClose}
            isPurchased={isPurchased}
            claimState={claimState}
          />
        )}
      </div>
    </CSSTransition>
  );
};
