import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { EAPITagType } from "./enums";
import { getClientTimestamp } from "./utils";

export const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const JWT_TOKEN_KEY = "jwtToken";

export const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery: retry(fetchBaseQuery({
    baseUrl: REACT_APP_API_ENDPOINT || "http://localhost:1337",
    credentials: "include",
    prepareHeaders(headers, { endpoint }) {
      // Do not set the authorization header for the auth endpoint
      if (endpoint === "auth" || endpoint === "register") {
        return headers;
      }
      // Get the JWT token from local storage
      const token = localStorage.getItem(JWT_TOKEN_KEY);

      // Set the authorization header with the bearer token
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }), {maxRetries: 3}),
  tagTypes: [
    EAPITagType.USERS,
    EAPITagType.MATES_LIST,
    EAPITagType.MATES_GET_REWARDS,
    EAPITagType.LEADERBOARD,
    EAPITagType.VALIDATE_USER_IN_GROUP,
    EAPITagType.TASKS,
    EAPITagType.STATS,
    EAPITagType.STAKE_CREATE,
    EAPITagType.CLAIM_STAKING,
    EAPITagType.USER_PASSIVE_INCOME,
    EAPITagType.USER_PASSIVE_INCOME_CLAIM,
    EAPITagType.USER_PASSIVE_INCOME_INCREASE_LEVEL,
    EAPITagType.WAI_LEADERBOARD,
    EAPITagType.NOTIFY_USERS,
  ],
  endpoints: () => ({}),
});

const AUTH_TOKEN = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImNtM3ptZ3c0NTAwMDI1NmZubzl6bW5qeHgiLCJuYW1lIjoid2NvaW4iLCJyb2xlIjoiU1VQRVJfQURNSU4iLCJpYXQiOjE3MzI3MTc2OTMsImV4cCI6MTc0ODI2OTY5M30.19_LZI_E4SDFmAYWGb4Qrj3IVsd-HcXJUMZojoZJ04go0Iv8Nld2srMSXg7fyvz_75f-kVsMgfFqetcfLDKeRBGm4Vqg80-QqAKZR_8vActAT4IB9tkNTUhXLhPbaRDiYu_E0NYw-qEhpv4lVxn9_ihe61Fp8xTVxjzDpeY8TP95CFYMXmC_pwJfqw8HC7pgnWtpz_XBXVxI1Itcvtg36S2KKKsdjE6U417qw7AhTmjL5B8AX_Dwe_j_9muF-Sr5ktCHB1y9XpitXrAxD8FjOrdVjZffrqWbSs2M7pflp4JhRQ1lNywMcc_LM8ZkBmJ6Ca8blrWedvx1HE9HRKr_hw';

export const legendBaseApi = createApi({
  reducerPath: "legendApi",
  baseQuery: retry(fetchBaseQuery({
    baseUrl: "https://partnerapi.legendscommunity.app/",
    credentials: "include",
    prepareHeaders(headers) {
      headers.set("Authorization", `Bearer ${AUTH_TOKEN}`);
      headers.set('Content-Type', 'application/json');

      return headers;
    },
  }), {maxRetries: 3}),
  tagTypes: [
    EAPITagType.LEGENDS
  ],
  endpoints: () => ({}),
});

export const rabbitBaseApi = createApi({
  reducerPath: "rabbitApi",
  baseQuery: retry(fetchBaseQuery({
    baseUrl: "https://open-api.fomohash.com/api/v1/subscription-status/",
    credentials: "include",
    prepareHeaders(headers) {
      // headers.set("Authorization", `Bearer ${AUTH_TOKEN}`);
      // headers.set('Content-Type', 'application/json');
      return headers;
    },
  }), {maxRetries: 3}),
  endpoints: () => ({}),
});

export const externalBaseApi = createApi({
  reducerPath: "externalApi",
  baseQuery: retry(fetchBaseQuery({
    credentials: "include",
    baseUrl: REACT_APP_API_ENDPOINT || "http://localhost:1337",
    prepareHeaders(headers) {
      // @ts-ignore
      const initData = window?.Telegram?.WebApp?.initData || process.env.REACT_APP_INIT_DATA_EXTERNAL_QUERY;
      const encodedInitData = btoa(initData);

      const clientTimestamp = getClientTimestamp().toString();
  
      headers.set("x-init-data", encodedInitData);
      headers.set("x-request-timestamp", clientTimestamp);
      headers.set("Content-Type", "application/json");

      return headers;
    },
  }), { maxRetries :3 }),
  tagTypes: [
    EAPITagType.EXTERNAL_API
  ],
  endpoints: () => ({}),
});

export const checkOrderBaseApi = createApi({
  reducerPath: "checkOrderApi",
  baseQuery: retry(fetchBaseQuery({
    credentials: "include",
    baseUrl: REACT_APP_API_ENDPOINT || "http://localhost:1337",
    prepareHeaders(headers) {
      // @ts-ignore
      const initData = window?.Telegram?.WebApp?.initData || process.env.REACT_APP_INIT_DATA_EXTERNAL_QUERY;
      const encodedInitData = btoa(initData);

      const clientTimestamp = getClientTimestamp().toString();
  
      headers.set("x-init-data", encodedInitData);
      headers.set("x-request-timestamp", clientTimestamp);
      headers.set("Content-Type", "application/json");

      return headers;
    },
  }), { maxRetries: 0 }),
  tagTypes: [
    EAPITagType.CHECK_ORDER_API
  ],
  endpoints: () => ({}),
});

export const okxCheckUidBaseApi = createApi({
  reducerPath: 'okxCheckUid',
  baseQuery: retry(fetchBaseQuery({
    credentials: 'include',
    baseUrl: 'https://www.okx.com',
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }), {maxRetries: 3}),
  tagTypes: [EAPITagType.OKX_CHECK_UID],
  endpoints: () => ({}),
});




