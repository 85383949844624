import React from "react";

import { Button, Typography } from "@mui/material";

interface IProps {
  onClick: () => void;
  disableButton: boolean;
  label: React.ReactElement | string;
}

export const ActionButton: React.FC<IProps> = ({
  onClick,
  disableButton,
  label,
}) => {
  return (
    <Button
      onClick={onClick}
      className={disableButton ? "btn-disabled" : ""}
      sx={{
        background: "#FFF",
        width: "100%",
        borderRadius: "22px",
        height: "48px",
        position: "relative",
      }}
    >
      <Typography
        className="ab"
        sx={{
          color: "#9747FF",
          fontSize: "20px",
          textTransform: "none",
        }}
      >
        {label}
      </Typography>
    </Button>
  );
};
