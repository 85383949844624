import React from "react";

import { Box } from "@mui/material";

import { AbsoluteSpinner } from "../../../absolute-spinner";
import { TAppUser, TClaimState } from "../../../contexts/types";
import { SmallCoin } from "../../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_SMALL } from "../../../../consts";
import { PayPassMultiplierComponent } from "../../pay-pass-multiplier";
import { formatValues } from "../../../../utils";
import { useLazyGetValidateUserInGroupQuery } from "../../../feature/users";

import { useTeaFiApi } from "./hooks";
import { ModalContent } from "./modal";
import { ECampaign, ICampaign } from "./models";
import { useTimerApi } from "./timer/hooks";

const campaignLogo = require("./img/teaFiIcon.png") as string;
const checkedImg = require("./img/checked.svg").default as string;

interface IProps {
  user: TAppUser;
  setClaimState?: React.Dispatch<React.SetStateAction<TClaimState | undefined>>;
}
export const TeaFi: React.FC<IProps> = ({ user, setClaimState }) => {
  const {
    completedTasks,
    allTasks,
    tasksToDisplay,
    payPassMultiplier,
    onTaskClick,
    activeTask,
    onModalClose,
    isLoading,
    onClaimTask,
    onTeafiVerify,
    timestamp,
  } = useTeaFiApi(user, setClaimState);
  const [fetchValidateUserInGroup, state] =
    useLazyGetValidateUserInGroupQuery();

  const handleTelegramGroupVerify = (resource: string) => {
    return fetchValidateUserInGroup({ resource });
  };
  const { formattedTime } = useTimerApi(timestamp as number);

  const renderedTasks = () => {
    return tasksToDisplay().map((task) => {
      const showTimer = () => {
        if (task?.type === ECampaign.CONNECT_WALLET) {
          if (formattedTime === "00:00:00") {
            return false;
          } else if (typeof formattedTime === "string") {
            return true;
          } else {
            return false;
          }
        }

        return false;
      };

      const showLabel = () => {
        if (task?.type === ECampaign.CONNECT_WALLET) {
          if (formattedTime === "00:00:00") {
            return true;
          } else if (typeof formattedTime === "string") {
            return false;
          } else {
            return true;
          }
        }

        return task?.is_done;
      };

      const getImg = () => {
        if (task?.type === ECampaign.CONNECT_WALLET) {
          if (formattedTime === "00:00:00") {
            return task.icon;
          } else if (typeof formattedTime === "string") {
            return checkedImg;
          } else {
            return task.icon;
          }
        } else {
          return task.is_done ? checkedImg : task.icon;
        }
      };

      return (
        <div
          className="collab-list-item"
          onClick={() => onTaskClick(task)}
          key={task.type}
          style={{
            position: "relative",
          }}
        >
          <img
            className="collab-item-icon"
            src={getImg()}
            alt=""
            width="70px"
            height="70px"
          />
          <div className="collab-item-content">
            <div className="task-item-reward">
              <div className="task-item-info-stack">
                {showTimer() ? (
                  <Box
                    style={{
                      position: "absolute",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        color: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        height: "100%",
                        alignItems: "baseline",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <p style={{ color: "#fff" }}>
                        The task will be available in{" "}
                      </p>
                      <p style={{ color: "#fff" }}>{formattedTime}</p>
                    </div>
                  </Box>
                ) : (
                  <div className="white-color">{task.title}</div>
                )}

                {showLabel() ? (
                  <div className="collab-item-desc">
                    <SmallCoin
                      width={BALANCE_COIN_SIZE_SMALL}
                      height={BALANCE_COIN_SIZE_SMALL}
                    />
                    <p className="white-color">+{formatValues(task.reward)}</p>
                    <PayPassMultiplierComponent
                      payPassMultiplier={payPassMultiplier}
                    />
                  </div>
                ) : null}

                {task?.type !== ECampaign.CONNECT_WALLET && !task?.is_done ? (
                  <div className="collab-item-desc">
                    <SmallCoin
                      width={BALANCE_COIN_SIZE_SMALL}
                      height={BALANCE_COIN_SIZE_SMALL}
                    />
                    <p className="white-color">+{formatValues(task.reward)}</p>
                    <PayPassMultiplierComponent
                      payPassMultiplier={payPassMultiplier}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const currentActiveTask = () =>
    tasksToDisplay().find(
      (item) => item.type === activeTask?.type
    ) as ICampaign | null;

  return (
    <div
      className="task-wrapper"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <AbsoluteSpinner show={isLoading || state.isLoading} />
      <ModalContent
        activeTask={currentActiveTask()}
        onClose={onModalClose}
        payPassMultiplier={payPassMultiplier}
        onTelegramGroupVerify={handleTelegramGroupVerify}
        onClaimTask={onClaimTask}
        onTeafiVerify={onTeafiVerify}
      />
      <div
        className="task-list-main-wrapper bebebe"
        style={{ height: "auto", backdropFilter: "blur(10px)" }}
      >
        <div className="task-list-wrapper">{renderedTasks()}</div>
        <div className="collab-footer">
          <div>
            Tasks completed:{" "}
            <b className="collab-footer-counter ab">
              {completedTasks}/{allTasks}
            </b>
          </div>
        </div>
      </div>
      <img
        style={{ display: "flex", margin: "0 auto" }}
        width="138xpx"
        src={campaignLogo}
        alt=""
      />
    </div>
  );
};
