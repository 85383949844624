import React from "react";

import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { IPendingOrder, PendingOrder } from "../../../../feature/users/models";
import { EKeyType, EModalType } from "../../../models";
import { useKeyShopContentApi } from "../hooks";
import { KEYS } from "../../../consts";
import { Spinner } from "../../../../spinner";

const tonIcon = require("./img/tonIcon.png") as string;
const starsIcon = require("./img/starsIcon.png") as string;

interface IProps {
  onCloseModal: () => void;
  onModalTypeClick: (
    modalType: EModalType | null,
    item?: IPendingOrder & {
      type: "ton" | "stars";
    }
  ) => void;
  pendingOrders?: PendingOrder;
  currentOrderItem:
    | (IPendingOrder & {
        type: "ton" | "stars";
      })
    | null;
}

export const CheckTransaction: React.FC<IProps> = ({
  onCloseModal,
  onModalTypeClick,
  currentOrderItem,
}) => {
  const {
    isLoading,
    onCheckTransactionKeys,
    onCloseOrder,
    inputCode,
    onInputChange,
    errorMessage,
  } = useKeyShopContentApi(onModalTypeClick);

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  const isTonOrderOpened = currentOrderItem?.type === "ton";

  const [cancelNotification, setCancelNotification] = React.useState(0);

  const [notificationModals, setNotificationModals] = React.useState(false);

  const handleNotificationModalsToggle = (value: boolean) => {
    setNotificationModals(value);
  };

  const handleClick = (order_id?: string, type?: "ton" | "stars") => {
    if (cancelNotification === 0) {
      handleNotificationModalsToggle(true);
      setCancelNotification((prevState) => prevState + 1);
    } else if (cancelNotification === 1 || cancelNotification === 2) {
      setCancelNotification((prevState) => prevState + 1);
    } else if (cancelNotification === 3) {
      onInputChange("");

      if (order_id && type) {
        onCloseOrder(order_id, type);
      }
    }
  };

  const handleOpenInstruction = () => {
    window.open(
      "https://telegra.ph/W-Treasures-Payment-Orders-12-13",
      "_blank"
    );
  };

  const getPriceAndType = (productType: EKeyType, type: "ton" | "stars") => {
    return Object.values(KEYS).find((item) => item.type === productType)?.[
      type
    ];
  };

  return (
    <Stack
      width="100%"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Spinner
        isLoading={isLoading}
        label="Verifying transaction…"
        marginTop="-26px"
      />
      {notificationModals && (
        <div className="overlay app-modal-root blur">
          <Stack
            style={{
              borderRadius: "16px",
              padding: "22.74px 21.74px",
              backgroundColor: "#fff",
              position: "relative",
              width: "316px",
              gap: "20px",
            }}
          >
            <IconButton
              onClick={() => {
                handleNotificationModalsToggle(false);
                setCancelNotification((prevState) => prevState + 1);
              }}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                color: "#000",
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography textAlign="center" color="error" fontSize="20px">
              <b>Warning!</b>
            </Typography>
            <Typography
              textAlign="center"
              sx={{ color: "#000", lineHeight: "1.3" }}
              fontSize="16px"
            >
              Make sure the money wasn’t taken. If you cancel, you{" "}
              <b>can’t get the purchase back.</b>
            </Typography>
            <Button
              className="ab"
              sx={{
                color: "#fff",
                width: "100%",
                height: "54px",
                backgroundColor: "#9747FF",
                borderRadius: "22px",
                fontSize: "16px",
                textTransform: "none",
              }}
              onClick={handleOpenInstruction}
            >
              Check instructions
            </Button>
          </Stack>
        </div>
      )}
      <IconButton
        onClick={() => {
          onInputChange("");
          onCloseModal();
        }}
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          color: "#fff",
        }}
      >
        <CloseIcon />
      </IconButton>

      <Stack gap="8px">
        <p
          className="ab"
          style={{
            fontSize: "140px",
            color: "#fff",
            margin: "0 auto",
            marginBottom: "20px",
          }}
        >
          #
        </p>
        <p
          className="ab"
          style={{ fontSize: "35px", color: "#fff", margin: "0 auto" }}
        >
          Pending Order
        </p>
        {isTonOrderOpened ? (
          <p
            style={{
              fontSize: "18px",
              color: "#fff",
              margin: "0 auto",
              textAlign: "center",
            }}
          >
            Input your transaction hash to check your payment status
          </p>
        ) : null}
        <a
          target="_blank"
          href="https://telegra.ph/W-Treasures-Payment-Orders-12-13"
          className="purple-color-chests"
          style={{ fontSize: "18px", textAlign: "center" }}
        >
          Step by step instructions
        </a>
        <Box
          className="okx-input-wrapper"
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "32px",
          }}
        >
          {isTonOrderOpened && (
            <>
              <Stack gap={1} width="100%">
                <input
                  value={inputCode ?? ""}
                  type="text"
                  className="okx-mobile-input"
                  placeholder="Input hash here"
                  onChange={(e) => onInputChange(e.target.value as string)}
                  onBlur={scrollToBottom}
                  style={{
                    color: "#fff",
                    height: "46px",
                    borderRadius: "22px",
                  }}
                />
                <p
                  className={`azure-games-validation-label ${
                    errorMessage && "azure-games-validation-label-visible"
                  }`}
                  style={{ fontSize: "16px" }}
                >
                  {errorMessage && "invalid hash"}
                </p>
              </Stack>
              <Button
                onClick={() => onCheckTransactionKeys(currentOrderItem)}
                className={`ab ${!inputCode && "btn-disabled"}`}
                style={{
                  minWidth: "110px",
                  borderRadius: "22px",
                  background: "#9747FF",
                  color: "#fff",
                  height: "46px",
                  textTransform: "none",
                }}
              >
                Check
              </Button>
            </>
          )}
        </Box>
      </Stack>
      <Stack gap={1} justifyContent="center" width="100%">
        <Stack
          flexDirection="column"
          textAlign="start"
          gap={1}
          justifyContent="start"
          width="100%"
        >
          <p
            style={{
              fontSize: "16px",
              color: "#fff",
              margin: "0 auto",
            }}
          >
            Order info
          </p>
          <Stack alignItems="start" flexDirection="column" gap={0.5}>
            <Stack alignItems="center" flexDirection="row" gap={0.5}>
              <p
                style={{
                  fontSize: "14px",
                  color: "#fff",
                }}
              >
                price:
              </p>
              <p
                style={{
                  fontSize: "14px",
                  color: "#fff",
                }}
              >
                {currentOrderItem?.product_id
                  ? getPriceAndType(
                      currentOrderItem.product_id,
                      currentOrderItem.type
                    )
                  : undefined}{" "}
              </p>
              {currentOrderItem?.type === "stars" ? (
                <img src={starsIcon} width="14px" />
              ) : (
                <img src={tonIcon} width="14px" />
              )}
            </Stack>
            <Stack
              flexDirection="row"
              justifyContent="flex-start"
              gap={0.5}
              alignItems="flex-start"
            >
              <p
                style={{
                  fontSize: "14px",
                  color: "#fff",
                  whiteSpace: "nowrap",
                }}
              >
                order id:
              </p>
              <p
                style={{
                  fontSize: "14px",
                  color: "#fff",
                  wordBreak: "break-all",
                  whiteSpace: "normal",
                }}
              >
                {currentOrderItem?.order_id}
              </p>
            </Stack>
          </Stack>
        </Stack>
        <Button
          className="ab"
          sx={{
            color: "#fff",
            width: "100%",
            height: "54px",
            backgroundColor: "red",
            borderRadius: "22px",
            fontSize: "16px",
            textTransform: "none",
          }}
          onClick={() =>
            handleClick(currentOrderItem?.order_id, currentOrderItem?.type)
          }
        >
          {cancelNotification === 3 ? "Sure?" : "Cancel Payment"}
        </Button>
      </Stack>
    </Stack>
  );
};
