import React from "react";

import { Stack } from "@mui/material";

import { TConditionsType } from "../models";

import { Actions } from "./actions";

interface IProps {
  handleConnectWallet: () => void;
  handleDisconnect: () => Promise<void>;
  address?: string;
}

export const Content: React.FC<IProps> = ({
  handleConnectWallet,
  handleDisconnect,
  address,
}) => {
  return (
    <Stack justifyContent="space-between" gap="30px">
      <Actions
        handleConnectWallet={handleConnectWallet}
        handleDisconnect={handleDisconnect}
        address={address}
      />
    </Stack>
  );
};
