import React from "react";

import { AbsoluteSpinner } from "../../../absolute-spinner";
import { TAppUser, TClaimState } from "../../../contexts/types";

import { SmallCoin } from "../../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_SMALL } from "../../../../consts";
import { PayPassMultiplierComponent } from "../../pay-pass-multiplier";
import { formatValues } from "../../../../utils";

import { useYoutubeFiApi } from "./hooks";
import { ModalContent } from "./modal";
import { ETab, ICampaign } from "./models";
import { motion } from "framer-motion";

const campaignLogo = require("./img/youtubeLogoIcon.png") as string;
const checkedImg = require("./img/checked.svg").default as string;

interface IProps {
  user: TAppUser;
  setClaimState?: React.Dispatch<React.SetStateAction<TClaimState | undefined>>;
}
export const Youtube: React.FC<IProps> = ({ user, setClaimState }) => {
  const {
    tasksToDisplay,
    tasksTonToDisplay,
    tasksSpaceShowToDisplay,
    payPassMultiplier,
    onTaskClick,
    activeTask,
    onModalClose,
    isLoading,
    onClaimTask,
    activeTab,
    setActiveTab,
  } = useYoutubeFiApi(user, setClaimState);

  const renderedTasks = React.useMemo(
    () =>
      tasksToDisplay.map((task) => (
        <div
          className="collab-list-item"
          onClick={() => onTaskClick(task)}
          key={task.type}
          style={{
            position: "relative",
          }}
        >
          <img
            className="collab-item-icon"
            src={task?.is_done ? checkedImg : task.icon}
            alt=""
            width="70px"
            height="70px"
          />
          <div className="collab-item-content">
            <div className="task-item-reward">
              <div className="task-item-info-stack">
                <div className="white-color">{task.title}</div>
                {!task?.is_done && (
                  <div className="collab-item-desc">
                    <SmallCoin
                      width={BALANCE_COIN_SIZE_SMALL}
                      height={BALANCE_COIN_SIZE_SMALL}
                    />
                    <p className="white-color">+{formatValues(task.reward)}</p>
                    <PayPassMultiplierComponent
                      payPassMultiplier={payPassMultiplier}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )),
    [tasksToDisplay, onTaskClick]
  );

  const renderedTasksSpaceShow = React.useMemo(
    () =>
      tasksSpaceShowToDisplay.map((task) => (
        <div
          className="collab-list-item"
          onClick={() => onTaskClick(task)}
          key={task.type}
          style={{
            position: "relative",
          }}
        >
          <img
            className="collab-item-icon"
            src={task?.is_done ? checkedImg : task.icon}
            alt=""
            width="70px"
            height="70px"
          />
          <div className="collab-item-content">
            <div className="task-item-reward">
              <div className="task-item-info-stack">
                <div className="white-color">{task.title}</div>
                {!task?.is_done && (
                  <div className="collab-item-desc">
                    <SmallCoin
                      width={BALANCE_COIN_SIZE_SMALL}
                      height={BALANCE_COIN_SIZE_SMALL}
                    />
                    <p className="white-color">+{formatValues(task.reward)}</p>
                    <PayPassMultiplierComponent
                      payPassMultiplier={payPassMultiplier}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )),
    [tasksSpaceShowToDisplay, onTaskClick]
  );

  const renderedTasksTon = React.useMemo(
    () =>
      tasksTonToDisplay.map((task) => (
        <div
          className="collab-list-item"
          onClick={() => onTaskClick(task)}
          key={task.type}
          style={{
            position: "relative",
          }}
        >
          <img
            className="collab-item-icon"
            src={task?.is_done ? checkedImg : task.icon}
            alt=""
            width="70px"
            height="70px"
          />
          <div className="collab-item-content">
            <div className="task-item-reward">
              <div className="task-item-info-stack">
                <div className="white-color">{task.title}</div>
                {!task?.is_done && (
                  <div className="collab-item-desc">
                    <SmallCoin
                      width={BALANCE_COIN_SIZE_SMALL}
                      height={BALANCE_COIN_SIZE_SMALL}
                    />
                    <p className="white-color">+{formatValues(task.reward)}</p>
                    <PayPassMultiplierComponent
                      payPassMultiplier={payPassMultiplier}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )),
    [tasksTonToDisplay, onTaskClick]
  );

  const currentActiveTask = () => {
    if (activeTab === ETab.SAGA) {
      return tasksToDisplay.find(
        (item) => item.type === activeTask?.type
      ) as ICampaign | null;
    } else if (activeTab === ETab.SPACE_SHOW) {
      return tasksSpaceShowToDisplay.find(
        (item) => item.type === activeTask?.type
      ) as ICampaign | null;
    } else {
      return tasksTonToDisplay.find(
        (item) => item.type === activeTask?.type
      ) as ICampaign | null;
    }
  };

  return (
    <div
      className="task-wrapper"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <AbsoluteSpinner show={isLoading} />
      <ModalContent
        activeTask={currentActiveTask()}
        onClose={onModalClose}
        payPassMultiplier={payPassMultiplier}
        onClaimTask={onClaimTask}
      />

      <div
        className="task-list-main-wrapper bebebe"
        style={{ height: "auto", backdropFilter: "blur(10px)" }}
      >
        <div
          className="boosters-toggle-wrapper"
          style={{ padding: "8px", overflowX: "auto", overflowY: "hidden" }}
        >
          <h2
            className={`green-color boosters-header-item task-toggle-label task-toggle-label-white-space ${
              activeTab === ETab.SPACE_SHOW ? "selected" : ""
            }`}
            onClick={() => setActiveTab(ETab.SPACE_SHOW)}
          >
            <b>The W-Space</b>
            {activeTab === ETab.SPACE_SHOW && (
              <motion.div
                className="underline-youtube"
                layoutId="underline-youtube"
              />
            )}
          </h2>
          <h2
            className={`green-color boosters-header-item task-toggle-label task-toggle-label-white-space ${
              activeTab === ETab.SAGA ? "selected" : ""
            }`}
            onClick={() => setActiveTab(ETab.SAGA)}
          >
            <b>W-Saga</b>
            {activeTab === ETab.SAGA && (
              <motion.div
                className="underline-youtube"
                layoutId="underline-youtube"
              />
            )}
          </h2>
          <h2
            className={`green-color boosters-header-item task-toggle-label task-toggle-label-white-space ${
              activeTab === ETab.TON_VIDEO ? "selected" : ""
            }`}
            onClick={() => setActiveTab(ETab.TON_VIDEO)}
          >
            <b>TON</b>
            {activeTab === ETab.TON_VIDEO && (
              <motion.div
                className="underline-youtube"
                layoutId="underline-youtube"
              />
            )}
          </h2>
        </div>
        {activeTab === ETab.SAGA ? (
          <div className="task-list-wrapper">{renderedTasks}</div>
        ) : null}
        {activeTab === ETab.SPACE_SHOW ? (
          <div className="task-list-wrapper">{renderedTasksSpaceShow}</div>
        ) : null}
        {activeTab === ETab.TON_VIDEO ? (
          <div className="task-list-wrapper">{renderedTasksTon}</div>
        ) : null}
      </div>
      <img
        style={{ display: "flex", margin: "0 auto" }}
        width="133px"
        src={campaignLogo}
        alt=""
      />
    </div>
  );
};
