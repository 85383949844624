import React from "react";

import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

import { UserContext } from "../contexts/user-context";

import { TConditionsType } from "./models";
import { useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";
import { useGetNftAirdropStatusQuery, useUpdateNftAirdropWalletAddressMutation } from "../feature/users";
import { Address } from "@ton/core";
import { IAirdropConditions } from "../feature/users/models";

export const usePageNftApi = () => {
    const [impactOccurred] = useHapticFeedback();

    const { user, isLoading, setClaimState } =
    React.useContext(UserContext);
    const {data, isLoading: airdropIsLoading, isSuccess} = useGetNftAirdropStatusQuery();
    const [updateNftAirdropWalletAddress, updateNftAirdropWalletAddressState] = useUpdateNftAirdropWalletAddressMutation();
    const [tonConnectUI] = useTonConnectUI();

    const parsedTasks = React.useMemo(() => {
        if (data?.data?.conditions) {
            const parsed = JSON.parse(data.data.conditions) as IAirdropConditions;

            return {
                connect_wallet: parsed?.connect_wallet || false
            }
        }

        return {
            connect_wallet: false
        }

    }, [data, isLoading, isSuccess])

    const [canUpdateAddress, setCanUpdateAddress] = React.useState<boolean>(false);

    const databaseWalletAddress = data?.data?.wallet_address;
    
    const isWalletConnected = tonConnectUI.connected;

    const canShowConnectedBtn = isSuccess && !databaseWalletAddress;

    const handleConnectWallet = () => { 
        tonConnectUI.openModal();
        setCanUpdateAddress(true);
    }

    async function handleDisconnect() {
        try {
          impactOccurred('heavy');
          await tonConnectUI.disconnect();
          handleConnectWallet();

        } catch (e) {
            handleConnectWallet();
          console.log("Error during disconnect:", e);
        }
    };

    const wallet = useTonWallet();
    const address = wallet?.account?.address;

    React.useEffect(() => {
        if (isSuccess) {
            if (address && databaseWalletAddress && isSuccess) {
                const bounceableAddress = Address.parse(address).toString({
                    bounceable: false,
                  })
    
                if (bounceableAddress !== databaseWalletAddress) {
                    handleDisconnect();
                }
    
            } else if (address && !databaseWalletAddress) {
                handleDisconnect();
            }
        }
    }, [isSuccess])
    
    React.useEffect(() => {
        if (canUpdateAddress && address) {
            const bounceableAddress = Address.parse(address).toString({
                bounceable: false,
              })

            updateNftAirdropWalletAddress({
                wallet_address: bounceableAddress,
            }).then(() => {
                impactOccurred('heavy');
            })
        }

    }, [address, canUpdateAddress]);

    const CONDITIONS_TASKS: TConditionsType[] = [
        {
            text: 'Connected TON Wallet',
            isCompleted: parsedTasks.connect_wallet,
        },
        // {
        //     text: '1 Active Mate',
        //     isCompleted: true,
        // },
        // {
        //     text: '10 Completed Tasks',
        //     isCompleted: true,
        // },
        // {
        //     text: '1 Completed Campaign',
        //     isCompleted: false,
        // },
        // {
        //     text: '5 Opened Chests',
        //     isCompleted: false,
        // },
        // {
        //     text: '5 Completed Stakes',
        //     isCompleted: false,
        // },
    ];

    const allConditionsCompleted = CONDITIONS_TASKS.every((i) => i.isCompleted);

    return {
        user,
        isLoading: isLoading || airdropIsLoading,
        isFetching: updateNftAirdropWalletAddressState.isLoading,
        setClaimState,
        CONDITIONS_TASKS,
        allConditionsCompleted,
        handleConnectWallet,
        handleDisconnect,
        address,
        databaseWalletAddress,
        isWalletConnected,
        canShowConnectedBtn,
        nftAirdropData: data,
        isEligible: data?.data?.status === "PENDING" || data?.data?.status === "SENT"
    }
}