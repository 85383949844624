import React from "react";

import "./index.scss";
import { FreeBoosters } from "./free-boosters";
import { MainBoosters } from "./main-boosters";
import { EBooster } from "./types";
import { ERune, TRune } from "../../hooks/rune/models";

interface IProps {
  onOpen: (booster: EBooster) => void;
  multiplierLevel: number;
  chargerLevel: number;
  maximizerLevel: number;
  isMultiplierUsed: boolean;
  isWorkerBoostsUsed: boolean;
  availableEnergyBoosts: number;
  availableDiceBoosts: number;
  runeType: ERune;
  onSetRuneStep?: (runeStep: TRune) => void;
  counter: {
    start: number;
    end: number;
  };
  setCounter: React.Dispatch<
    React.SetStateAction<{
      start: number;
      end: number;
    }>
  >;
  payPassMultiplier?: number;
  wSkinsToBuyCounter: number;
  wStyleToBuyCounter: number;
  isRecoveryTappingActive: boolean;
  dataBaseUserId?: number;
}

export const Boosters: React.FC<IProps> = ({
  onOpen,
  multiplierLevel,
  chargerLevel,
  maximizerLevel,
  availableEnergyBoosts,
  availableDiceBoosts,
  isMultiplierUsed,
  isWorkerBoostsUsed,
  counter,
  setCounter,
  onSetRuneStep,
  runeType,
  payPassMultiplier,
  wSkinsToBuyCounter,
  wStyleToBuyCounter,
  isRecoveryTappingActive,
  dataBaseUserId,
}) => {
  // const [showBoosterModal, setShowBoosterModal] = React.useState(false);
  // const LOCAL_STORAGE_KEY = "SHOW_BOOSTER_NOTIFICATION_MODAL";

  // React.useEffect(() => {
  //   if (!hasUserMultiplier) {
  //     try {
  //       const isShowedModal = localStorage.getItem(LOCAL_STORAGE_KEY);

  //       if (!isShowedModal) {
  //         setShowBoosterModal(true);
  //       }
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   }
  // }, []);

  // const handleCloseModal = () => {
  //   setShowBoosterModal(false);
  //   try {
  //     localStorage.setItem(LOCAL_STORAGE_KEY, "true");
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  return (
    <div className="boosters-root">
      {/* {showBoosterModal && (
        <div
          className={`overlay app-modal-root ${showBoosterModal ? "blur" : ""}`}
        ></div>
      )}
      {showBoosterModal && (
        <NotificationModal show={showBoosterModal} onClose={handleCloseModal} />
      )} */}

      <FreeBoosters
        onOpen={onOpen}
        availableEnergyBoosts={availableEnergyBoosts}
        availableDiceBoosts={availableDiceBoosts}
        payPassMultiplier={payPassMultiplier}
      />
      <MainBoosters
        onOpen={onOpen}
        multiplierLevel={multiplierLevel}
        chargerLevel={chargerLevel}
        maximizerLevel={maximizerLevel}
        isMultiplierUsed={isMultiplierUsed}
        isWorkerBoostsUsed={isWorkerBoostsUsed}
        counter={counter}
        setCounter={setCounter}
        onSetRuneStep={onSetRuneStep}
        runeType={runeType}
        wSkinsToBuyCounter={wSkinsToBuyCounter}
        wStyleToBuyCounter={wStyleToBuyCounter}
        isRecoveryTappingActive={isRecoveryTappingActive}
        dataBaseUserId={dataBaseUserId}
      />
    </div>
  );
};
