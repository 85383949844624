import React from "react";

import { motion } from "framer-motion";

import { ETab } from "../type";

import "./index.scss";

interface IProps {
  activeTab: ETab;
  onChange: (tab: ETab) => void;
  isCampaign: boolean;
}

export const ToggleGroup: React.FC<IProps> = ({
  activeTab,
  onChange,
  isCampaign,
}) => {
  const campaignFont = isCampaign ? "stroke-font" : "";

  return (
    <div
      className="boosters-toggle-wrapper"
      style={{ padding: "8px", overflowX: "auto", overflowY: "hidden" }}
    >
      <h2
        className={`green-color boosters-header-item task-toggle-label task-toggle-label-white-space ${
          activeTab === ETab.TEA_FI ? "selected" : ""
        }`}
        onClick={() => onChange(ETab.TEA_FI)}
      >
        <b className={campaignFont}>Tea-Fi</b>
        {activeTab === ETab.TEA_FI && (
          <motion.div className="underline" layoutId="underline" />
        )}
      </h2>
      {/* <h2
        className={`green-color boosters-header-item task-toggle-label task-toggle-label-white-space ${
          activeTab === ETab.MINER_TYCOON ? "selected" : ""
        }`}
        onClick={() => onChange(ETab.MINER_TYCOON)}
      >
        <b className={campaignFont}>Miner Tycoon</b>
        {activeTab === ETab.MINER_TYCOON && (
          <motion.div className="underline" layoutId="underline" />
        )}
      </h2> */}
      <h2
        className={`green-color boosters-header-item task-toggle-label task-toggle-label-white-space ${
          activeTab === ETab.YOUTUBE ? "selected" : ""
        }`}
        onClick={() => onChange(ETab.YOUTUBE)}
      >
        <b className={campaignFont}>Youtube</b>
        {activeTab === ETab.YOUTUBE && (
          <motion.div className="underline" layoutId="underline" />
        )}
      </h2>
      <h2
        className={`green-color boosters-header-item task-toggle-label task-toggle-label-white-space ${
          activeTab === ETab.ACTIVE ? "selected" : ""
        }`}
        onClick={() => onChange(ETab.ACTIVE)}
      >
        <b className={campaignFont}>Active</b>
        {activeTab === ETab.ACTIVE && (
          <motion.div className="underline" layoutId="underline" />
        )}
      </h2>
      <h2
        className={`green-color boosters-header-item task-toggle-label task-toggle-label-white-space ${
          activeTab === ETab.COMPLETED ? "selected" : ""
        }`}
        onClick={() => onChange(ETab.COMPLETED)}
      >
        <b className={campaignFont}>Completed</b>
        {activeTab === ETab.COMPLETED && (
          <motion.div className="underline" layoutId="underline" />
        )}
      </h2>
    </div>
  );
};
