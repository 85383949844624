export const Header: React.FC = () => {
  return (
    <div
      className="page-claim-header"
      style={{
        position: "relative",
      }}
    >
      <div className="page-claim-header-root">
        <p className="green-color ab" style={{ fontSize: "22px" }}>
          W-AIRDROP
        </p>
      </div>
    </div>
  );
};
