import React from "react";

import {
  useHapticFeedback,
  useWebApp,
} from "@vkruglikov/react-telegram-web-app";

import { TTelegramGroupVerify } from "../../contexts/types";
import { useOpenTelegramLink } from "../../hooks";
import {
  useUpdateGetCheckJetTonTaskExternalApiMutation,
  useUpdateGetCheckTrumpEmpireTaskExternalApiMutation,
  useVerifyTaskMutation,
} from "../../feature/users";

import { ITask } from "../type";
import { Spinner } from "../../spinner";
import axios from "axios";

interface IProps {
  currentTask: ITask;
  onClaim?: (rawResponse?: any) => void;
  onTelegramGroupVerify?: TTelegramGroupVerify;
}

export const ExternalVerifyButton: React.FC<IProps> = ({
  currentTask,
  onClaim,
}) => {
  const webApp = useWebApp();

  const { onOpenTelegramLink } = useOpenTelegramLink();

  const [checkJetTonTaskAsync, checkJetTonTaskAsyncState] =
    useUpdateGetCheckJetTonTaskExternalApiMutation();
  const [checkTrumpsImpireTaskAsync, checkTrumpsImpireTaskAsyncState] =
    useUpdateGetCheckTrumpEmpireTaskExternalApiMutation();

  const [isVerified, setIsVerified] = React.useState<boolean>(false);
  const [isTryVerified, setIsTryVerified] = React.useState<
    boolean | undefined
  >();
  const [impactOccurred] = useHapticFeedback();

  const [rawResponse, setRawResponce] = React.useState<any>();

  const [verifyTask, verifyTaskState] = useVerifyTaskMutation();
  // @ts-ignore
  const telegramUserId =
    // @ts-ignore
    window?.Telegram?.WebApp?.initDataUnsafe?.user?.id;

  const handleVerify = () => {
    if (currentTask.type === "jetTonDeposit" && telegramUserId) {
      checkJetTonTaskAsync()
        .then((res) => {
          // @ts-ignore
          if (res?.data?.status == "success") {
            if (
              // @ts-ignore
              res?.data?.rawResponse?.has_deposit_greater_than_5_usd ||
              // @ts-ignore
              res?.data?.rawResponse?.has_td_greater_than_5_usd
            ) {
              setRawResponce(
                // @ts-ignore
                res?.data?.rawResponse
              );

              setIsVerified(true);
              setIsTryVerified(undefined);
            }
          } else {
            setIsVerified(false);
            setIsTryVerified(true);
          }
        })
        .catch((e) => console.log(e, "error jetTonDeposit"));
    } else if (currentTask.type === "trumpsEmpire" && telegramUserId) {
      checkTrumpsImpireTaskAsync().then((res) => {
        // @ts-ignore
        if (res?.data?.status == "success") {
          setIsVerified(true);
          setIsTryVerified(undefined);
        } else {
          setIsVerified(false);
          setIsTryVerified(true);
        }
      });
    } else if (telegramUserId && currentTask.verifyExternalTaskName) {
      try {
        verifyTask({
          telegramUser: String(telegramUserId),
          taskName: currentTask.verifyExternalTaskName,
        })
          .unwrap()
          .then((res) => {
            if (res?.isFinished) {
              setIsVerified(true);
              setIsTryVerified(undefined);
            } else {
              setIsVerified(false);
              setIsTryVerified(true);
            }
          })
          .catch((error) => {
            console.error(error);
            setIsVerified(false);
            setIsTryVerified(true);
          });

        impactOccurred("heavy");
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleTryVerifiedContent = () => {
    if (typeof isTryVerified === "undefined") {
      return null;
    } else if (isTryVerified === true) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "absolute",
            top: "-36%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            color: "red",
          }}
        >
          <p>Task not completed.</p>
          <p>Try again</p>
        </div>
      );
    } else {
      return null;
    }
  };

  const onOpenExternalTelegramEntity = (link: string | null) => {
    webApp.openTelegramLink(link);
  };

  const handleOpenLink = React.useCallback(() => {
    return currentTask?.is_done
      ? onOpenTelegramLink()
      : onOpenExternalTelegramEntity(currentTask.link);
  }, [
    currentTask?.is_done,
    currentTask.link,
    onOpenExternalTelegramEntity,
    onOpenTelegramLink,
  ]);
  const isLoading =
    verifyTaskState.isLoading ||
    checkJetTonTaskAsyncState.isLoading ||
    checkTrumpsImpireTaskAsyncState.isLoading;

  return (
    <div
      className="task-modal-button-wrapper"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        position: "relative",
      }}
    >
      <Spinner isLoading={isLoading} />
      {handleTryVerifiedContent()}
      {!currentTask.is_done && (
        <button
          onClick={isVerified ? () => onClaim?.(rawResponse) : handleVerify}
          className={`app-modal-content-button ${
            currentTask.is_done ? "btn-disabled" : ""
          }`}
        >
          {isVerified ? "Claim" : "Verify"}
        </button>
      )}

      {!currentTask.is_done && (
        <div className="task-modal-button-wrapper">
          <button
            onClick={handleOpenLink}
            className={`app-modal-content-button`}
          >
            {currentTask?.btnLabel}
          </button>
        </div>
      )}
    </div>
  );
};
