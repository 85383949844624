import React from "react";

import { CHAIN, SendTransactionRequest, useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { beginCell, Cell, toNano } from "@ton/core";
import { TClaimState } from "../../../../contexts/types";
import { LottieAnimationContext } from "../../../../contexts/lottie-animation-context";
import { useUpdateUserExternalApi } from "../../../../../hooks";
import { validateOrder } from "../../../../../utils";

export const useConnectWalletApi = (
    onShow: (value: React.SetStateAction<boolean>) => void,
    setClaimState:
    | React.Dispatch<React.SetStateAction<TClaimState | undefined>>
    | undefined, claimState?: TClaimState, ) => {
        const { setIsVisible } = React.useContext(LottieAnimationContext);

    // Wallets connections
    const [tonConnectUI, setOptions] = useTonConnectUI();
    const wallet = useTonWallet();
    const address = wallet?.account?.address;
    const [impactOccurred] = useHapticFeedback();
    const [updateUserExternalApi, updateUserState] = useUpdateUserExternalApi();
    const [isProcessing, setIsProcessing] = React.useState(false);
    const databaseUserId = claimState?.id

    const tonPrice = process.env.REACT_APP_RECOVERY_TAPPING_TON_AMOUNT;

    // @ts-ignore
  const notAcceptedWallet = wallet?.name === "HOT";
  // @ts-ignore
  const isNetNotAccepted = React.useMemo(
    () => wallet?.account?.chain === CHAIN.TESTNET || notAcceptedWallet,
    [notAcceptedWallet, wallet?.account?.chain]
  );

  const [failedTransaction, setFailedTransaction] = React.useState(false);

  const handleDisconnect = async () => {
    try {
      impactOccurred('heavy')
      await tonConnectUI.disconnect();
    } catch (e) {
      console.log("Error during disconnect:", e);
    }
  };

  React.useEffect(() => {
    if (isNetNotAccepted) {
      handleDisconnect();
    }
  }, [handleDisconnect, isNetNotAccepted]);

    const handleSentTransaction = async () => {
      setFailedTransaction(false);

      if (isNetNotAccepted) {
        handleDisconnect();
      } else {
      if (!wallet || !wallet.account?.address) {
        console.error("Wallet not connected");
        return;
      }

      if (address && process.env.REACT_APP_RECOVERY_TAPPING_TON_AMOUNT) {
        const transaction: SendTransactionRequest = {
          validUntil: Math.floor(Date.now() / 1000) + 3600,
          messages: [
            {
              address: "UQAq4SXiWmgsve0zqMzZRmCErBC_CjZjU_P0Csptw5XlTU3O",
              amount: toNano(process.env.REACT_APP_RECOVERY_TAPPING_TON_AMOUNT).toString(),
              payload: beginCell()
              .storeUint(0, 32)
              // @ts-ignore
              .storeStringTail(`type: wai, wallet: ${wallet?.name || ''},  userId: ${databaseUserId}`)
              .endCell().toBoc().toString("base64"),
            },
          ],
        };
    
        try {
          const res = await tonConnectUI.sendTransaction(transaction);
        
          if (res?.boc) {
            const cell = Cell.fromBase64(res.boc);
            const buffer = cell.hash();
            const hashHex = buffer.toString("hex");
        
            const newData = {
              boosts: { ...(claimState?.boosts || {}), wai_boost: true },
            } as unknown as TClaimState;
                
            if (hashHex && newData && databaseUserId) {
              setIsProcessing(true);
              const checkTransaction = await validateOrder(
                hashHex,
                databaseUserId
              );

            if (checkTransaction) {
                setIsProcessing(false);
                await updateUserExternalApi({ ...newData });
                setClaimState?.((prevState) => ({ ...prevState, ...newData }));
                onShow(false);
                setIsVisible?.(true);
                setFailedTransaction(false);

                const timeoutId = setTimeout(() => {
                  setIsVisible?.(false);
                }, 4000);
              } else if (checkTransaction === false) {
                setIsProcessing(false);
                setFailedTransaction(true);
              }
            } 
          }
        } catch (e) {
          setIsProcessing(false);
          console.error(e, 'Transaction error');
        }
      }
      }
    };

    return {
      isWalletConnected: tonConnectUI.connected,
      address,
      tonConnectUI,
      setOptions,
      onDisconnect: handleDisconnect,
      handleSentTransaction,
      tonPrice,
      isLoading: updateUserState.isLoading,
      failedTransaction,
      isProcessing,
    }
}