import React from "react";

import { HashRouter, Routes, Route } from "react-router-dom";

import { Wai } from "../wai";
import { Wallet } from "../wallet";
import { PageClaim } from "../page-claim";
import { PageMates } from "../page-mates";
import { PageTasks } from "../page-tasks";
import { PageDashboard } from "../page-dashboard";
import { PageBoosters } from "../page-boosters";
import { PageSelectedBlockchain } from "../page-selected-blockchain";
import { Leaderboard } from "../leaderboard";
import { PagePayPass } from "../page-pay-pass";
import { PageChests } from "../page-chests";
import { PageNftAirdrop } from "../page-airdrop-nft";
import { useShowContentApi } from "../../hooks";
import { PageNftEmpty } from "../page-nft-empty";

export const ROUTES = {
  MAIN: {
    PATH: "/",
    COMPONENT: <PageClaim />,
  },
  MATES: {
    PATH: "/mates",
    COMPONENT: <PageMates />,
  },
  TASKS: {
    PATH: "/tasks",
    COMPONENT: <PageTasks />,
  },
  CHANGE_BLOCKCHAIN: {
    PATH: "/change-blockchain",
    COMPONENT: <PageSelectedBlockchain />,
  },
  LEADERBOARD: {
    PATH: "/Statistics",
    COMPONENT: <Leaderboard />,
  },
  WAI: {
    PATH: "/wai",
    COMPONENT: <Wai />,
  },
  WALLET: {
    PATH: "/wallet",
    COMPONENT: <Wallet />,
  },
  DASHBOARD: {
    PATH: "/dashboard",
    COMPONENT: <PageDashboard />,
  },
  BOOSTERS: {
    PATH: "/boosters",
    COMPONENT: <PageBoosters />,
  },
  PAY_PASS: {
    PATH: "/pay-pass",
    COMPONENT: <PagePayPass />,
  },
  CHESTS: {
    PATH: "/chests",
    COMPONENT: <PageChests />,
  },
  NFT_AIRDROP: {
    PATH: "/nft",
    COMPONENT: <PageNftAirdrop />,
  },
  NFT_EMPTY: {
    PATH: "/nft",
    COMPONENT: <PageNftEmpty />,
  },
  OTHER: {
    PATH: "*",
    COMPONENT: <PageClaim />,
  },
};

export const Routers: React.FC = () => {
  const { canShowContent } = useShowContentApi();

  return (
    <HashRouter>
      <Routes>
        <Route path={ROUTES.MAIN.PATH} element={ROUTES.MAIN.COMPONENT} />
        <Route path={ROUTES.MATES.PATH} element={ROUTES.MATES.COMPONENT} />
        <Route path={ROUTES.TASKS.PATH} element={ROUTES.TASKS.COMPONENT} />
        <Route path={ROUTES.CHESTS.PATH} element={ROUTES.CHESTS.COMPONENT} />
        <Route
          path={ROUTES.BOOSTERS.PATH}
          element={ROUTES.BOOSTERS.COMPONENT}
        />
        <Route
          path={ROUTES.PAY_PASS.PATH}
          element={ROUTES.PAY_PASS.COMPONENT}
        />
        <Route
          path={ROUTES.DASHBOARD.PATH}
          element={ROUTES.DASHBOARD.COMPONENT}
        />
        <Route
          path={ROUTES.CHANGE_BLOCKCHAIN.PATH}
          element={ROUTES.CHANGE_BLOCKCHAIN.COMPONENT}
        />
        <Route
          path={ROUTES.LEADERBOARD.PATH}
          element={ROUTES.LEADERBOARD.COMPONENT}
        />
        <Route path={ROUTES.WALLET.PATH} element={ROUTES.WALLET.COMPONENT} />
        <Route path={ROUTES.WAI.PATH} element={ROUTES.WAI.COMPONENT} />
        <Route
          path={ROUTES.NFT_EMPTY.PATH}
          element={
            canShowContent
              ? ROUTES.NFT_AIRDROP.COMPONENT
              : ROUTES.NFT_EMPTY.COMPONENT
          }
        />
        <Route path={ROUTES.OTHER.PATH} element={ROUTES.OTHER.COMPONENT} />
      </Routes>
    </HashRouter>
  );
};
