import { KEYS } from "../consts";
import { TChest, EChestNames, EKeyType } from "../models";

// icons
const starterIcon = require("./img/starter-icon.png") as string;
const guardianIcon= require("./img/guardian-icon.png") as string;
const treasureIcon = require("./img/treasure-icon.png") as string;
const cosmicIcon = require("./img/cosmic-icon.png") as string;

// opened icons
const starterOpenedIcon = require("./img/starter-opened-icon.png") as string;
const guardianOpenedIcon= require("./img/guardian-opened-icon.png") as string;
const treasureOpenedIcon = require("./img/treasure-opened-icon.png") as string;
const cosmicOpenedIcon = require("./img/cosmic-opened-icon.png") as string;

const CHEST_KEYS_AMOUNT: Record<EChestNames, number> = {
    [EChestNames.STARTER]: 1,
    [EChestNames.GUARDIAN]: 5,
    [EChestNames.TREASURE]: 10,
    [EChestNames.WCOSMIC]: 20,
};

const CHEST_LABELS = {
    [EChestNames.STARTER]: 'Starter',
    [EChestNames.GUARDIAN]: 'Guardian',
    [EChestNames.TREASURE]: 'Treasure',
    [EChestNames.WCOSMIC]: 'W-Cosmic',
}

export const CHESTS_DATA: Record<EChestNames, TChest> = {
    [EChestNames.STARTER]: {
        name: CHEST_LABELS[EChestNames.STARTER],
        amount: CHEST_KEYS_AMOUNT[EChestNames.STARTER],
        icon: starterIcon,
        isBestDeal: false,
        type: EChestNames.STARTER,
        iconOpened: starterOpenedIcon,
    },
    [EChestNames.GUARDIAN]: {
        name: CHEST_LABELS[EChestNames.GUARDIAN],
        amount: CHEST_KEYS_AMOUNT[EChestNames.GUARDIAN],
        icon: guardianIcon,
        isBestDeal: false,
        type: EChestNames.GUARDIAN,
        iconOpened: guardianOpenedIcon,
    },
    [EChestNames.TREASURE]: {
        name: CHEST_LABELS[EChestNames.TREASURE],
        amount: CHEST_KEYS_AMOUNT[EChestNames.TREASURE],
        icon: treasureIcon,
        isBestDeal: false,
        type: EChestNames.TREASURE,
        iconOpened: treasureOpenedIcon,

    },
    [EChestNames.WCOSMIC]: {
        name: CHEST_LABELS[EChestNames.WCOSMIC],
        amount: CHEST_KEYS_AMOUNT[EChestNames.WCOSMIC],
        icon: cosmicIcon,
        isBestDeal: true,
        type: EChestNames.WCOSMIC,
        iconOpened: cosmicOpenedIcon,

    },
}

export const CHESTS = [EChestNames.STARTER, EChestNames.GUARDIAN, EChestNames.TREASURE, EChestNames.WCOSMIC].map((chestName) => CHESTS_DATA[chestName]);

export const KEYS_DATA = [EKeyType.Bronze, EKeyType.Silver, EKeyType.Gold, EKeyType.Platinum, EKeyType.Diamond, EKeyType.Brilliant].map(
    (keyName) => KEYS[keyName]
);