import React from "react";

import "./index.scss";

import { CSSTransition } from "react-transition-group";
import { useWebApp } from "@vkruglikov/react-telegram-web-app";
import { TELEGRAM_LINK } from "../../consts";
import { MainLoader } from "./main-loader";
import { MaintenanceLoader } from "./maintenance-loader";
import { Spinner } from "../spinner";
import WebApp from "@twa-dev/sdk";

// images
const mainBg = require("./img/main-bg.png") as string;
const maintenanceBg = require("./img/maintenance-bg.png") as string;

interface IProps extends React.PropsWithChildren {
  isLoading: boolean;
  isFetching?: boolean;
  bg?: string;
  customBg?: string;
  isMainPage?: boolean;
  hasLoadedOnce?: boolean;
  setHasLoadedOnce?: React.Dispatch<React.SetStateAction<boolean>>;
  isTasks?: boolean;
  getCustomBg?: (bg: string) => string;
}

export const Template: React.FC<IProps> = ({
  isLoading,
  isFetching,
  children,
  bg,
  customBg,
  isMainPage,
  hasLoadedOnce,
  setHasLoadedOnce,
  isTasks,
  getCustomBg,
}) => {
  const webApp = useWebApp();

  const handleOpenTelegram = () => {
    webApp.openTelegramLink(TELEGRAM_LINK);
  };

  const handleOpenX = () => {
    webApp.openLink("https://x.com/wcoin_io?s=21");
  };

  const [isLoadingState, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (isMainPage && !hasLoadedOnce) {
      setIsLoading(true);
      const timer = setTimeout(() => {
        setIsLoading(false);
        setHasLoadedOnce?.(true);
      }, 3000);

      return () => clearInterval(timer);
    }
  }, [isMainPage, hasLoadedOnce, setHasLoadedOnce]);

  const showChildren = () => {
    if (isMainPage) {
      return isLoadingState ? null : children;
    }

    return children;
  };

  const isMaintenance = process.env.REACT_APP_MAINTENANCE_MODE === "true";

  const loaderBg = isMaintenance ? maintenanceBg : bg;

  const getBg = () => {
    if (isTasks) {
      return isLoadingState || isLoading ? getCustomBg?.(mainBg) : loaderBg;
    }

    return isLoadingState || isLoading
      ? loaderBg
      : customBg || getCustomBg?.(mainBg);
  };

  return (
    <div
      className="template-body"
      style={{
        backgroundImage: `url(${getBg()})`,
        backgroundSize: "cover",
        backgroundColor: "#000",
      }}
    >
      <CSSTransition
        in={isLoadingState || (isMainPage && isLoading)}
        timeout={200}
        classNames="fade1"
        unmountOnExit
      >
        <div
          className="overlay"
          style={{
            backgroundImage: `url(${getBg()})`,
            backgroundSize: "cover",
            position: "unset",
          }}
        >
          {isMaintenance ? (
            <MaintenanceLoader
              onOpenTelegram={handleOpenTelegram}
              onOpenX={handleOpenX}
              bg={getBg()}
            />
          ) : (
            <MainLoader
              onOpenTelegram={handleOpenTelegram}
              onOpenX={handleOpenX}
              bg={getBg()}
            />
          )}
        </div>
      </CSSTransition>
      <Spinner isLoading={!!isFetching} />
      {showChildren()}
    </div>
  );
};
