import React from "react";

import { Stack } from "@mui/material";

import { usePageClaimApi, useTeamDonateApi } from "./hooks";

import "./index.scss";
import { Template } from "../template";
import { Counter } from "../counter";
import { getClaimIcon } from "../../utils";
import { BottomAnimation } from "../page-animation";
import { BurningNotificationModal } from "../burning/burning-notification-modal";
import { BurningModal } from "../burning/burning-modal";

import { ClaimButton } from "./claim-button";
import { ClaimModal } from "./claim-modal";
import { Header } from "./header";
import { PagesLinks } from "./pages-links";
import { Footer } from "./footer";
import { CountdownModal } from "./countdown-modal";
import { KeysModal } from "./keys-modal";
import { RecoveryTapping } from "./recovery-tapping";
import { TeamDonates } from "./team-donates";

// TODO: ADD AFTER RUNE
// const bg = require("../template/main-bg-choose.png");
// const runeBg = require("./img/rune-loader.png");
// const walletBg = require("../template/walletBg.png") as string;
const loaderBg = require("./img/chests-bg.svg").default as string;
const recoveryTappingIcon =
  require("./recovery-tapping/img/hand-icon.png") as string;

export const PageClaim: React.FC = () => {
  const {
    isLoading,
    isFetching,
    isSuccess,
    energy,
    maxEnergy,
    onClickChange,
    counter,
    disableBtn,
    addToBalance,
    workerModalStatus,
    onWorkerClaimBalance,
    onCloseModal,
    earnByMinute,
    claimState,
    activeTaskCount,
    setHasLoadedOnce,
    hasLoadedOnce,
    payPassMultiplier,
    showAnimation,
    isCheckActivityIsSuccess,
    isCheckActivityIsError,
    isCheckActivityIsLoading,
    checkActivityData,
    setCanGetAuthAfterCheckActivity,
    showCountdownModal,
    setShowCountdownModal,
    setClaimState,
    canGetUser,
    isRecoveryTappingActive,
    getCustomBg,
  } = usePageClaimApi();

  const canShowWorkerModal = !!(
    workerModalStatus?.readyToShowWorkerModal &&
    !workerModalStatus?.alreadyOpenedModal &&
    earnByMinute
  );

  const handleCountdownModalChange = (value: boolean) => {
    setShowCountdownModal(value);
  };

  const BURNING_NOTIFICATION_KEY = "BURNING_NOTIFICATION_KEY";

  const [
    showFirstBurningNotificationModal,
    setShowFirstBurningNotificationModal,
  ] = React.useState(false);

  const [showBurningModal, setShowBurningModal] = React.useState(false);

  const handleShowedBurningNotification = () => {
    try {
      localStorage.setItem(BURNING_NOTIFICATION_KEY, "true");

      if (checkActivityData?.status === "burned") {
        setShowBurningModal(true);
      } else {
        setCanGetAuthAfterCheckActivity?.(true);
      }

      setShowFirstBurningNotificationModal(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCanAuth = () => {
    setCanGetAuthAfterCheckActivity?.(true);
    setShowBurningModal(false);
  };

  React.useEffect(() => {
    if (isCheckActivityIsError) {
      setCanGetAuthAfterCheckActivity?.(true);
    }

    if (!isCheckActivityIsLoading) {
      if (isCheckActivityIsError) {
        setCanGetAuthAfterCheckActivity?.(true);
      } else {
        try {
          const isBurningNotificationShowed = Boolean(
            localStorage.getItem(BURNING_NOTIFICATION_KEY)
          );
          if (isCheckActivityIsSuccess) {
            if (isBurningNotificationShowed) {
              checkActivityData?.status === "burned"
                ? setShowBurningModal(true)
                : setCanGetAuthAfterCheckActivity?.(true);
            } else {
              setShowFirstBurningNotificationModal(true);
            }
          }
        } catch (e) {
          setCanGetAuthAfterCheckActivity?.(true);
        }
      }
    }
  }, [
    isCheckActivityIsSuccess,
    isSuccess,
    checkActivityData,
    isCheckActivityIsError,
    setCanGetAuthAfterCheckActivity,
    isCheckActivityIsLoading,
  ]);

  const [showKeysGift, setShowKeysGift] = React.useState(false);

  React.useEffect(() => {
    if (isSuccess && !canShowWorkerModal && canGetUser) {
      try {
        const giftKeysShowed = localStorage.getItem("TON_JECKPOT_20_NEW");
        if (!giftKeysShowed) {
          setShowKeysGift(true);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [isSuccess, canShowWorkerModal, canGetUser]);

  const closeShowKeysGift = () => {
    try {
      localStorage.setItem("TON_JECKPOT_20_NEW", "true");

      setShowKeysGift(false);
    } catch (e) {
      console.log(e);
    }
  };
  const [showTonWeekModal, setShowTonWeekModal] = React.useState(false);
  const [showRecoveryTappingModal, setShowRecoveryTappingModal] =
    React.useState(false);

  const {
    updatePayDonateInvoiceState,
    updateUserState,
    handleUpdateDonate,
    currentPrice,
    setCurrentPrice,
    isTonDonateLoading,
    isWalletConnected,
    address,
    tonConnectUI,
    setOptions,
    onDisconnect,
    failedTransaction,
    isProcessing,
    handleSentTransaction,
  } = useTeamDonateApi(claimState, setClaimState);

  const showOverlay =
    canShowWorkerModal ||
    (showCountdownModal && !isRecoveryTappingActive) ||
    showTonWeekModal ||
    (showRecoveryTappingModal && !isRecoveryTappingActive) ||
    updateUserState.isLoading;

  const canShowActionIconsButton = canGetUser && !isLoading;
  const isSpinnerSkin =
    claimState?.skins?.shuriken?.selected || claimState?.skins?.vinyl?.selected;

  return (
    <Template
      isLoading={isLoading}
      isMainPage={true}
      isFetching={isFetching}
      setHasLoadedOnce={setHasLoadedOnce}
      hasLoadedOnce={hasLoadedOnce}
      bg={loaderBg}
      getCustomBg={getCustomBg}
    >
      {showOverlay && (
        <div
          className={`overlay app-modal-root ${showOverlay ? "blur" : ""}`}
          style={{ zIndex: "12" }}
        ></div>
      )}
      {showFirstBurningNotificationModal && (
        <BurningNotificationModal
          onClick={handleShowedBurningNotification}
          showFirstBurningNotificationModal={showFirstBurningNotificationModal}
        />
      )}
      {showBurningModal && (
        <BurningModal
          onClick={handleCanAuth}
          checkActivityData={checkActivityData}
          showBurningModal={showBurningModal}
        />
      )}
      <ClaimModal
        canShowWorkerModal={canShowWorkerModal}
        value={earnByMinute}
        onClose={onCloseModal}
        onClaim={onWorkerClaimBalance}
        disabled={isLoading}
      />
      {!isRecoveryTappingActive ? (
        <CountdownModal
          show={showCountdownModal}
          onClose={() => handleCountdownModalChange(false)}
        />
      ) : null}
      {isSuccess && (
        <div className="page-claim">
          <Header />
          <div className="page-claim-content-wrapper">
            <div className="content main-wrapper page-claim-main-wrapper">
              <Counter counter={counter} additionalComponent={<PagesLinks />} />
              <div className="box-claim-wrapper">
                <Stack
                  gap={1}
                  style={{
                    position: "absolute",
                    right: "20px",
                    top: "36.5%",
                  }}
                  justifyContent="center"
                >
                  {!isRecoveryTappingActive ? (
                    <img
                      onClick={() => setShowRecoveryTappingModal(true)}
                      src={recoveryTappingIcon}
                      alt=""
                      width="64.1px"
                      height="64.1px"
                      style={{
                        zIndex: 10,
                      }}
                    />
                  ) : null}
                </Stack>
                <ClaimButton
                  showAnimation={showAnimation}
                  onClick={onClickChange}
                  energy={energy}
                  maxEnergy={maxEnergy}
                  clickMultiplier={addToBalance}
                  disableBtn={disableBtn}
                  claimIcon={getClaimIcon(claimState?.skins)}
                  isSpinnerSkin={isSpinnerSkin}
                  payPassMultiplier={payPassMultiplier}
                />
                <BottomAnimation>
                  <Footer
                    activeTaskCount={activeTaskCount}
                    claimState={claimState}
                  />
                </BottomAnimation>
              </div>
            </div>
          </div>
        </div>
      )}
      <RecoveryTapping
        showModal={showRecoveryTappingModal}
        onShow={setShowRecoveryTappingModal}
        claimState={claimState}
        setClaimState={setClaimState}
      />
      {canShowActionIconsButton ? (
        <TeamDonates
          onUpdateDonate={handleUpdateDonate}
          currentPrice={currentPrice}
          setCurrentPrice={setCurrentPrice}
          isWalletConnected={isWalletConnected}
          address={address}
          tonConnectUI={tonConnectUI}
          setOptions={setOptions}
          onDisconnect={onDisconnect}
          failedTransaction={failedTransaction}
          isProcessing={isProcessing}
          handleSentTransaction={handleSentTransaction}
          isLoading={
            isProcessing ||
            updatePayDonateInvoiceState.isLoading ||
            isTonDonateLoading
          }
        />
      ) : null}
    </Template>
  );
};
