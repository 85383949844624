export const Header: React.FC = () => {
  return (
    <div
      className="page-claim-header"
      style={{
        position: "relative",
      }}
    >
      <div className="page-claim-header-root">
        <p className="green-color ab" style={{ fontSize: "22px" }}>
          W-AIRDROP
        </p>
        <div className="icons page-claim-icons">
          {/* <a href={`#${ROUTES.DASHBOARD.PATH}`}>
            <img width="22px" height="22px" src={dashboardIcon} alt="" />
          </a>
          <a href={`#${ROUTES.LEADERBOARD.PATH}`}>
            <img width="22px" height="22px" src={statsIcon} alt="" />
          </a> */}
        </div>
      </div>
    </div>
  );
};
