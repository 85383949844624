import React from "react";

import { formatValues } from "../../../../../../utils";
import { UserContext } from "../../../../../contexts/user-context";
import { useUpdateUserExternalApi } from "../../../../../../hooks";
import WebApp from "@twa-dev/sdk";

import { EStyles, TStylesItems } from "./models";
import { Oval } from "react-loader-spinner";
import { useUpdateStyleInvoiceMutation } from "../../../../../feature/users";
import { WStylesModal } from "./styles-modal";
import { ZoomInAnimation } from "../../../../../page-animation";
import { Stack } from "@mui/material";
import { LottieAnimationContext } from "../../../../../contexts/lottie-animation-context";

// images
const starsIcon = require("./img/stars.png") as string;

const natureIcon = require("./img/Nature.png") as string;
const oceanIcon = require("./img/Ocean.png") as string;
const goldIcon = require("./img/gold.png") as string;
const spaceIcon = require("./img/space.png") as string;
const defaultIcon = require("./img/default-bg.png") as string;
const tonIcon = require("./img/tonIcon.png") as string;

export const WStyles: React.FC = ({}) => {
  const [updateUserExternalApi, updateUserState] = useUpdateUserExternalApi();
  const { setIsVisible } = React.useContext(LottieAnimationContext);

  const [updateBoostsStateInvoice, updateBootsStyleInvoiceState] =
    useUpdateStyleInvoiceMutation();

  const { user, setClaimState, isLoading } = React.useContext(UserContext);
  const claimState = user?.claim_state;
  const claimStateStyles = user?.claim_state?.boosts?.styles;

  const [stylesToBuy, setStylesToBuy] = React.useState<EStyles | null>(null);

  const handleChangeStyleToBuy = (style: TStylesItems) => {
    setStylesToBuy(style.type);
  };

  const handleModalClose = () => {
    setStylesToBuy(null);
  };

  const handleSelectStyle = (style: TStylesItems) => {
    if (style.selected) {
      return;
    }

    let newClaimState = {
      boosts: {
        ...(claimState?.boosts || {}),
        styles: claimState?.boosts?.styles ?? {},
      },
    };

    Object.keys(newClaimState?.boosts?.styles)?.forEach((key) => {
      if (
        newClaimState &&
        newClaimState?.boosts?.styles &&
        newClaimState?.boosts?.styles?.[key]
      ) {
        newClaimState.boosts.styles = {
          ...newClaimState.boosts.styles,
          [key]: {
            ...newClaimState.boosts.styles[key],
            selected: false,
          },
        };
      }
    });

    if (newClaimState?.boosts?.styles && style.type) {
      newClaimState.boosts.styles = {
        ...newClaimState.boosts.styles,
        [style.type]: {
          purchased: true,
          selected: true,
          type: style.type,
        },
      };
    }

    updateUserExternalApi({ ...newClaimState })
      .then(() => {
        // @ts-ignore
        setClaimState?.((prevState) => ({
          ...(prevState || {}),
          ...newClaimState,
        }));
      })
      .catch((e: any) => {
        console.warn(e);
      });
  };

  const anyStylesSelected = () => {
    const selected =
      !!claimStateStyles?.[EStyles.NATURE]?.selected ||
      !!claimStateStyles?.[EStyles.OCEAN]?.selected ||
      !!claimStateStyles?.[EStyles.GOLD]?.selected ||
      !!claimStateStyles?.[EStyles.SPACE]?.selected;

    if (selected === false) {
      return true;
    } else {
      return false;
    }
  };

  const W_STYLES = [
    {
      title: EStyles.NATURE,
      selected: claimStateStyles?.[EStyles.NATURE]?.selected || false,
      purchased: claimStateStyles?.[EStyles.NATURE]?.purchased || false,
      icon: natureIcon,
      type: EStyles.NATURE,
      starsPrice: 49,
      tonPrice: 0.2,
    },
    {
      title: EStyles.OCEAN,
      selected: claimStateStyles?.[EStyles.OCEAN]?.selected || false,
      purchased: claimStateStyles?.[EStyles.OCEAN]?.purchased || false,
      icon: oceanIcon,
      type: EStyles.OCEAN,
      starsPrice: 49,
      tonPrice: 0.2,
    },
    {
      title: EStyles.GOLD,
      selected: claimStateStyles?.[EStyles.GOLD]?.selected || false,
      purchased: claimStateStyles?.[EStyles.GOLD]?.purchased || false,
      icon: goldIcon,
      type: EStyles.GOLD,
      starsPrice: 99,
      tonPrice: 0.4,
    },
    {
      title: EStyles.SPACE,
      selected: claimStateStyles?.[EStyles.SPACE]?.selected || false,
      purchased: claimStateStyles?.[EStyles.SPACE]?.purchased || false,
      icon: spaceIcon,
      type: EStyles.SPACE,
      starsPrice: 99,
      tonPrice: 0.4,
    },
    {
      title: EStyles.DEFAULT,
      selected:
        claimStateStyles?.[EStyles.DEFAULT]?.selected || anyStylesSelected(),
      purchased: claimStateStyles?.[EStyles.DEFAULT]?.purchased || true,
      icon: defaultIcon,
      type: EStyles.DEFAULT,
      starsPrice: 0,
      tonPrice: 0,
    },
  ];

  const currentStyles = (W_STYLES.find((item) => {
    return item!.type === stylesToBuy;
  }) || null) as TStylesItems | null;

  const handleGetGenerate2Invoice = async (update: () => void) => {
    if (currentStyles?.starsPrice) {
      try {
        const result = await updateBoostsStateInvoice({
          amount: currentStyles.starsPrice,
        }).unwrap();

        if (result?.result) {
          WebApp.openInvoice(result?.result, (status) => {
            if (status === "paid") {
              update();
              setIsVisible?.(true);

              const timeoutId = setTimeout(() => {
                setIsVisible?.(false);
              }, 4000);
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handBuyStyles = (paymentType: "STARS" | "TON" | "DEFAULT") => {
    if (!currentStyles) {
      return;
    }
    if (claimState?.id) {
      let newBoosts = {
        boosts: {
          ...(claimState?.boosts || {}),
          styles: {
            ...(claimState?.boosts?.styles || {}),
          },
        },
      };

      newBoosts.boosts.styles = claimState?.boosts?.styles ?? {};

      if (currentStyles) {
        newBoosts.boosts.styles = {
          ...newBoosts.boosts.styles,
          [currentStyles.type]: {
            purchased: true,
            selected: false,
            type: currentStyles.type,
          },
        };
      }

      const handleBuy = () => {
        updateUserExternalApi({ ...newBoosts })
          .then(() => {
            // @ts-ignore
            setClaimState?.((prevState) => ({
              ...(prevState || {}),
              ...newBoosts,
            }));
          })
          .catch((e: any) => {
            console.warn(e);
          });
      };

      if (paymentType === "DEFAULT") {
        handleBuy();
      } else if (paymentType === "STARS") {
        handleGetGenerate2Invoice(handleBuy);
      } else {
        handleBuy();
      }
    }
  };

  const status = (item: TStylesItems) => {
    if (item.selected) {
      return "Selected";
    } else if (item.price === 0 || item.purchased) {
      return "You own it";
    }

    return formatValues(item.price);
  };

  const isLoadingApp =
    updateUserState?.isLoading || updateBootsStyleInvoiceState.isLoading;

  return (
    <>
      {isLoadingApp && (
        <div className="app-spinner" style={{ top: 0, left: 0 }}>
          <Oval
            visible={isLoadingApp}
            height="100"
            width="100"
            color="#9747FF"
            secondaryColor="rgb(225, 219, 239)"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}
      <WStylesModal
        open={!!currentStyles}
        onClose={handleModalClose}
        onUpdate={handBuyStyles}
        stylesToBuy={currentStyles}
        isPurchased={currentStyles?.purchased}
        isLoading={isLoading}
        claimState={claimState}
      />
      {W_STYLES.map((item, index) => {
        return (
          <div
            key={index}
            className="main-boosters-root"
            onClick={
              item!.purchased
                ? // @ts-ignore
                  () => handleSelectStyle(item)
                : // @ts-ignore
                  () => handleChangeStyleToBuy(item)
            }
          >
            <div className="main-boosters-left">
              <div className="main-boosters-avatar">
                <ZoomInAnimation>
                  <img width="75px" height="75px" src={item!.icon} alt="" />
                </ZoomInAnimation>
              </div>
              <div className="main-boosters-description-wrapper ">
                <p
                  className="green-color"
                  style={{
                    marginBottom: "8px",
                  }}
                >
                  {item!.title}
                </p>
                {/* <p className="green-color">{item!.subTitle}</p> */}
                <div
                  className="main-boosters-description"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Stack gap={1}>
                    {!item?.purchased && (
                      <p
                        className="main-boosters-price common-color"
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <img width="14px" src={tonIcon} alt="" />
                        <p> {item.tonPrice}</p>
                      </p>
                    )}
                    {!item?.purchased && (
                      <p
                        className="main-boosters-price common-color"
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <img
                          src={starsIcon}
                          alt=""
                          width="15px"
                          height="15px"
                        />
                        <p> {item.starsPrice}</p>
                      </p>
                    )}
                  </Stack>
                  <p className="main-boosters-price common-color">
                    {/** @ts-ignore */}
                    {status(item)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
