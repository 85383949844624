export const getDateByTimestamp = (timestamp: number) => {
  const date = new Date(timestamp);

  return date.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
  });
};

export const getTimeByTimestamp = (timestamp: number) => {
  const date = new Date(timestamp);

  return date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
};
