import React from "react";

import { Box, Button, Stack, Typography } from "@mui/material";

import { IPendingOrder, PendingOrder } from "../../../../feature/users/models";
import { getDateByTimestamp, getTimeByTimestamp } from "../utils";
import { KEYS } from "../../../consts";
import { EKeyType, EModalType } from "../../../models";

import "./index.scss";

const keysIcon = require("./img/keys.png") as string;
const tonIcon = require("./img/tonIcon.png") as string;
const starsIcon = require("./img/starsIcon.png") as string;

interface IProps {
  pendingOrders?: PendingOrder;
  onModalTypeClick: (
    value: EModalType | null,
    item?: IPendingOrder & {
      type: "ton" | "stars";
    }
  ) => void;
}

export const PendingContent: React.FC<IProps> = ({
  pendingOrders,
  onModalTypeClick,
}) => {
  const combinedOrders = [
    ...(pendingOrders?.ton
      ? pendingOrders?.ton.map((order) => ({ ...order, type: "ton" }))
      : []),
    ...(pendingOrders?.telegram_stars
      ? pendingOrders.telegram_stars.map((order) => ({
          ...order,
          type: "stars",
        }))
      : []),
  ].sort((a, b) => b.created_at - a.created_at) as (IPendingOrder & {
    type: "ton" | "stars";
  })[];

  const getPriceAndType = (productType: EKeyType, type: "ton" | "stars") => {
    return Object.values(KEYS).find((item) => item.type === productType)?.[
      type
    ];
  };

  const getAmount = (productType: EKeyType) => {
    return Object.values(KEYS).find((item) => item.type === productType)
      ?.amount;
  };

  return (
    <Stack flex={1} height="100%" gap="20px" width="100%" overflow="auto">
      {combinedOrders?.length ? (
        <>
          {combinedOrders.map((item) => {
            return (
              <Stack
                flexDirection="row"
                gap={2}
                width="100%"
                justifyContent="space-between"
              >
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  gap={2}
                >
                  <Stack width="54px" justifyContent="center">
                    <Typography lineHeight="1" color="#B6B6B6" fontSize="12px">
                      {getDateByTimestamp(item.created_at)}
                    </Typography>
                    <Typography lineHeight="1" color="#B6B6B6" fontSize="12px">
                      {getTimeByTimestamp(item.created_at)}
                    </Typography>
                  </Stack>
                  <Box
                    color="#B6B6B6"
                    fontSize="14px"
                    width="135px"
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    gap="8px"
                  >
                    <img width="15.43px" height="18" src={keysIcon} alt="" />
                    <Typography sx={{ color: "#E477FF" }}>
                      {getAmount(item.product_id)}
                    </Typography>
                    <Typography>for</Typography>
                    <Stack flexDirection="row" gap="4px" alignItems="center">
                      {item.type === "ton" ? (
                        <img src={tonIcon} width="17px" alt="" />
                      ) : (
                        <img
                          src={starsIcon}
                          width="17.55px"
                          height="18px"
                          alt=""
                        />
                      )}
                      <Typography
                        className={
                          item.type === "ton"
                            ? "tonPriceColor"
                            : "starsPriceColor"
                        }
                      >
                        {getPriceAndType(item.product_id, item.type)}
                      </Typography>
                    </Stack>
                  </Box>
                </Stack>
                <Stack flexDirection="row" gap={1} alignItems="center">
                  <Button
                    onClick={() =>
                      onModalTypeClick(EModalType.CHECK_STANSACTION, item)
                    }
                    className="ab"
                    style={{
                      backgroundColor: "#fff",
                      color: "#9747FF",
                      padding: "5px 10px",
                      borderRadius: "16px",
                      textTransform: "capitalize",
                    }}
                  >
                    Restore
                  </Button>
                </Stack>
              </Stack>
            );
          })}
        </>
      ) : (
        <Typography
          color="rgb(175, 180, 168)"
          fontSize="16px"
          textAlign="center"
        >
          No orders
        </Typography>
      )}
    </Stack>
  );
};
