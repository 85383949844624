import React from "react";

import { Stack } from "@mui/material";
import { BackButton } from "@vkruglikov/react-telegram-web-app";

import { TemplatePages } from "../template-pages";

import { usePageNftApi } from "./hooks";
import { Header } from "./header";
import { Heading } from "./heading";
import { Content } from "./content";
import { IconContent } from "./icon-content";
import { ROUTES } from "../routers";

const bgIcon = require("./img/bg.jpg") as string;

export const PageNftEmpty: React.FC = () => {
  const { isLoading, handleConnectWallet, handleDisconnect, address } =
    usePageNftApi();

  return (
    <TemplatePages isLoading={isLoading} isFetching={false} bg={bgIcon}>
      <BackButton onClick={() => (window.location.hash = ROUTES.MAIN.PATH)} />

      <Header />
      <Stack
        flexDirection="column"
        textAlign="center"
        height="calc(100% - 60px)"
        width="100%"
        justifyContent="space-between"
        overflow="auto"
        style={{
          padding: "0 24px 24px",
        }}
      >
        <Heading />
        <IconContent />
        <Content
          handleConnectWallet={handleConnectWallet}
          handleDisconnect={handleDisconnect}
          address={address}
        />
      </Stack>
    </TemplatePages>
  );
};
