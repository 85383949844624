export enum ECampaign {
    PLAY_GAME = "play_game",
}
  
  export interface ICampaign {
      icon: string;
      link: string;
      type: ECampaign;
      title: string;
      btnLabel: string;
      reward: number;
      is_done?: boolean;
  }
  