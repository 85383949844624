import React from "react";

import { motion } from "framer-motion";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

import { EBooster } from "../types";
import { ERune, TRune } from "../../../hooks/rune/models";

import "./index.scss";
import { BoostersItems } from "./boosters-items";
import { WSkins } from "./boosters-items/w-skins";
import { WStyles } from "./boosters-items/w-styles";

interface IProps {
  onOpen: (booster: EBooster) => void;
  multiplierLevel: number;
  chargerLevel: number;
  maximizerLevel: number;
  isMultiplierUsed: boolean;
  isWorkerBoostsUsed: boolean;
  onSetRuneStep?: (runeStep: TRune) => void;
  runeType: ERune;
  counter: {
    start: number;
    end: number;
  };
  setCounter: React.Dispatch<
    React.SetStateAction<{
      start: number;
      end: number;
    }>
  >;
  wSkinsToBuyCounter: number;
  isRecoveryTappingActive: boolean;
  wStyleToBuyCounter: number;
  dataBaseUserId?: number;
}

enum EToggleBoosters {
  BOOSTERS = "BOOSTERS",
  W_SKINS = "W_SKINS",
  W_STYLES = "W_STYLES",
}

export const MainBoosters: React.FC<IProps> = ({
  onOpen,
  multiplierLevel,
  chargerLevel,
  maximizerLevel,
  isMultiplierUsed,
  isWorkerBoostsUsed,
  counter,
  setCounter,
  onSetRuneStep,
  runeType,
  wSkinsToBuyCounter,
  wStyleToBuyCounter,
  isRecoveryTappingActive,
  dataBaseUserId,
}) => {
  const [impactOccurred] = useHapticFeedback();
  const [toggle, setToggle] = React.useState<EToggleBoosters>(
    EToggleBoosters.BOOSTERS
  );

  const handleTabChange = (tab: EToggleBoosters) => {
    impactOccurred("heavy");
    setToggle(tab);
  };

  const content = () => {
    if (toggle === EToggleBoosters.BOOSTERS) {
      return (
        <BoostersItems
          onOpen={onOpen}
          multiplierLevel={multiplierLevel}
          chargerLevel={chargerLevel}
          maximizerLevel={maximizerLevel}
          isMultiplierUsed={isMultiplierUsed}
          isWorkerBoostsUsed={isWorkerBoostsUsed}
          isRecoveryTappingActive={isRecoveryTappingActive}
        />
      );
    } else if (toggle === EToggleBoosters.W_SKINS) {
      return (
        <WSkins
          counter={counter}
          setCounter={setCounter}
          onSetRuneStep={onSetRuneStep}
          runeType={runeType}
          dataBaseUserId={dataBaseUserId}
        />
      );
    } else {
      return <WStyles />;
    }
  };

  return (
    <div className="main-boosters-wrapper">
      <div
        className="boosters-toggle-wrapper"
        style={{
          gap: "16px",
        }}
      >
        <h2
          className={`green-color boosters-header-item ${
            toggle === EToggleBoosters.BOOSTERS ? "selected" : ""
          }`}
          onClick={() => handleTabChange(EToggleBoosters.BOOSTERS)}
        >
          <b>Boosters</b>
          {toggle === EToggleBoosters.BOOSTERS && (
            <motion.div className="underline" layoutId="underline" />
          )}
        </h2>
        <h2
          className={`green-color boosters-header-item ${
            toggle === EToggleBoosters.W_SKINS ? "selected" : ""
          }`}
          onClick={() => handleTabChange(EToggleBoosters.W_SKINS)}
        >
          <b>W-Skins</b>
          {wSkinsToBuyCounter ? (
            <div
              className="active-task"
              style={{ right: "-10px", top: "0px", left: "unset" }}
            >
              {wSkinsToBuyCounter}
            </div>
          ) : null}
          {toggle === EToggleBoosters.W_SKINS && (
            <motion.div className="underline" layoutId="underline" />
          )}
        </h2>
        <h2
          className={`green-color boosters-header-item ${
            toggle === EToggleBoosters.W_STYLES ? "selected" : ""
          }`}
          onClick={() => handleTabChange(EToggleBoosters.W_STYLES)}
        >
          <b>W-Styles</b>
          {wStyleToBuyCounter ? (
            <div
              className="active-task"
              style={{ right: "-10px", top: "0px", left: "unset" }}
            >
              {wStyleToBuyCounter}
            </div>
          ) : null}
          {toggle === EToggleBoosters.W_STYLES && (
            <motion.div className="underline" layoutId="underline" />
          )}
        </h2>
      </div>
      <div className="main-boosters-boosters-list bebebe">{content()}</div>
    </div>
  );
};
