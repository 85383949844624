import React from "react";

import {
  useTonWallet,
  useTonConnectUI,
} from "@tonconnect/ui-react";
import {
  useHapticFeedback,
} from "@vkruglikov/react-telegram-web-app";

import { UserContext } from "../contexts/user-context";
import { ERune } from "../hooks/rune/models";
import { useUpdateUserExternalApi } from "../../hooks";
import { TClaimState } from "../contexts/types";

import "./index.scss";

export const useWalletApi = () => {
    const {
        user,
        isLoading,
        onSetRuneStep,
        setClaimState,
        task: { WALLET_TASK },
        getCustomBg
      } = React.useContext(UserContext);
      const [impactOccurred] = useHapticFeedback();
    
      const wallet = useTonWallet();
      const [updateUserExternalApi, updateUserState] = useUpdateUserExternalApi();
      const [openModal, setOpenModal] = React.useState<boolean>(false);
    
      const [tonConnectUI] = useTonConnectUI();
      const payPassMultiplier = user?.claim_state?.payPassMultiplier;
    
      React.useEffect(() => {
        const address = wallet?.account.address;
        const claimState = user?.claim_state;
    
        if (
          tonConnectUI.connected &&
          address &&
          claimState?.tasks &&
          // @ts-ignore
          !claimState.tasks?.[WALLET_TASK.type]?.address &&
          payPassMultiplier
        ) {
          if (claimState) {
            let newData = {
              tasks: {
                ...claimState.tasks,
                [WALLET_TASK.type]: { address },
              },
              balance: claimState.tasks?.[WALLET_TASK.type]
                ? user.claim_state?.balance || 0
                : (user.claim_state?.balance || 0) + (WALLET_TASK.reward * payPassMultiplier),
            } as TClaimState;
    
            updateUserExternalApi({ ...newData })
              .then(() => {
                if (!claimState.tasks?.[WALLET_TASK.type]) {
                  setOpenModal(true);
                }
                impactOccurred("heavy");
                setClaimState?.((prevState) => ({ ...prevState, ...newData }));
              })
              .catch((e: any) => {
                console.warn(e);
              });
          }
        }
      }, [
        WALLET_TASK.reward,
        WALLET_TASK.type,
        tonConnectUI.connected,
        tonConnectUI.disconnect,
      ]);
    
      // @ts-ignore
      const runeStep = user?.claim_state?.tasks?.rune?.step === ERune.START;
    
      const handleDisconnect = async () => {
        try {
          await tonConnectUI.disconnect();
        } catch (e) {
          console.log(e);
        }
      };
    
      const [isWalletLoading, setIsWalletLoading] = React.useState(false);
    
      React.useEffect(() => {
        if (!tonConnectUI.connected) {
            setIsWalletLoading(true);
            // @ts-ignore
            const callback = (mutationsList, observer) => {
              for (let mutation of mutationsList) {
                if (mutation.type === "childList") {
                  const button = document.querySelector("[data-tc-connect-button]");
                  // @ts-ignore
                  if (button && button.textContent.includes("Connect Wallet")) {
                    console.log("Connect Wallet button is now visible!");
                    setIsWalletLoading(false);
                  }
                }
              }
            };
        
            const observer = new MutationObserver(callback);
        
            const targetNode = document.querySelector(".wallet-actions-wrapper");
            if (targetNode) {
              observer.observe(targetNode, { childList: true, subtree: true });
            }
        
            return () => {
              observer.disconnect();
            };
        }
      }, []);

      return {
        isLoading,
        updateUserState,
        openModal,
        setOpenModal,
        runeStep,
        onSetRuneStep,
        user,
        wallet,
        tonConnectUI,
        handleDisconnect,
        isWalletLoading,
        WALLET_TASK,
        payPassMultiplier,
        getCustomBg,
      }
}