import React from "react";

import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

import { SmallCoin } from "../../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_MEDIUM } from "../../../../consts";
import { formatValues } from "../../../../utils";
import { Stack, Typography } from "@mui/material";
import { TonConnectUI } from "@tonconnect/ui-react";
import { truncateString } from "../../../wallet/utils";
import { Address } from "@ton/core";

const tonIcon = require("./img/tonIcon.png") as string;
const starsIcon = require("./img/stars-icon.png") as string;
const editIcon = require("./img/edit-icon.png") as string;

interface IProps {
  icon: string;
  title: string;
  price: number | string;
  tonPrice?: number;
  onClick: (payment?: "TON" | "STARS") => void;
  buttonLabel: string;
  disabled?: boolean;
  onClose: (event: React.SyntheticEvent) => void;
  isPurchased?: boolean;
  isStars?: boolean;
  subTitle?: string;
  isWalletConnected: boolean;
  address?: string;
  tonConnectUI: TonConnectUI;
  onDisconnect: () => Promise<void>;
  failedTransaction: boolean;
}

export const WSkinsModalContent: React.FC<IProps> = ({
  icon,
  title,
  price,
  tonPrice,
  onClick,
  buttonLabel,
  disabled,
  onClose,
  isPurchased,
  isStars,
  subTitle,
  address,
  tonConnectUI,
  onDisconnect,
  failedTransaction,
}) => {
  const [impactOccurred] = useHapticFeedback();

  return (
    <div className="app-modal-content-wrapper">
      <div className="close-modal ab" onClick={onClose}>
        x
      </div>
      <>
        <div className="app-modal-content">
          <div className="app-modal-content-icon">
            {<img width={200} src={icon} alt="" />}
          </div>
          <Stack gap={1} alignItems="center">
            <div className="app-modal-content-label ab">{title}</div>
            {subTitle ? (
              <div
                className="app-modal-content-label"
                style={{ fontSize: "16px" }}
              >
                {subTitle}
              </div>
            ) : null}
          </Stack>

          <>
            <div
              className="app-modal-content-bonus-wrapper"
              style={{
                height: "30px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {!isPurchased && (
                <>
                  {isStars && tonPrice ? (
                    <Stack
                      flexDirection="column"
                      gap="8px"
                      justifyContent="center"
                    >
                      <Stack
                        flexDirection="row"
                        gap="4px"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <img
                          width="20px"
                          height="20px"
                          src={tonIcon}
                          alt=""
                          style={{ marginBottom: "4px" }}
                        />
                        <p className="app-modal-content-bonus ab">
                          {formatValues(tonPrice)}
                        </p>
                      </Stack>
                      <Stack
                        flexDirection="row"
                        gap="4px"
                        justifyContent="center"
                      >
                        <img
                          src={starsIcon}
                          alt=""
                          style={{ marginBottom: "4px" }}
                        />
                        <p className="app-modal-content-bonus ab">
                          {formatValues(price)}
                        </p>
                      </Stack>
                    </Stack>
                  ) : (
                    <>
                      <SmallCoin
                        width={BALANCE_COIN_SIZE_MEDIUM}
                        height={BALANCE_COIN_SIZE_MEDIUM}
                      />
                      <p className="app-modal-content-bonus ab">
                        {formatValues(price)}
                      </p>
                    </>
                  )}{" "}
                </>
              )}
            </div>
          </>
        </div>
        {isStars && tonPrice ? (
          <Stack flexDirection="column" gap="8px" width="100%">
            {address ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "4px",
                  height: "22px",
                  justifyContent: "center",
                }}
              >
                <p className="white-color paPassAddress">
                  Connected address:{" "}
                  {truncateString(
                    Address.parse(address).toString({
                      bounceable: false,
                    })
                  )}
                </p>
                <img src={editIcon} alt="" onClick={onDisconnect} />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "4px",
                  height: "22px",
                }}
              ></div>
            )}
            {failedTransaction ? (
              <Typography
                style={{
                  color: "red",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Not enough TON funds
              </Typography>
            ) : null}
            <Stack gap="16px" width="100%" flexDirection="row" height="53.55px">
              <button
                className={`app-modal-content-button ${
                  disabled || isPurchased ? "btn-disabled" : ""
                }`}
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "8px",
                  display: "flex",
                }}
                onClick={(e) => {
                  if (tonConnectUI.connected) {
                    e.stopPropagation();
                    onClick("TON");
                    impactOccurred("heavy");
                  } else {
                    tonConnectUI.openModal();
                  }
                }}
              >
                <img height="20px" width="20px" src={tonIcon} alt="" />
                <b>
                  {isPurchased
                    ? "Purchased"
                    : disabled
                    ? "Insufficient funds"
                    : tonPrice}
                </b>
              </button>
              <button
                className={`app-modal-content-button ${
                  disabled || isPurchased ? "btn-disabled" : ""
                }`}
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "8px",
                  display: "flex",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  onClick("STARS");
                  impactOccurred("heavy");
                }}
              >
                <img src={starsIcon} alt="" />
                <b>
                  {isPurchased
                    ? "Purchased"
                    : disabled
                    ? "Insufficient funds"
                    : price}
                </b>
              </button>
            </Stack>
          </Stack>
        ) : (
          <button
            className={`app-modal-content-button ${
              disabled || isPurchased ? "btn-disabled" : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
              onClick();
              impactOccurred("heavy");
            }}
          >
            <b>
              {isPurchased
                ? "Purchased"
                : disabled
                ? "Insufficient funds"
                : buttonLabel}
            </b>
          </button>
        )}
      </>
    </div>
  );
};
