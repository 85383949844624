import React from "react";

import { Stack } from "@mui/material";

import { EModalType } from "../../models";
import { Spinner } from "../../../spinner";

import { Keys } from "./keys";
import { useKeyShopContentApi } from "./hooks";
import { IPendingOrder } from "../../../feature/users/models";

interface IProps {
  onModalTypeClick: (
    value: EModalType | null,
    item?: IPendingOrder & {
      type: "ton" | "stars";
    }
  ) => void;
}

export const KeyShopContent: React.FC<IProps> = ({ onModalTypeClick }) => {
  const {
    currentKey,
    address,
    tonConnectUI,
    onDisconnect,
    onCurrentKey,
    onBuyKeys,
    isLoading,
    onBuyKeyBuyStars,
    currentPurchaseType,
  } = useKeyShopContentApi(onModalTypeClick);

  return (
    <Stack
      className="app-modal-content task-modal-content"
      style={{ justifyContent: "space-between", gap: "16px" }}
      overflow="auto"
    >
      <Spinner
        isLoading={isLoading}
        label="Verifying transaction…"
        marginTop="-26px"
      />
      <Keys
        currentKey={currentKey}
        address={address}
        tonConnectUI={tonConnectUI}
        onDisconnect={onDisconnect}
        onCurrentKey={onCurrentKey}
        onBuyKeys={onBuyKeys}
        onBuyKeyBuyStars={onBuyKeyBuyStars}
        currentPurchaseType={currentPurchaseType}
      />
    </Stack>
  );
};
