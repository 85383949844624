import React from "react";
import { ICampaign } from "../../models";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

interface IProps {
  activeTask: ICampaign | null;
  onClaimTask: (task: ICampaign | null) => void;
}

export const InputCode: React.FC<IProps> = ({ activeTask, onClaimTask }) => {
  const [impactOccurred] = useHapticFeedback();

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [inputCode, setInputCode] = React.useState<string | undefined>(
    undefined
  );

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as string;

    if (Boolean(value)) {
      setInputCode(value as string);
    } else {
      setInputCode(undefined);
    }

    if (errorMessage) {
      setErrorMessage(null);
    }
  };

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  const submitCode = () => {
    if (activeTask?.code !== inputCode) {
      setErrorMessage("Invalid code");
    } else {
      impactOccurred("heavy");
      onClaimTask(activeTask);
    }
  };

  return (
    <div>
      <div className="okx-input-root">
        <div className="okx-input-wrapper">
          <input
            value={inputCode}
            type="text"
            className="okx-mobile-input"
            placeholder="Input code here"
            onChange={(e) => onInputChange(e)}
            onBlur={scrollToBottom}
          />
        </div>
        <button onClick={submitCode} className={`okx-redeem-button`}>
          <p className="ab okx-redeem-text">Check</p>
        </button>
      </div>
      <p
        className={`okx-validation-label ${
          errorMessage && "okx-validation-label-visible"
        }`}
      >
        Invalid code
      </p>
    </div>
  );
};
