import React from "react";

import "antd/dist/reset.css";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";
import { isMobile } from "react-device-detect";
import { ToastContainer } from "react-toastify";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { ThirdwebProvider, trustWallet } from "@thirdweb-dev/react";
import { WebAppProvider, useExpand } from "@vkruglikov/react-telegram-web-app";
import WebApp from "@twa-dev/sdk";

import "./index.css";
import { store } from "./store";
import { getAppKeyCoin } from "./utils";
import { Routers } from "./components/routers";
import { LaptopScreen } from "./components/lapton-screen";
import { TimestampError } from "./components/timestamp-error";
import { UserProvider } from "./components/contexts/user-context";
import { AnimationProvider } from "./components/contexts/animation-context";
import { LottieAnimationProvider } from "./components/contexts/lottie-animation-context";
import { useShowContentApi } from "./hooks";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const App = () => {
  const [isExpanded, expand] = useExpand();
  expand();

  // const { canShowContent } = useShowContentApi();
  // React.useEffect(() => {
  //   if (canShowContent) {
  //     if (!sessionStorage.getItem("fullscreenTriggered")) {
  //       WebApp.isVerticalSwipesEnabled = false;
  //       WebApp.requestFullscreen();
  //       WebApp.lockOrientation();
  //       sessionStorage.setItem("fullscreenTriggered", "true");
  //     }
  //   }
  // }, []);

  const [isTimestampError, setIsTimestampError] =
    React.useState<boolean>(false);

  const [now, setNow] = React.useState(Date.now());
  let previousTime = now;

  React.useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Date.now();

      if (
        currentTime > previousTime + 10 * 60 * 1000 ||
        currentTime < previousTime - 10 * 60 * 1000
      ) {
        setIsTimestampError?.(true);
      }

      setNow(currentTime);
      previousTime = currentTime;
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const isAppKeyCoin = getAppKeyCoin();

  if (isTimestampError) {
    return <TimestampError />;
  }

  if (!isMobile && !isAppKeyCoin) {
    return <LaptopScreen />;
  }

  return (
    <LottieAnimationProvider>
      <WebAppProvider>
        <TonConnectUIProvider
          manifestUrl={process.env.REACT_APP_WALLET_MANIFEST}
        >
          <ThirdwebProvider
            supportedWallets={[trustWallet({ recommended: true })]}
          >
            <Provider store={store}>
              <AnimationProvider>
                <UserProvider>
                  <Routers />
                  <ToastContainer />
                </UserProvider>
              </AnimationProvider>
            </Provider>
          </ThirdwebProvider>
        </TonConnectUIProvider>
      </WebAppProvider>
    </LottieAnimationProvider>
  );
};

root.render(<App />);
