import React from "react";

import { Links } from "../links";
import { ROUTES } from "../../routers";
import { TClaimState } from "../../contexts/types";

const waiIcon = require("../img/wai.png") as string;
const rocketIcon = require("../img/rocket.png") as string;
const tasksIcon = require("../img/tasks.png") as string;
const matesIcon = require("../img/mates.png") as string;
const snowBottomIcon = require("./img/snow-bottom.svg").default as string;
const airdropIcon = require("./img/airdrop.png") as string;

interface IProps {
  activeTaskCount: number;
  claimState?: TClaimState;
}

export const Footer: React.FC<IProps> = ({ activeTaskCount, claimState }) => {
  const wSkinsToBuyCounter = [
    !claimState?.skins?.ice,
    !claimState?.skins?.christmas,
    !claimState?.skins?.vinyl,
    !claimState?.skins?.polar,
    !claimState?.skins?.runic,
    !claimState?.skins?.diamond_core,
    !claimState?.boosts?.styles?.Nature,
    !claimState?.boosts?.styles?.Ocean,
    !claimState?.boosts?.styles?.Gold,
    !claimState?.boosts?.styles?.Space,
  ].filter(Boolean)?.length;

  return (
    <div className="bottom-nav" style={{ position: "relative" }}>
      <img
        style={{
          position: "absolute",
          top: "-30px",
          left: "14px",
          zIndex: "11",
          width: "105px",
        }}
        src={snowBottomIcon}
        alt=""
      />
      <Links
        path={`#${ROUTES.MATES.PATH}`}
        icon={matesIcon}
        label="Mates"
        width={30}
        height={30}
      />
      <Links
        path={`#${ROUTES.TASKS.PATH}`}
        icon={tasksIcon}
        label="Tasks"
        activeTaskCount={activeTaskCount}
        width={30}
        height={30}
      />
      <Links
        path={`#${ROUTES.NFT_AIRDROP.PATH}`}
        icon={airdropIcon}
        label=""
        width={37}
        height={37}
      />
      <Links
        path={`#${ROUTES.WAI.PATH}`}
        icon={waiIcon}
        label="W-AI"
        width={30}
        height={30}
        updatePoint={claimState?.boosts?.wai_boost ? undefined : 1}
      />
      <Links
        path={`#${ROUTES.BOOSTERS.PATH}`}
        icon={rocketIcon}
        label="Boost"
        updatePoint={wSkinsToBuyCounter || undefined}
        width={30}
        height={30}
      />
    </div>
  );
};
