import React from "react";

import { Stack } from "@mui/material";
import { BackButton } from "@vkruglikov/react-telegram-web-app";

import { TemplatePages } from "../template-pages";

import { usePageNftApi } from "./hooks";
import { Header } from "./header";
import { Heading } from "./heading";
import { Content } from "./content";
import { ROUTES } from "../routers";

const bgIcon = require("./img/bg.jpg") as string;

export const PageNftAirdrop: React.FC = () => {
  const {
    isLoading,
    CONDITIONS_TASKS,
    handleConnectWallet,
    handleDisconnect,
    address,
    databaseWalletAddress,
    canShowConnectedBtn,
    nftAirdropData,
    isFetching,
    isEligible,
  } = usePageNftApi();

  return (
    <TemplatePages isLoading={isLoading} isFetching={isFetching} bg={bgIcon}>
      <BackButton onClick={() => (window.location.hash = ROUTES.MAIN.PATH)} />
      <Header />
      <Stack
        flexDirection="column"
        textAlign="center"
        height="calc(100% - 60px)"
        width="100%"
        justifyContent="flex-start"
        overflow="auto"
        style={{
          padding: "0 24px 24px",
        }}
      >
        <Heading isEligible={isEligible} />
        <Content
          isEligible={isEligible}
          list={CONDITIONS_TASKS}
          handleConnectWallet={handleConnectWallet}
          handleDisconnect={handleDisconnect}
          address={address}
          databaseWalletAddress={databaseWalletAddress}
          canShowConnectedBtn={canShowConnectedBtn}
          nftAirdropData={nftAirdropData}
        />
      </Stack>
    </TemplatePages>
  );
};
