import React from "react";

import { CSSTransition } from "react-transition-group";

import "../index.scss";
import { VerifyButton } from "../../page-tasks/verify-button";
import { VerifyReceived } from "../../page-tasks/verify-received";
import { TTelegramGroupVerify } from "../../contexts/types";
import { ExternalVerifyReceived } from "../../page-tasks/external-verify-received";
import { CommunityButton } from "./community-button";
import { ExternalVerifyButton } from "../../page-tasks/external-verify-button";
import { ETask, ITask } from "../../page-tasks/type";

import { Price } from "./price";
import { Stack } from "@mui/material";

const key = require("./img/key.png") as string;

interface IProps {
  currentTask: ITask | null;
  onClose: () => void;
  onClaim?: (rawResponse?: any) => void;
  isJoinedToTelegram?: (type?: ETask | string) => boolean | undefined;
  onTelegramGroupVerify?: TTelegramGroupVerify;
  payPassMultiplier?: number;
}

export const TaskModal: React.FC<IProps> = ({
  currentTask,
  onClose,
  onClaim,
  isJoinedToTelegram,
  onTelegramGroupVerify,
  payPassMultiplier,
}) => {
  const getIcon = () => {
    if (currentTask?.stringIcon) {
      return <div style={{ fontSize: 108 }}>{currentTask.stringIcon}</div>;
    } else if (currentTask?.icon) {
      return <img src={currentTask?.icon} alt="" />;
    }

    return currentTask?.componentIcon;
  };

  const onVerify = () => {
    if (currentTask) {
      return currentTask.type === ETask.TELEGRAM
        ? isJoinedToTelegram?.()
        : isJoinedToTelegram?.(currentTask.type);
    } else {
      return false;
    }
  };

  const getButtonContent = React.useMemo(() => {
    if (currentTask?.verifyExternalLink && !currentTask.is_done) {
      return (
        <Stack display="flex" flexDirection="column" gap={1}>
          {currentTask?.isKeyPrice ? (
            <Stack
              justifyContent="center"
              alignItems="center"
              flexDirection="row"
              gap={1}
            >
              <img width="20px" src={key} alt="" />
              <p style={{ color: "#fff", fontSize: "28px" }} className="ab">
                +{currentTask?.isKeyPrice}
              </p>
            </Stack>
          ) : null}
          <ExternalVerifyReceived
            currentTask={currentTask}
            isClaimed={false}
            payPassMultiplier={payPassMultiplier}
            modalLabel={currentTask.modalLabel}
            modalSubLabel={currentTask.modalSubLabel}
          />
        </Stack>
      );
    } else if (currentTask?.verifyLink) {
      return (
        <VerifyReceived
          currentTask={currentTask}
          isJoinedToTelegram={onVerify()}
          payPassMultiplier={payPassMultiplier}
        />
      );
    }

    return (
      <>
        <Stack display="flex" flexDirection="column" gap={1}>
          {currentTask?.isKeyPrice ? (
            <Stack
              justifyContent="center"
              alignItems="center"
              flexDirection="row"
              gap={1}
            >
              <img width="20px" src={key} alt="" />
              <p style={{ color: "#fff", fontSize: "28px" }} className="ab">
                +{currentTask?.isKeyPrice}
              </p>
            </Stack>
          ) : null}
          <Price
            modalLabel={currentTask?.modalLabel}
            reward={currentTask?.reward}
            payPassMultiplier={payPassMultiplier}
            isDone={!!currentTask?.is_done}
          />
        </Stack>
      </>
    );
  }, [currentTask, payPassMultiplier, onVerify]);

  const getVerifyButtonContent = React.useMemo(() => {
    if (currentTask?.verifyExternalLink && !currentTask.is_done) {
      return (
        <ExternalVerifyButton
          onTelegramGroupVerify={onTelegramGroupVerify}
          currentTask={currentTask}
          onClaim={onClaim}
        />
      );
    } else if (currentTask?.refLink || currentTask?.verifyLink) {
      return (
        <VerifyButton
          onTelegramGroupVerify={onTelegramGroupVerify}
          currentTask={currentTask}
          onClaim={onClaim}
          isJoinedToTelegram={onVerify()}
        />
      );
    } else {
      return <CommunityButton />;
    }
  }, [currentTask, onClaim, onTelegramGroupVerify, onVerify]);

  return (
    <>
      {!!currentTask && (
        <div
          className={`overlay app-modal-root ${!!currentTask ? "blur" : ""}`}
        ></div>
      )}
      <CSSTransition
        in={!!currentTask}
        timeout={2500}
        classNames="fade"
        unmountOnExit
      >
        <div className="overlay app-modal-root">
          <div
            className={`app-modal-content-wrapper task-modal-wrapper`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="close-modal ab" onClick={onClose}>
              x
            </div>
            <div className="app-modal-content task-modal-content">
              <div className="task-modal-desc">
                <div className="task-modal-icon">{getIcon()}</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    textAlign: "center",
                  }}
                >
                  {getButtonContent}
                </div>
              </div>
              <div className="task-modal-button-wrapper">
                {getVerifyButtonContent}
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};
