import React from "react";

import { MULTIPLIER_UPGRADES, UPGRADES, WORKER_UPGRADES } from "../../consts";
import { EBooster } from "../../types";
import { TUpgradeBoosterData } from "../types";
import { MainBoostersItem } from "../main-boosters-item";
import { isMaxLevel } from "../../../utils";

const tapIcon = require("./img/tap.png") as string;
const chargerIcon = require("./img/charger.png") as string;

interface IProps {
  onOpen: (booster: EBooster) => void;
  multiplierLevel: number;
  chargerLevel: number;
  maximizerLevel: number;
  isMultiplierUsed: boolean;
  isWorkerBoostsUsed: boolean;
  isRecoveryTappingActive: boolean;
}

export const BoostersItems: React.FC<IProps> = ({
  onOpen,
  multiplierLevel,
  chargerLevel,
  maximizerLevel,
  isMultiplierUsed,
  isWorkerBoostsUsed,
  isRecoveryTappingActive,
}) => {
  const { TAP_UPGRADES, CHARGER_UPGRADES, MAXIMIZER_UPGRADES } = UPGRADES;

  const UPGRADE_BOOSTERS_DATA: TUpgradeBoosterData[] = [
    {
      imageIcon: tapIcon,
      title: "Tap",
      price: isMaxLevel(multiplierLevel, Object.values(TAP_UPGRADES).length)
        ? 0
        : TAP_UPGRADES[multiplierLevel]?.price || 0,
      level: multiplierLevel,
      type: EBooster.TAP,
      isMaxLevel: isMaxLevel(
        multiplierLevel,
        Object.values(TAP_UPGRADES).length
      ),
    },
    {
      icon: "⛽",
      title: "Maximizer",
      price: isMaxLevel(
        maximizerLevel,
        Object.values(MAXIMIZER_UPGRADES).length
      )
        ? 0
        : MAXIMIZER_UPGRADES[maximizerLevel].price,
      level: maximizerLevel,
      type: EBooster.MAXIMIZER,
      isMaxLevel: isMaxLevel(
        maximizerLevel,
        Object.values(MAXIMIZER_UPGRADES).length
      ),
    },
    {
      imageIcon: chargerIcon,
      title: "Charger",
      price: isMaxLevel(chargerLevel, Object.values(CHARGER_UPGRADES).length)
        ? 0
        : CHARGER_UPGRADES[chargerLevel].price,
      level: chargerLevel,
      type: EBooster.CHARGER,
      isMaxLevel: isMaxLevel(
        chargerLevel,
        Object.values(CHARGER_UPGRADES).length
      ),
    },
  ];

  const MULTIPLIER_BOOSTERS_DATA = {
    icon: "🎰",
    title: "Multiplier",
    price: MULTIPLIER_UPGRADES.price,
    type: EBooster.MULTIPLIER,
    disabled: isMultiplierUsed,
  };

  const WORKER_BOOSTERS_DATA = {
    icon: "🤖",
    title: "W-orker",
    price: WORKER_UPGRADES.price,
    type: EBooster.WORKER,
    disabled: isWorkerBoostsUsed,
  };

  return (
    <>
      {UPGRADE_BOOSTERS_DATA?.map((i) => (
        <MainBoostersItem
          isRecoveryTappingActive={isRecoveryTappingActive}
          key={i.title}
          icon={i.icon}
          imageIcon={i.imageIcon}
          title={i.title}
          price={i.price}
          level={i.level}
          onClick={() => {
            if (isRecoveryTappingActive) {
              onOpen(i.type);
            }
          }}
          isMaxLevel={i.isMaxLevel}
          type={i.type}
        />
      ))}
      <MainBoostersItem
        isRecoveryTappingActive={isRecoveryTappingActive}
        icon={WORKER_BOOSTERS_DATA.icon}
        title={WORKER_BOOSTERS_DATA.title}
        price={WORKER_BOOSTERS_DATA.price}
        onClick={() => {
          if (isRecoveryTappingActive) {
            onOpen(WORKER_BOOSTERS_DATA.type);
          }
        }}
        isMaxLevel={WORKER_BOOSTERS_DATA.disabled}
        type={WORKER_BOOSTERS_DATA.type}
      />
      <MainBoostersItem
        isRecoveryTappingActive={isRecoveryTappingActive}
        icon={MULTIPLIER_BOOSTERS_DATA.icon}
        title={MULTIPLIER_BOOSTERS_DATA.title}
        price={MULTIPLIER_BOOSTERS_DATA.price}
        onClick={() => {
          if (isRecoveryTappingActive) {
            onOpen(MULTIPLIER_BOOSTERS_DATA.type);
          }
        }}
        isMaxLevel={MULTIPLIER_BOOSTERS_DATA.disabled}
        type={MULTIPLIER_BOOSTERS_DATA.type}
      />
    </>
  );
};
