import React from "react";

import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import WebApp from "@twa-dev/sdk";

import { EModalType, TKey } from "../../models";
import { useConnectWalletApi } from "../../hooks";
import { useUpdateKeyOrderMutation, useUpdateKeyOpenOrderMutation, useUpdateKeyStarsOpenOrderMutation, useCheckUpdateKeyOrderMutation } from "../../../feature/users";
import { useInputCheckCode } from "./check-transaction/utils";
import { showToast } from "../../../../utils";

import { ECurrentPurchaseType } from "./models";
import { IPendingOrder } from "../../../feature/users/models";

export const useKeyShopContentApi = (  onModalTypeClick: (
  modalType: EModalType | null,
  item?: IPendingOrder & {
    type: "ton" | "stars";
  }
) => void
) => {
  const [impactOccurred] = useHapticFeedback();

  const { inputCode, onInputChange, errorMessage, setErrorMessage } = useInputCheckCode();

  const { address, tonConnectUI, onDisconnect, handleSentTransaction } =
    useConnectWalletApi();

  const [openKeyOrder,  openKeyOrderState] = useUpdateKeyOpenOrderMutation();
  const [openKeyStarsOrder,  openKeyOrderStarsState] = useUpdateKeyStarsOpenOrderMutation();

  const [updateKeyOrder, openCloseState] = useUpdateKeyOrderMutation();
  const [checkUpdateKeyOrder, checkUpdateKeyOrderState] = useCheckUpdateKeyOrderMutation();
  const isLoading = openKeyOrderState.isLoading || openCloseState.isLoading || openKeyOrderStarsState.isLoading || checkUpdateKeyOrderState.isLoading;

  const [currentKey, setCurrentKey] = React.useState<TKey | null>(null);
  const [currentPurchaseType, setCurrentPurchaseType] = React.useState<ECurrentPurchaseType | null>(null)

  const handleResetCurrentKey = () => {
    impactOccurred('heavy');
    setCurrentKey(null);
  }

  const handleCurrentKey = (value: TKey) => {
    impactOccurred('heavy');
    setCurrentKey(value);
  };

  const handleCloseCheckTransaction = () => {
    impactOccurred('heavy');
    onModalTypeClick(EModalType.HISTORY);
  };

  const onSuccessKeys = (orderId: string, transaction_id: string) => {
    if (currentKey && orderId && transaction_id) {
      updateKeyOrder({
        order_id: orderId,
        status:'success',
        transaction_id: transaction_id,
        type: 'ton',
      }).unwrap().then((res) => {
        handleResetCurrentKey();
        showToast('Keys purchased');
        impactOccurred("heavy");
        onModalTypeClick(null);
        setCurrentPurchaseType(null);
      })
    }
  };

  const onCheckTransactionKeys = (item: IPendingOrder & {
    type: "ton" | "stars";
}) => {
    impactOccurred('heavy');
    const id =  inputCode || item?.transaction_id; 
    const orderId =  item?.order_id;

    if (id &&  orderId) {
      return checkUpdateKeyOrder({
        status: 'success',
        order_id: orderId,
        transaction_id: id,
        type: 'ton',
      }).unwrap().then(() => {
        handleCloseCheckTransaction();
        showToast('Keys purchased');
        impactOccurred("heavy");
        onModalTypeClick(null);
      }).catch(() => {
        setErrorMessage(true);
      })
    }
  };

  const onBuyKeys = () => {
    impactOccurred('heavy');
    if (currentKey) {   
      openKeyOrder({product_id: currentKey.type, amount: currentKey.amount}).unwrap().then((res) => {
          const orderId = res?.data?.order?.id;
          setCurrentPurchaseType(ECurrentPurchaseType.TON);

          if (orderId) {
            handleSentTransaction(currentKey, orderId, onSuccessKeys)
          }
        }).catch((e) => console.log(e));
    }
  };

  const handleCloseOrder = (orderId: string, type: "ton" | "stars") => {
    impactOccurred('heavy');
      if (orderId) {
        if (type === 'ton') {
          updateKeyOrder({
            order_id: orderId,
            status:'cancel',
            type: 'ton',
          }).then(() => {
            impactOccurred("heavy");
            handleCloseCheckTransaction();
          })
        } else {
          updateKeyOrder({
            order_id: orderId,
            status:'cancel',
            type: 'tg-stars',
          }).then(() => {
            impactOccurred("heavy");
            handleCloseCheckTransaction();
          })
        }
    }
  }

  const handleBuyKeyBuyStars = () => {
    impactOccurred('heavy');
    setCurrentPurchaseType(ECurrentPurchaseType.STARS);

    if (currentKey?.type) {
      openKeyStarsOrder({
        product_id: currentKey.type,
      }).unwrap().then((response) => {
        const starsOrderId = response?.data?.order?.id
        const starsLink = response?.data?.order?.meta?.invoice?.result as string;

        if (starsOrderId && starsLink) {
          const starsLink = response?.data?.order?.meta?.invoice?.result as string;

            try {
              WebApp.openInvoice(starsLink, (status) => {
                if (status === "paid") {
                  updateKeyOrder({
                    order_id: starsOrderId,
                    status:'success',
                    transaction_id: 'stars_transactions',
                    type: 'tg-stars',
                  }).unwrap().then((res) => {
                    handleResetCurrentKey();
                    showToast('Stars purchased');
                    impactOccurred("heavy");
                    setCurrentPurchaseType(null);
                  })
                }
              });
            } catch (error) {
              console.log(error);
            }
        }
      }).catch((e) => {
          console.log(e, 'error opened stars transaction')
      })
    }
  }
    return {
        currentKey,
        address,
        tonConnectUI,
        onDisconnect,
        onCurrentKey: handleCurrentKey,
        onCloseCheckTransaction: handleCloseCheckTransaction,
        onBuyKeys,
        onCloseOrder: handleCloseOrder,
        onCheckTransactionKeys,
        isLoading,
        inputCode,
        onInputChange,
        errorMessage,
        onBuyKeyBuyStars: handleBuyKeyBuyStars,
        currentPurchaseType,
    }
}