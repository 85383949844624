import React from "react";

import {
  useHapticFeedback,
  useWebApp,
} from "@vkruglikov/react-telegram-web-app";
import { skipToken } from "@reduxjs/toolkit/query";
import { useLocation } from "react-router-dom";

import { UserContext } from "../contexts/user-context";
import {
  useGetMatesQuery,
  useLazyGetMatesGetRewardsQuery,
} from "../feature/users";
import { useUpdateUserExternalApi } from "../../hooks";
import { TTask } from "../feature/users/models";
import { TClaimState } from "../contexts/types";

import { FIRST_LEVEL, INVITE_MATE_URL, INVITE_THREE_MATES_REWARD, LAST_LEVEL, LEVELS } from "./consts";

export const usePageMatesApi = () => {
  const webApp = useWebApp();
  const [impactOccurred] = useHapticFeedback();
  const [openModal, setOpenModal] = React.useState<number | null>(null);

  const {
    user,
    isLoading: isContextLoading,
    setClaimState,
    isSuccess,
    getCustomBg
  } = React.useContext(UserContext);

  const payPassMultiplier = user?.claim_state?.payPassMultiplier;

  const canGetMates = isSuccess ? undefined : skipToken;

  // Queries
  const { data: mates, isLoading: isMatesLoading, refetch } = useGetMatesQuery(
    canGetMates,
    {
      selectFromResult: (result) => {
        return {
          ...result,
          newMates: result?.currentData,
        };
      },
    }
  );
  const handleRefetchUsers = () => {
    impactOccurred('heavy');
    refetch();
    // @ts-ignore
    setClaimState?.((prevState) => ({
      ...(prevState || {}),
      newMates: mates,
    }));
  }

  const [updateUserExternalApi, updateUserState] = useUpdateUserExternalApi();
  const [fetchMatesGetRewards, state] = useLazyGetMatesGetRewardsQuery();
  console.log(user?.claim_state?.newMates);
  React.useEffect(() => {
    if (mates && !user?.claim_state?.newMates) {
      // @ts-ignore
      setClaimState?.((prevState) => ({
        ...(prevState || {}),
        newMates: mates,
      }));
    }
  }, [mates, mates?.count_mates, setClaimState, user?.claim_state?.newMates]);

  const currentLevelParams = user?.claim_state?.current_mates_level
    ? LEVELS[Number(user?.claim_state.current_mates_level)]
    : FIRST_LEVEL;

  const isLastLevel = !!(
    user?.claim_state?.current_mates_level &&
    user.claim_state.current_mates_level === LAST_LEVEL
  );

  const handleUpdateMatesLevel = () => {
    setOpenModal(currentLevelParams.levelRewards.old);
    impactOccurred("heavy");

    if (
      user.claim_state?.current_mates_level &&
      currentLevelParams
    ) {
      const current_mates_level = isLastLevel
        ? user.claim_state.current_mates_level
        : Number(user.claim_state.current_mates_level) + 1;

      const newData = {
        current_mates_level,
        balance:
          Number(user.claim_state.balance) + currentLevelParams.levelRewards.old,
        balance_from_clicks:
          Number(user.claim_state.balance_from_clicks) + currentLevelParams.levelRewards.old,
      } as TClaimState;

      updateUserExternalApi({
        ...newData,
      })
        .then(() => {
          setClaimState?.((prevState) => ({ ...prevState, ...newData }));
        })
        .catch((e: any) => {
          console.warn(e);
        });
    }
  };

  const handleGetClaimAll = () => {
    impactOccurred("heavy");
    fetchMatesGetRewards()
      .then((res) => {
        if (res.data && res.data.new_balance) {
          // @ts-ignore
          setClaimState?.((prevState) => ({
            ...(prevState || {}),
            balance: res!.data!.new_balance,
            balance_from_clicks:
              (prevState!.balance_from_clicks || 0) + res?.data!.reward,
            newMates: {
              ...prevState!.newMates,
              total_reward_balance: 0,
              top_reward_mates: prevState!.newMates!.top_reward_mates!.map(
                (mate) => ({
                  ...mate,
                  reward: 0,
                })
              ),
            },
          }));
          setOpenModal(res.data.reward);
        }
      })
      .catch((e) => console.log(e));
  };

  const handleInviteMate = () => {
    webApp.openTelegramLink(INVITE_MATE_URL);
    webApp.close();
  };

  return {
    isLoading: isMatesLoading || isContextLoading,
    isFetching: updateUserState.isLoading || state.isFetching,
    mates: user?.claim_state?.newMates,
    user,
    onUpdateMatesLevel: handleUpdateMatesLevel,
    onGetClaimAll: handleGetClaimAll,
    isLastLevel,
    currentLevelParams,
    onInviteMate: handleInviteMate,
    onModalClose: () => setOpenModal(null),
    isOpenModal: openModal,
    payPassMultiplier,
    handleRefetchUsers,
    getCustomBg,
  };
};

export const useCheckTaskTheeFriendsApi = (mates?: number) => {
  const [updateUserExternalApi, updateUserState] = useUpdateUserExternalApi();

  const location = useLocation();
  const {user, setClaimState } = React.useContext(UserContext);
  const [verifyModalOpen, setVerifyModalOpen] = React.useState(false);
  const [inviteMateCount, setInviteMateCount] = React.useState<number | undefined>(undefined);

  const [isVerify, setIsVerify] = React.useState<undefined | boolean>(
    undefined
  );



  React.useEffect(() => {
    if (
      location?.state?.inviteThreeMatesData &&
      typeof mates === "number"
    ) {
      if (location?.state?.inviteThreeMatesData?.completed) {
        setIsVerify(true);
        setVerifyModalOpen(false);
      } else {
        // @ts-ignore
         if (!user?.claim_state?.tasks?.inviteThreeMatesData?.completed) {
          setVerifyModalOpen(true);
         }

      }
    }
  }, [location?.state?.inviteThreeMatesData, mates, user?.claim_state]);

  const handleVerify = () => {
    const payPassMultiplier = user?.claim_state?.payPassMultiplier;

    if (typeof mates === "number" && payPassMultiplier) {

      const tasks = inviteMates(
        mates || 0,
        // @ts-ignore
        user?.claim_state?.tasks
      );
      // @ts-ignore
      setInviteMateCount(tasks?.inviteThreeMatesData?.remainingInvites);
      // @ts-ignore
      const newData = {
        // @ts-ignore
        balance: tasks?.inviteThreeMatesData?.completed
          ? user!.claim_state!.balance + (INVITE_THREE_MATES_REWARD * payPassMultiplier)
          : user!.claim_state!.balance,
        tasks,
      };

        updateUserExternalApi({
          ...newData,
        })
          .then(() => {
            // @ts-ignore
            setClaimState?.((prevState) => ({ ...prevState, ...newData }));

            // @ts-ignore
            if (tasks?.inviteThreeMatesData?.completed) {
              setIsVerify(true);
            } else {
              setIsVerify(false);
            }
          })
          .catch((e: any) => {
            console.warn(e);
          });
    }
  };

  return {
    isVerify,
    verifyModalOpen,
    onCloseVerifyModalOpen: () => setVerifyModalOpen(false),
    onVerify: handleVerify,
    isFetching: updateUserState.isLoading,
    inviteMateCount,

  };
};

export function inviteMates(mates: number, tasks?: TTask): TTask {
  if (!tasks) {
    return {
      inviteThreeMatesData: {
        mates: mates,
        isEnoughMates: false,
        completed: false,
        remainingInvites: Math.max(3 - mates, 0),
      },
    };
  }

  const inviteThreeMatesData = tasks?.inviteThreeMatesData;
  // @ts-ignore
  const initialMates = inviteThreeMatesData?.mates ?? mates;
  const currentMates = mates;
  const isEnoughMates = currentMates - initialMates >= 3;
  const remainingInvites = Math.max(3 - (currentMates - initialMates), 0);

  // @ts-ignore
  if (inviteThreeMatesData?.completed) {
    return tasks;
  }

  return {
    ...tasks,
    inviteThreeMatesData: {
      mates: initialMates,
      isEnoughMates: isEnoughMates,
      completed: isEnoughMates,
      remainingInvites: remainingInvites,
    },
  };
}
