import React from "react";

import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

import { UserContext } from "../contexts/user-context";

import { TConditionsType } from "./models";
import { useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";

export const usePageNftApi = () => {
    const [impactOccurred] = useHapticFeedback();

    const { user, isLoading, setClaimState } =
    React.useContext(UserContext);

    const [tonConnectUI] = useTonConnectUI();

    const handleConnectWallet = () => {
        tonConnectUI.openModal();
    }

    const handleDisconnect = async () => {
        try {
          impactOccurred('heavy')
          await tonConnectUI.disconnect();
        } catch (e) {
          console.log("Error during disconnect:", e);
        }
    };

    const wallet = useTonWallet();
    const address = wallet?.account?.address;

    return {
        user, isLoading, setClaimState,
        handleConnectWallet,
        handleDisconnect,
        address,
    }
}