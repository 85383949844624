import React, { useContext } from "react";

import { BackButton } from "@vkruglikov/react-telegram-web-app";

import { UserContext } from "../contexts/user-context";
import { TemplatePages } from "../template-pages";
import { ROUTES } from "../routers";

import { Collaboration } from "./collobaration";
import {
  useAdditionalsHandlersApi,
  useAdsGramApi,
  useLocalPageTasksApi,
  useOnClickaApi,
} from "./hooks";
import { Modals } from "./modals";
import { Content } from "./content";

import "./index.scss";
import { useShowContentApi } from "../../hooks";

export const PageTasks: React.FC = () => {
  const {
    user,
    task: {
      tasks,
      isLoading,
      isFetching,
      currentTask,
      onClaimTask,
      onChangeCurrentIndexTask,
      WALLET_TASK,
      THREE_FRIENDS_TASK,
      readyToClaim,
      isJoinedToTelegram,
      onTelegramGroupVerify,
      can_update_adsgram_ads,
      can_update_onclicka_ads,
    },
    onSetRuneStep,
    setClaimState,
    getCustomBg,
  } = useContext(UserContext);

  const {
    openCollaboration,
    onCloseCollaboration,
    onOpenSupport,
    isCompletedTasksListEmpty,
    activeTab,
    onActiveTaskChange,
    activeTasksList,
    completedTasksList,
    handleSetShowVerifyModal,
    showVerifyModal,
    showShareModal,
    setShowShareModal,
    payPassMultiplier,
    bgImage,
    isActiveTabIsntCollab,
  } = useLocalPageTasksApi(user, tasks);

  const {
    handleCheckCodeModal,
    checkCodeModal,
    handleVerifyModalClose,
    handleVerifyModalClaim,
    handleTaskModalClose,
    handleTaskModalClaim,
    isCurrentTaskShareModal,
    handleShareModalClose,
    handleShareModalClaim,
    handleSetShowShareModal,
  } = useAdditionalsHandlersApi(
    currentTask,
    showShareModal,
    setShowShareModal,
    onChangeCurrentIndexTask,
    handleSetShowVerifyModal,
    onClaimTask
  );
  const { handleShowAdgramsAdvert } = useAdsGramApi(onClaimTask);
  const { handleShowOnclickaAdvert } = useOnClickaApi(onClaimTask);

  return (
    <TemplatePages
      isLoading={isLoading}
      isFetching={isFetching}
      bg={
        isActiveTabIsntCollab
          ? bgImage
          : getCustomBg
          ? getCustomBg(bgImage)
          : bgImage
      }
    >
      <BackButton onClick={() => (window.location.hash = ROUTES.MAIN.PATH)} />
      <Collaboration
        isOpen={openCollaboration}
        onClose={onCloseCollaboration}
        onOpenSupport={onOpenSupport}
      />
      <div className="page page-1 page-tasks main-wrapper">
        <Modals
          showVerifyModal={showVerifyModal}
          onCheckCodeModal={handleCheckCodeModal}
          checkCodeModal={checkCodeModal}
          currentTask={currentTask}
          onVerifyModalClose={handleVerifyModalClose}
          onVerifyModalClaim={handleVerifyModalClaim}
          onTelegramGroupVerify={onTelegramGroupVerify}
          onTaskModalClose={handleTaskModalClose}
          onTaskModalClaim={handleTaskModalClaim}
          isJoinedToTelegram={isJoinedToTelegram}
          isCurrentTaskShareModal={isCurrentTaskShareModal}
          onShareModalClose={handleShareModalClose}
          onShareModalClaim={handleShareModalClaim}
          payPassMultiplier={payPassMultiplier}
        />
        <Content
          activeTab={activeTab}
          user={user}
          setClaimState={setClaimState}
          can_update_adsgram_ads={can_update_adsgram_ads}
          can_update_onclicka_ads={can_update_onclicka_ads}
          WALLET_TASK={WALLET_TASK}
          THREE_FRIENDS_TASK={THREE_FRIENDS_TASK}
          activeTasksList={activeTasksList}
          onClaimTask={onClaimTask}
          onChangeCurrentIndexTask={onChangeCurrentIndexTask}
          readyToClaim={readyToClaim}
          onSetShowVerifyModal={handleSetShowVerifyModal}
          onSetShowShareModal={handleSetShowShareModal}
          onShowAdgramsAdvert={handleShowAdgramsAdvert}
          onShowOnclickaAdvert={handleShowOnclickaAdvert}
          completedTasksList={completedTasksList}
          isCompletedTasksListEmpty={isCompletedTasksListEmpty}
          onSetRuneStep={onSetRuneStep}
          onActiveTaskChange={onActiveTaskChange}
          payPassMultiplier={payPassMultiplier}
          onCheckCodeModal={handleCheckCodeModal}
        />
      </div>
    </TemplatePages>
  );
};
