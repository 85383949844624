import React from "react";

import { Stack, Typography } from "@mui/material";

export const Heading: React.FC = () => {
  return (
    <Stack
      gap={2}
      className="galaxy-color"
      style={{
        width: "100%",
        fontSize: "48px",
        justifyContent: "center",
        marginTop: "40px",
      }}
    >
      <Stack gap={2}>
        <Typography
          className="ab"
          style={{
            fontSize: "48px",
            lineHeight: "0.9",
          }}
          textAlign="center"
        >
          W-Coin
        </Typography>
        <Typography
          className="ab"
          style={{
            fontSize: "48px",
            lineHeight: "0.7",
          }}
          textAlign="center"
        >
          Premarket
        </Typography>
      </Stack>
    </Stack>
  );
};
