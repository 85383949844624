import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import {
  baseApi,
  legendBaseApi,
  externalBaseApi,
  okxCheckUidBaseApi,
  rabbitBaseApi,
  checkOrderBaseApi,
} from "./services";

/** Configure store. */
export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    [legendBaseApi.reducerPath]: legendBaseApi.reducer,
    [externalBaseApi.reducerPath]: externalBaseApi.reducer,
    [okxCheckUidBaseApi.reducerPath]: okxCheckUidBaseApi.reducer,
    [checkOrderBaseApi.reducerPath]: checkOrderBaseApi.reducer,
    [rabbitBaseApi.reducerPath]: rabbitBaseApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      baseApi.middleware,
      legendBaseApi.middleware,
      externalBaseApi.middleware,
      okxCheckUidBaseApi.middleware,
      checkOrderBaseApi.middleware,
      rabbitBaseApi.middleware,
    ),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
