import React from "react";

import { Button, Typography } from "@mui/material";
import { EKeyType } from "../../../../models";

const discount25Icon = require("../img/discount-25-icon.png") as string;
const discount30Icon = require("../img/discount-30-Icon.svg").default as string;
const discount50Icon = require("../img/discount-50-Icon.svg").default as string;
const discount60Icon = require("../img/discount-60-icon.svg").default as string;

interface IProps {
  onClick?: () => void;
  label: string | React.ReactElement;
  fontSize?: string;
  disableButton?: boolean;
  currentKeyType?: EKeyType;
}

export const ActionButton: React.FC<IProps> = ({
  onClick,
  label,
  fontSize,
  disableButton,
  currentKeyType,
}) => {
  const discountSrc = () => {
    switch (currentKeyType) {
      case EKeyType.Silver:
        return discount25Icon;
      case EKeyType.Gold:
        return discount30Icon;
      case EKeyType.Platinum:
      case EKeyType.Diamond:
        return discount50Icon;
      case EKeyType.Brilliant:
        return discount60Icon;
      case EKeyType.Bronze:
      default:
        return undefined;
    }
  };

  return (
    <Button
      onClick={onClick}
      className={disableButton ? "btn-disabled" : ""}
      sx={{
        background: "#9747FF",
        width: "100%",
        borderRadius: "22px",
        height: "48px",
        position: "relative",
      }}
    >
      {discountSrc() && (
        <img
          width="35.03px"
          height="35.03px"
          src={discountSrc()}
          alt=""
          style={{
            position: "absolute",
            right: 0,
            top: "-14px",
            zIndex: 2,
          }}
        />
      )}

      <Typography
        className="ab"
        sx={{
          color: "#fff",
          fontSize: fontSize ?? "20px",
          textTransform: "none",
        }}
      >
        {label}
      </Typography>
    </Button>
  );
};
