import { Box } from "@mui/material";
import React from "react";
import { ModalContent } from "./modal-content";
import { EPrice } from "../models";
import { TonConnectUI, TonConnectUiOptions } from "@tonconnect/ui-react";

const icon = require("./img/donationIcon.png") as string;

interface IProps {
  onUpdateDonate: (price: number) => Promise<void>;
  currentPrice: EPrice;
  setCurrentPrice: (price: EPrice) => void;
  isWalletConnected: boolean;
  address?: string;
  tonConnectUI: TonConnectUI;
  setOptions: (options: TonConnectUiOptions) => void;
  onDisconnect: () => Promise<void>;
  failedTransaction: boolean;
  isProcessing: boolean;
  handleSentTransaction: () => Promise<void>;
  isLoading: boolean;
}

export const TeamDonates: React.FC<IProps> = ({
  onUpdateDonate,
  currentPrice,
  setCurrentPrice,
  isWalletConnected,
  address,
  tonConnectUI,
  setOptions,
  onDisconnect,
  failedTransaction,
  isProcessing,
  handleSentTransaction,
  isLoading,
}) => {
  const [showModal, setShowModal] = React.useState(false);

  const handleClose = (event: React.SyntheticEvent, reason: string) => {
    if (reason === "backdropClick") {
      return;
    }

    setShowModal(false);
  };

  return (
    <>
      <Box
        onClick={() => setShowModal(true)}
        alignItems="center"
        style={{
          position: "absolute",
          left: "22px",
          top: "35.5%",
          width: "76px",
          height: "78px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img src={icon} alt="" width="55.04px" height="54.12px" />
      </Box>
      <ModalContent
        onUpdateDonate={onUpdateDonate}
        showModal={showModal}
        onCloseWithBackdropClick={handleClose}
        onClose={() => setShowModal(false)}
        currentPrice={currentPrice}
        setCurrentPrice={setCurrentPrice}
        isWalletConnected={isWalletConnected}
        address={address}
        tonConnectUI={tonConnectUI}
        setOptions={setOptions}
        onDisconnect={onDisconnect}
        failedTransaction={failedTransaction}
        handleSentTransaction={handleSentTransaction}
        isLoading={isLoading || isProcessing}
      />
    </>
  );
};
