import React from "react";

import { Box, Button, Stack } from "@mui/material";
import { Address } from "@ton/core";

import { truncateString } from "../../../wallet/utils";

import "./index.scss";

// images
const editIcon = require("./img/edit-icon.png") as string;

interface IProps {
  address?: string;
  databaseWalletAddress?: string;
  handleDisconnect: () => Promise<void>;
  handleConnectWallet: () => void;
  canShowConnectedBtn: boolean;
  isEligible?: boolean;
  status?: "PENDING" | "NOT VERIFIED" | "SENT";
}

export const Actions: React.FC<IProps> = ({
  address,
  databaseWalletAddress,
  handleDisconnect,
  handleConnectWallet,
  canShowConnectedBtn,
  isEligible,
  status,
}) => {
  const getBtn = () => {
    if (isEligible) {
      if (status === "PENDING") {
        return (
          <Button disabled className="nft-action-btn ab">
            You are in queue
          </Button>
        );
      } else {
        return <Button className="nft-action-btn ab">Open Getgems.io</Button>;
      }
    }

    if (canShowConnectedBtn) {
      return (
        <Button onClick={handleConnectWallet} className="nft-action-btn ab">
          Connect wallet
        </Button>
      );
    } else {
      return <Box width="285.24px" height="66px"></Box>;
    }
  };

  const addressContent = () => {
    if (status === "SENT") {
      return <Box height="22px"></Box>;
    }

    if (databaseWalletAddress) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <p className="white-color paPassAddress">
            TON wallet address:{" "}
            {truncateString(
              Address.parse(databaseWalletAddress).toString({
                bounceable: false,
              })
            )}
          </p>
          <img src={editIcon} alt="" onClick={handleDisconnect} />
        </div>
      );
    } else if (address) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <p className="white-color paPassAddress">
            TON wallet address:{" "}
            {truncateString(
              Address.parse(address).toString({
                bounceable: false,
              })
            )}
          </p>
          <img src={editIcon} alt="" onClick={handleDisconnect} />
        </div>
      );
    } else {
      return <Box height="22px"></Box>;
    }
  };

  return (
    <Stack width="100%" justifyContent="center" alignItems="center" gap="20px">
      {getBtn()}
      {addressContent()}
    </Stack>
  );
};
