import React from "react";

import { useNavigate } from "react-router-dom";

import { SmallCoin } from "../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_SMALL } from "../../../consts";
import { formatValues } from "../../../utils";
import { ZoomInAnimation } from "../../page-animation";
import { PayPassMultiplierComponent } from "../pay-pass-multiplier";
import { ITask } from "../type";

import "./index.scss";
import { ClaimButton } from "./claim-button";
import { Stack } from "@mui/material";
import { ADSGRAM_FIELD } from "../consts";

const keyIcon = require("./img/key.png") as string;

interface IProps extends ITask {
  payPassMultiplier?: number;
  onClaim?: () => void;
  canClaim?: boolean;
  onOpenModal?: () => void;
  onSetShowVerifyModal?: (value: boolean) => void;
  onSetShowShareModal?: () => void;
  onShowAdgramsAdvert?: () => void;
  onShowOnclickaAdvert?: () => void;
  isAdaGramDisabledItem?: boolean;
  isOncklicaDisabledItem?: boolean;
  onCheckCodeModal?: () => void;
}

export const TaskItem: React.FC<IProps> = ({
  payPassMultiplier,
  icon,
  stringIcon,
  componentIcon,
  title,
  subtitle,
  reward,
  is_done,
  additionalRewardInfo,
  canClaim,
  verifyLink,
  verifyExternalLink,
  share,
  onOpenModal,
  onClaim,
  openPageBeforeTaskReadyType,
  claimTask,
  doubleVerify,
  onSetShowVerifyModal,
  onSetShowShareModal,
  onShowAdgramsAdvert,
  onShowOnclickaAdvert,
  isAdverts,
  isAdaGramDisabledItem,
  isOncklicaDisabledItem,
  checkCode,
  onCheckCodeModal,
  isKeyPrice,
  type,
}) => {
  const navigate = useNavigate();

  function openPageBeforeTaskReady(path: string) {
    navigate(path);
  }

  const onClick = React.useMemo(() => {
    if (isAdverts) {
      if (type === ADSGRAM_FIELD) {
        return () => onShowAdgramsAdvert?.();
      } else {
        return () => onShowOnclickaAdvert?.();
      }
    }

    if (share) {
      return () => onSetShowShareModal?.();
    }

    if (checkCode) {
      return () => onCheckCodeModal?.();
    } else if (claimTask) {
      if (openPageBeforeTaskReadyType && !canClaim && !is_done) {
        return () => openPageBeforeTaskReady(openPageBeforeTaskReadyType);
      }
    } else if (doubleVerify) {
      return () => onSetShowVerifyModal?.(true);
    } else if (!!verifyLink || !!verifyExternalLink) {
      return () => onOpenModal?.();
    } else {
      return onClaim;
    }
  }, [
    isAdverts,
    share,
    checkCode,
    claimTask,
    doubleVerify,
    verifyLink,
    verifyExternalLink,
    onShowAdgramsAdvert,
    onShowOnclickaAdvert,
    onSetShowShareModal,
    onCheckCodeModal,
    openPageBeforeTaskReadyType,
    canClaim,
    is_done,
    openPageBeforeTaskReady,
    onSetShowVerifyModal,
    onOpenModal,
    onClaim,
  ]);

  const getIcon = () => {
    if (stringIcon) {
      return <p style={{ fontSize: 50, color: "#fff" }}>{stringIcon}</p>;
    } else if (icon) {
      return <img src={icon} alt="" style={{ width: "65px" }} />;
    }

    return componentIcon;
  };

  const keyPriceContent = (
    <Stack
      justifyContent="flex-start"
      flexDirection="row"
      alignItems="center"
      gap={0.5}
    >
      <div
        style={{
          width: "20px",
          height: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img width="14px" src={keyIcon} alt="" />
      </div>
      <p className="common-color" style={{ fontSize: "15px" }}>
        +{isKeyPrice}
      </p>
    </Stack>
  );

  return (
    <div
      onClick={onClick}
      className={`${
        is_done || isAdaGramDisabledItem || isOncklicaDisabledItem
          ? "btn-disabled"
          : ""
      } task-link-wrapper`}
    >
      <div
        className="task-item-avatar blur-bg-strong"
        style={{ minWidth: "88px" }}
      >
        {canClaim && !is_done && <ClaimButton onClick={() => onClaim?.()} />}

        {canClaim && !is_done ? (
          getIcon()
        ) : (
          <ZoomInAnimation> {getIcon()} </ZoomInAnimation>
        )}
      </div>
      <div className="task-item-info-stack">
        <p className="green-color">{is_done ? title : title}</p>
        <p className="green-color">{is_done ? subtitle : subtitle}</p>

        {is_done ? (
          <p className="common-color">Completed</p>
        ) : (
          <Stack gap="4px">
            {isKeyPrice ? keyPriceContent : null}
            <div className="task-item-reward">
              {additionalRewardInfo ? (
                <div className="green-color">{additionalRewardInfo}</div>
              ) : null}
              <SmallCoin
                width={BALANCE_COIN_SIZE_SMALL}
                height={BALANCE_COIN_SIZE_SMALL}
              />
              <p className="common-color">+{formatValues(reward)}</p>{" "}
              <PayPassMultiplierComponent
                payPassMultiplier={payPassMultiplier}
              />
            </div>
          </Stack>
        )}
      </div>
    </div>
  );
};
