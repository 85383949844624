import React from "react";

import { Box, IconButton, Stack, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  useHapticFeedback,
  useWebApp,
} from "@vkruglikov/react-telegram-web-app";

import { ICampaign } from "../models";
import { Price } from "../../../../app-modal/task-modal/price";
import { InputCode } from "./input-code";

interface IProps {
  onClose: () => void;
  activeTask: ICampaign | null;
  payPassMultiplier?: number;
  onClaimTask: (task: ICampaign | null) => void;
}

export const ModalContent: React.FC<IProps> = ({
  activeTask,
  onClose,
  payPassMultiplier,
  onClaimTask,
}) => {
  const webApp = useWebApp();

  const [impactOccurred] = useHapticFeedback();

  const handleClose = (event: React.SyntheticEvent, reason: string) => {
    if (reason === "backdropClick") {
      return;
    }

    onClose();
  };

  const handleOpen = () => {
    if (activeTask) {
      webApp.openLink(activeTask.link);
      if (!activeTask.code && !activeTask.is_done) {
        impactOccurred("heavy");
        onClaimTask(activeTask);
      }
    }
  };

  const actionButtons = () => {
    const subscribeBtn = (
      <div className="task-modal-button-wrapper">
        <button onClick={handleOpen} className={`app-modal-content-button`}>
          {activeTask?.btnLabel}
        </button>
      </div>
    );

    return (
      <Stack gap={2} width="100%">
        <Stack width="100%" gap={1}>
          {subscribeBtn}
        </Stack>
      </Stack>
    );
  };

  const getModalContent = () => {
    return (
      <>
        <Stack display="flex" flexDirection="column" gap={1}>
          <Price
            modalLabel={activeTask?.title}
            reward={activeTask?.reward}
            payPassMultiplier={payPassMultiplier}
            isDone={!!activeTask?.is_done}
          />
        </Stack>
      </>
    );
  };

  return (
    <Modal
      open={!!activeTask}
      onClose={handleClose}
      style={{
        display: "flex",
      }}
    >
      <Box
        className="app-modal-content-wrapper"
        style={{
          display: "flex",
          width: "100%",
          height: "85%",
          justifyContent: "space-between",
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 12,
            right: 12,
            color: "#fff",
          }}
        >
          <CloseIcon style={{ color: "#fff" }} />
        </IconButton>
        <div
          className="app-modal-content task-modal-content"
          style={{
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div className="task-modal-desc">
            <Stack
              style={{
                gap: "24px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                className="task-modal-icon"
                style={{
                  maxWidth: "max-content",
                  margin: "0 auto",
                }}
              >
                <img src={activeTask?.icon} alt="" />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  textAlign: "center",
                }}
              >
                {getModalContent()}
              </div>
              {activeTask?.code && !activeTask.is_done ? (
                <InputCode activeTask={activeTask} onClaimTask={onClaimTask} />
              ) : null}
            </Stack>
          </div>
          {actionButtons()}
        </div>
      </Box>
    </Modal>
  );
};
