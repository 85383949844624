import React from "react";

import "../index.scss";

import { CSSTransition } from "react-transition-group";
import { WSkinsModalContent } from "./wskins-modal-content";
import { TWSkinsItems } from "../../page-boosters/boosters/main-boosters/boosters-items/w-skins/types";
import { getCSkinIcon } from "../../../utils";
import { TonConnectUI } from "@tonconnect/ui-react";

interface IProps {
  open: boolean;
  onClose: () => void;
  onUpdate: (payment?: "TON" | "STARS") => void;
  skinsToBuy: TWSkinsItems | null;
  balance: number;
  isPurchased?: boolean;
  isLoading?: boolean;
  isWalletConnected: boolean;
  address?: string;
  tonConnectUI: TonConnectUI;
  onDisconnect: () => Promise<void>;
  failedTransaction: boolean;
}

export const WSkinsModal: React.FC<IProps> = ({
  open,
  onClose,
  onUpdate,
  skinsToBuy,
  balance,
  isPurchased,
  isLoading,
  isWalletConnected,
  address,
  tonConnectUI,
  onDisconnect,
  failedTransaction,
}) => {
  return (
    <CSSTransition in={!!open} timeout={400} classNames="fade" unmountOnExit>
      <div className="overlay app-modal-root">
        {skinsToBuy && (
          <WSkinsModalContent
            isStars={skinsToBuy.paymentStars || !!skinsToBuy.tonPrice}
            onClick={onUpdate}
            icon={getCSkinIcon(skinsToBuy)}
            title={skinsToBuy.title}
            subTitle={skinsToBuy.subTitle}
            price={skinsToBuy.price}
            tonPrice={skinsToBuy?.tonPrice}
            buttonLabel={isPurchased ? "Purchased" : "Buy"}
            onClose={onClose}
            disabled={
              skinsToBuy.paymentStars
                ? false
                : skinsToBuy.price > balance || isPurchased || isLoading
            }
            isPurchased={isPurchased}
            isWalletConnected={isWalletConnected}
            address={address}
            tonConnectUI={tonConnectUI}
            onDisconnect={onDisconnect}
            failedTransaction={failedTransaction}
          />
        )}
      </div>
    </CSSTransition>
  );
};
