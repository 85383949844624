import React from "react";

import { Stack, Typography } from "@mui/material";

const icon = require("./img/voucher.png") as string;

interface IProps {
  amount?: number;
}

export const IconContent: React.FC<IProps> = ({ amount }) => {
  return (
    <Stack flex="1" justifyContent="center" alignItems="center">
      <Typography
        className="ab galaxy-color"
        style={{
          fontSize: "31px",
        }}
        textAlign="center"
      >
        {amount} Vouchers
      </Typography>
      <img width="80%" src={icon} alt="" />
    </Stack>
  );
};
