import React from "react";

import { Stack } from "@mui/material";
import { motion } from "framer-motion";

interface IProps {
  activeTab: "Pending" | "Treasures";
  handleActiveTabChange: (value: "Pending" | "Treasures") => void;
}

export const ToggleItems: React.FC<IProps> = ({
  activeTab,
  handleActiveTabChange,
}) => {
  return (
    <Stack
      mt={2}
      mb={4}
      flexDirection="row"
      justifyContent="flex-start"
      gap="16px"
      color="#fff"
    >
      <h2
        className={` boosters-header-item task-toggle-label task-toggle-label-white-space ${
          activeTab === "Pending" ? "selected" : ""
        }`}
        onClick={() => handleActiveTabChange("Pending")}
      >
        <b>Pending Orders</b>
        {activeTab === "Pending" && (
          <motion.div className="underline" layoutId="underline" />
        )}
      </h2>
      <h2
        className={` boosters-header-item task-toggle-label task-toggle-label-white-space ${
          activeTab === "Treasures" ? "selected" : ""
        }`}
        onClick={() => handleActiveTabChange("Treasures")}
      >
        <b>W-Treasures</b>
        {activeTab === "Treasures" && (
          <motion.div className="underline" layoutId="underline" />
        )}
      </h2>
    </Stack>
  );
};
