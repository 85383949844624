export enum EPrice {
    LOW = "LOW",
    MEDIUM = "MEDIUM",
    PRE_HIGH = "PRE_HIGH",
    HIGH = "HIGH",
}


export const tabPrice: Record<EPrice, number> = {
  [EPrice.LOW]: 5,
  [EPrice.MEDIUM]: 10,
  [EPrice.PRE_HIGH]: 20,
  [EPrice.HIGH]: 50,
}


export const tonPrice: Record<EPrice, number> = {
  [EPrice.LOW]: 1,
  [EPrice.MEDIUM]: 2,
  [EPrice.PRE_HIGH]: 4,
  [EPrice.HIGH]: 10,
}

export const starsPrice: Record<EPrice, number> = {
  [EPrice.LOW]: 250,
  [EPrice.MEDIUM]: 500,
  [EPrice.PRE_HIGH]: 990,
  [EPrice.HIGH]: 4990,
}