import React from "react";

import { TonConnectUI } from "@tonconnect/ui-react";
import { Stack } from "@mui/material";
import { Address } from "@ton/core";

import { TKey } from "../../../models";
import { truncateString } from "../../../../wallet/utils";
import { ECurrentPurchaseType } from "../models";

import { ActionButton } from "./action-button";

const starsIcon = require("./img/stars-icon.png") as string;

interface IProps {
  currentKey: TKey | null;
  tonConnectUI: TonConnectUI;
  address?: string;
  onDisconnect: () => Promise<void>;
  onBuyKeys: () => void;
  onBuyKeyBuyStars: () => void;
  currentPurchaseType: ECurrentPurchaseType | null;
}

// images
const editIcon = require("./img/edit-icon.png") as string;

export const ActionButtons: React.FC<IProps> = ({
  currentKey,
  tonConnectUI,
  address,
  onDisconnect,
  onBuyKeys,
  onBuyKeyBuyStars,
}) => {
  return currentKey ? (
    <Stack
      flexDirection="column"
      gap={2}
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <div
        className="connected-address"
        style={{ height: "30px", alignItems: "center" }}
      >
        {address ? (
          <>
            <p className="white-color paPassAddress">
              Connected address:{" "}
              {truncateString(
                Address.parse(address).toString({
                  bounceable: false,
                })
              )}
            </p>
            <img src={editIcon} alt="" onClick={onDisconnect} />
          </>
        ) : null}
      </div>
      <Stack width="100%" flexDirection="row" gap={2}>
        {!tonConnectUI.connected ? (
          <ActionButton
            currentKeyType={currentKey?.type}
            onClick={() => tonConnectUI.openModal()}
            label={`${currentKey.ton} TON`}
          />
        ) : null}
        {tonConnectUI.connected ? (
          <ActionButton
            currentKeyType={currentKey?.type}
            onClick={onBuyKeys}
            label={`${currentKey.ton} TON`}
          />
        ) : null}
        <ActionButton
          currentKeyType={currentKey?.type}
          onClick={onBuyKeyBuyStars}
          label={
            <Stack flexDirection="row" alignItems="center" gap={0.5}>
              {" "}
              <img
                width="22.14px"
                height="22.71px"
                src={starsIcon}
                alt=""
              />{" "}
              {`${currentKey.stars}`}
            </Stack>
          }
        />
      </Stack>
    </Stack>
  ) : (
    <Stack
      flexDirection="column"
      gap={2}
      width="100%"
      justifyContent="center"
      alignItems="center"
      height="86px"
    ></Stack>
  );
};
