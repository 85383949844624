import React from "react";

import { Stack, Typography } from "@mui/material";

interface IProps {
  label: string;
  icon: string;
  width: string;
  height: string;
  onClick: () => void;
}

export const ActionButton: React.FC<IProps> = ({
  label,
  icon,
  width,
  height,
  onClick,
}) => {
  return (
    <Stack
      display="flex"
      flexDirection="row"
      gap={0.5}
      alignItems="center"
      style={{
        padding: "4px 10px",
        borderRadius: "20px",
        border: "2px solid #A16DE5",
        justifyContent: "center",
      }}
      onClick={onClick}
    >
      <img width={width} height={height} src={icon} alt="" />
      <Typography className="ab" style={{ color: "#D6D6E7", fontSize: "12px" }}>
        {label}
      </Typography>
    </Stack>
  );
};
