import React from "react";

import { Box, Stack, Typography } from "@mui/material";

import { formatStatisticsValue } from "../../../../../utils";
import { TChestsHistory } from "../../../models";
import { Benefits } from "../../chest-content/benefit-list/benefits-item";
import { getDateByTimestamp, getTimeByTimestamp } from "../utils";

const wcoinIcon = require("../../img/wcoin-icon.png") as string;
const batteryIcon = require("../../img/battery-icon.png") as string;
const lackyDiceIcon = require("../../img/lacky-dice-icon.png") as string;
const waiIcon = require("../../img/wai-icon.png") as string;
const keyIcon = require("../../img/key-icon.png") as string;
const galaxyIcon = require("../../img/galaxy-icon.png") as string;

interface IProps {
  history?: TChestsHistory[];
}

enum EItem {
  WCOIN = "wcoin",
  BATTERY = "battery",
  LACKY_DICE = "dice",
  WAI = "wai",
  KEY = "keys",
  PAY_PASS_LITE = "pay-pass-lite",
  PAY_PASS_COMPLETE = "pay-pass-complete",
}

const icons: Record<
  string,
  {
    icons: React.ReactElement;
    hasValue: boolean;
    className: string;
  }
> = {
  [EItem.WCOIN]: {
    icons: <Benefits src={wcoinIcon} alt="" width="25px" height="25px" />,
    hasValue: true,
    className: "history-reward-color-wcoin",
  },
  [EItem.BATTERY]: {
    icons: <Benefits src={batteryIcon} alt="" width="25px" height="25px" />,
    hasValue: true,
    className: "history-reward-color-battery",
  },
  [EItem.LACKY_DICE]: {
    icons: <Benefits src={lackyDiceIcon} alt="" width="25px" height="25px" />,
    hasValue: true,
    className: "history-reward-color-dice",
  },
  [EItem.WAI]: {
    icons: <Benefits src={waiIcon} alt="" width="25px" height="25px" />,
    hasValue: true,
    className: "history-reward-color-wai",
  },
  [EItem.KEY]: {
    icons: <Benefits src={keyIcon} alt="" width="25px" height="25px" />,
    hasValue: true,
    className: "history-reward-color-key",
  },
  [EItem.PAY_PASS_LITE]: {
    icons: <Benefits src={galaxyIcon} alt="" width="25px" height="25px" />,
    hasValue: false,
    className: "history-reward-color-pay-pass",
  },
  [EItem.PAY_PASS_COMPLETE]: {
    icons: <Benefits src={galaxyIcon} alt="" width="25px" height="25px" />,
    hasValue: false,
    className: "history-reward-color-pay-pass",
  },
};

export const TreasuresContent: React.FC<IProps> = ({ history }) => {
  return (
    <Stack flex={1} height="100%" gap="20px" width="100%" overflow="auto">
      {history ? (
        <>
          {history.map((item) => (
            <Stack
              flexDirection="row"
              gap={2}
              width="100%"
              justifyContent="space-between"
            >
              <Stack flexDirection="row" justifyContent="space-between" gap={2}>
                <Stack width="54px">
                  <Typography lineHeight="1" color="#B6B6B6" fontSize="12px">
                    {getDateByTimestamp(item.date)}
                  </Typography>
                  <Typography lineHeight="1" color="#B6B6B6" fontSize="12px">
                    {getTimeByTimestamp(item.date)}
                  </Typography>
                </Stack>
                <Box
                  color="#B6B6B6"
                  fontSize="14px"
                  width="135px"
                  alignItems="center"
                  display="flex"
                >
                  <p
                    style={{
                      display: "flex",
                      gap: "4px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    W-Chest:{" "}
                    <p style={{ color: "#fff" }}>{item.chest_product_id}</p>
                  </p>
                </Box>
              </Stack>
              <Stack flexDirection="row" gap={1} alignItems="center">
                {icons[item.reward_type].hasValue ? (
                  <Typography
                    fontSize="14px"
                    className={icons[item.reward_type].className}
                  >
                    {formatStatisticsValue(Number(item.reward_value), 2)}
                  </Typography>
                ) : null}
                {icons[item.reward_type].icons}
              </Stack>
            </Stack>
          ))}
        </>
      ) : (
        <Typography
          color="rgb(175, 180, 168)"
          fontSize="16px"
          textAlign="center"
        >
          No purchases
        </Typography>
      )}
    </Stack>
  );
};
