import React from "react";

import { Stack, Typography } from "@mui/material";

import { TConditionsType } from "../models";

import { TopContent } from "./top-content";
import { Actions } from "./actions";
import { IAirdropResponse } from "../../feature/users/models";
import { IconContent } from "../icon-content";

const eligIcon = require("./img/eligable.png") as string;
const notEligIcon = require("./img/nonEligable.png") as string;

interface IProps {
  list: TConditionsType[];
  handleConnectWallet: () => void;
  handleDisconnect: () => Promise<void>;
  address?: string;
  databaseWalletAddress?: string;
  canShowConnectedBtn: boolean;
  nftAirdropData?: IAirdropResponse;
  isEligible?: boolean;
}

export const Content: React.FC<IProps> = ({
  list,
  handleConnectWallet,
  handleDisconnect,
  address,
  databaseWalletAddress,
  canShowConnectedBtn,
  nftAirdropData,
  isEligible,
}) => {
  return (
    <Stack justifyContent="flex-start" gap="30px" flex="1">
      {isEligible ? (
        <IconContent amount={nftAirdropData?.data?.amount} />
      ) : (
        <TopContent list={list} />
      )}
      <Stack
        alignItems="center"
        justifyContent="center"
        flexDirection="row"
        gap="8px"
      >
        {isEligible ? (
          <img width="22.24px" src={eligIcon} alt="" />
        ) : (
          <img width="22.24px" src={notEligIcon} alt="" />
        )}
        <Typography
          style={{
            color: "#fff",
            fontSize: "16px",
          }}
        >
          {isEligible ? "You are eligible" : "You’re not eligible"}
        </Typography>
      </Stack>
      <Actions
        handleConnectWallet={handleConnectWallet}
        handleDisconnect={handleDisconnect}
        address={address}
        databaseWalletAddress={databaseWalletAddress}
        canShowConnectedBtn={canShowConnectedBtn}
        isEligible={isEligible}
        status={nftAirdropData?.data?.status}
      />
    </Stack>
  );
};
