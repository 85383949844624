import React from "react";

import { CSSTransition } from "react-transition-group";
import { useWebApp } from "@vkruglikov/react-telegram-web-app";

import "../index.scss";
import { BALANCE_COIN_SIZE_MEDIUM, TELEGRAM_LINK } from "../../../consts";
import { SmallCoin } from "../../page-claim/icons/small-coin";
import { INVITE_THREE_MATES_REWARD } from "../consts";
import { PayPassMultiplierComponent } from "../../page-tasks/pay-pass-multiplier";

// images
const icon = require("../img/3mates3.png") as string;

interface IProps {
  open: boolean;
  isVerify?: boolean;
  onClose: () => void;
  onVerify: () => void;
  inviteMateCount?: number;
  payPassMultiplier?: number;
}

export const VerifyModal: React.FC<IProps> = ({
  open,
  isVerify,
  onClose,
  onVerify,
  inviteMateCount,
  payPassMultiplier,
}) => {
  const webApp = useWebApp();

  const handleOpenTelegram = () => {
    webApp.openTelegramLink(TELEGRAM_LINK);
  };

  const getLabel = () => {
    if (typeof isVerify === "boolean") {
      return isVerify ? (
        <>
          <div className="task-modal-reward ab">
            <SmallCoin
              width={BALANCE_COIN_SIZE_MEDIUM}
              height={BALANCE_COIN_SIZE_MEDIUM}
            />
            <p className="ab">+{INVITE_THREE_MATES_REWARD}</p>{" "}
            <PayPassMultiplierComponent payPassMultiplier={payPassMultiplier} />
          </div>
          <p className="task-modal-received">Received</p>
        </>
      ) : (
        <>
          <p className="task-modal-received" style={{ textAlign: "center" }}>
            There are not enough invited friends to complete the task
          </p>
          {inviteMateCount && (
            <p className="task-modal-received" style={{ alignItems: "center" }}>
              Invite {inviteMateCount} more mates
            </p>
          )}
        </>
      );
    }
  };

  return (
    <CSSTransition in={open} timeout={400} classNames="fade" unmountOnExit>
      <div className="overlay app-modal-root">
        <div
          className={`app-modal-content-wrapper task-modal-wrapper`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="close-modal ab" onClick={onClose}>
            x
          </div>
          <div className="app-modal-content task-modal-content">
            <div className="task-modal-desc">
              <div className="task-modal-icon">
                <img src={icon} />
              </div>
              {getLabel()}
            </div>
            {!isVerify && (
              <div
                className={`task-modal-button-wrapper ${
                  isVerify === false && "btn-disabled"
                }`}
              >
                <button
                  onClick={onVerify}
                  className={`app-modal-content-button`}
                >
                  Verify
                </button>
              </div>
            )}

            <div className="task-modal-button-wrapper">
              <button
                onClick={handleOpenTelegram}
                className={`app-modal-content-button`}
              >
                Follow W-Community
              </button>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
