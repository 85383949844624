import React from "react";

import { RightAnimation } from "../page-animation";
import { TemplatePages } from "../template-pages";

import { StakingModal } from "./staking-modal";
import { ToggleGroup } from "./toggle-group";
import { ETab } from "./enum";
import { StakeList } from "./stake-list";
import { useDashboardApi } from "./hooks";
import { CloseStakingModal } from "./close-staking-modal";
import "./index.scss";
import { Spinner } from "../spinner";
import { BackButton } from "@vkruglikov/react-telegram-web-app";
import { ROUTES } from "../routers";

// images
const bg = require("../../components/page-staking/bg.jpg") as string;
const contentBg = require("./img/dashboard-bg.png") as string;

export function PageDashboard() {
  const {
    getTimeLeft,
    canGetStaking,
    handleNew,
    isLoading,
    user,
    isFetching,
    onGetStacking,
    stackToGet,
    onChangeStackToGet,
    showCloseStaking,
    showStakingModal,
    closeCloseStakingModal,
    getCustomBg,
  } = useDashboardApi();

  const currentStake =
    user?.stakes?.filter((i) => !i.completed).slice(0, 3) || [];
  const completedStake = (user?.stakes?.filter((i) => i.completed) || []).sort(
    (a, b) => b.dateWhenStaked - a.dateWhenStaked
  );
  const hideStaking = !currentStake.length;

  const [activeTab, setActiveTab] = React.useState<ETab>(ETab.ACTIVE);

  return (
    <TemplatePages
      isLoading={isLoading}
      isFetching={isFetching}
      bg={getCustomBg ? getCustomBg(bg) : bg}
    >
      <BackButton onClick={() => (window.location.hash = ROUTES.MAIN.PATH)} />

      <CloseStakingModal
        show={showCloseStaking}
        onClose={closeCloseStakingModal}
      />

      <Spinner isLoading={isLoading} />

      {!!stackToGet && (
        <div
          className={`overlay app-modal-root ${!!stackToGet ? "blur" : ""}`}
        ></div>
      )}
      <StakingModal
        amount={stackToGet?.amount}
        onClose={() => onChangeStackToGet(null)}
        onUpdate={() => {
          if (stackToGet) onGetStacking();
        }}
        disabled={!stackToGet?.amount || isFetching}
      />
      <div
        className={`page page-1 page-tasks main-wrapper staking-main-wrapper`}
      >
        <div className="content">
          <h2 className="main-heading staking-heading ab">Dashboard</h2>
          <div
            className="stakes dashboard-content"
            style={{ backgroundImage: `url(${contentBg})` }}
          >
            <ToggleGroup
              activeTab={activeTab}
              onChange={setActiveTab}
              hideStaking={hideStaking}
            />
            {ETab.ACTIVE === activeTab && (
              <StakeList
                currentStake={currentStake}
                canGetStaking={canGetStaking}
                onChangeStackToGet={onChangeStackToGet}
                getTimeLeft={getTimeLeft}
                handleNew={handleNew}
                user={user}
                hideStaking={hideStaking}
              />
            )}
            {ETab.COMPLETED === activeTab && (
              <div
                className={`staking-wrapper-completed ${
                  !completedStake?.length && "stacking-empty-list"
                }`}
              >
                {completedStake?.length ? (
                  <StakeList
                    currentStake={completedStake}
                    canGetStaking={canGetStaking}
                    onChangeStackToGet={onChangeStackToGet}
                    getTimeLeft={getTimeLeft}
                    handleNew={handleNew}
                    user={user}
                    isCompleted={true}
                  />
                ) : (
                  <>List is empty</>
                )}
              </div>
            )}
            {hideStaking ? (
              <RightAnimation>
                <p
                  className="bottom-link step1-actions-label staking-link"
                  style={{
                    color: "rgb(85, 26, 139)",
                  }}
                  onClick={showStakingModal}
                >
                  Staking is finished!
                </p>
              </RightAnimation>
            ) : (
              <RightAnimation>
                <a
                  target="_blank"
                  className="bottom-link step1-actions-label staking-link"
                  href="https://telegra.ph/How-staking-works-04-20"
                  rel="noreferrer"
                >
                  How staking works?
                </a>
              </RightAnimation>
            )}
          </div>
        </div>
      </div>
    </TemplatePages>
  );
}
