import React from "react";

import { Button, Stack, Typography } from "@mui/material";

const tonICon = require("./tonIcon.png") as string;

interface IProps {
  onClick: () => void;
  disableButton: boolean;
  label: React.ReactElement | string;
  tonImg?: boolean;
}

export const ActionButton: React.FC<IProps> = ({
  onClick,
  disableButton,
  label,
  tonImg,
}) => {
  return (
    <Button
      onClick={onClick}
      className={disableButton ? "btn-disabled" : ""}
      sx={{
        background: "#FFF",
        width: "100%",
        borderRadius: "22px",
        height: "48px",
        position: "relative",
      }}
    >
      <Stack flexDirection="row" gap={0.5} alignItems="center">
        {tonImg ? <img width="17.13px" height="17.7px" src={tonICon} /> : null}
        <Typography
          className="ab"
          sx={{
            color: "#9747FF",
            fontSize: "20px",
            textTransform: "none",
          }}
        >
          {label}
        </Typography>
      </Stack>
    </Button>
  );
};
