import { EStyles, TStylesItems } from "../page-boosters/boosters/main-boosters/boosters-items/w-styles/models";

const natureIcon = require("./img/custom-bg-nature.jpg") as string;
const spaceIcon = require("./img/custom-bg-space.jpg") as string;
const oceanIcon = require("./img/custom-bg-ocean.jpg") as string;
const goldIcon = require("./img/custom-bg-gold.jpg") as string;



export const defineUserName = (name: any) => {
    if (name == undefined || !name || name == "undefined" || name == null) {
      return "";
    }
    return name;
}

export function getPurchasedStyles(styles?: Record<string, Partial<TStylesItems>>) {
  if (!styles) return null;

  return Object.values(styles)?.find(i => i.selected)?.type;
}

export const getCustomBgIcon = (customBg: string, type?: EStyles, ): string => {
  switch (type) {
    case EStyles.NATURE:
      return natureIcon;
    case EStyles.OCEAN:
      return oceanIcon;
    case EStyles.GOLD:
      return goldIcon;
    case EStyles.SPACE:
      return spaceIcon;
      case EStyles.DEFAULT:
        return customBg;
    default:
      return customBg;
  }
};

